import * as types from "./types";

export const setCompanyInfo = (payload) => ({
  type: types.SET_COMPANY_INFO,
  payload: payload,
});

export const setMyCompaniesList = (payload) => ({
  type: types.SET_My_COMPANIES_LIST,
  payload: payload,
});

export const setRegionsList = (payload) => ({
  type: types.SET_REGIONS_LIST,
  payload: payload,
});

export const setCitiesList = (payload) => ({
  type: types.SET_CITIES_LIST,
  payload: payload,
});
export const setFaqsList = (payload) => ({
  type: types.SET_FAQS_LIST,
  payload: payload,
});

export const switchCurrentLanguage = () => ({
  type: types.SWITCH_CURREN_LANGUAGE,
});
export const changeCurrentLanguage = (payload) => ({
  type: types.SET_CURREN_LANGUAGE,
  payload: payload,
});

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader";
import { setPaymentParam } from "../../../../utils/localStorage";
import successIcon from "../../../../assets/images/Payment/successIcon.svg";
import ChevronBar from "../../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../../store/Certificate/epics";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { Button, Container } from "react-bootstrap";
import {
  downloadReport,
  getCertificate,
} from "../../../../store/Certificate/actions";
import { useParams } from "react-router-dom";
import PDFViewer from "../PdfViewer";

const Accepted = () => {
  const dispatch = useDispatch();
  const local = useSelector((state) => state.homeReducer.local);
  let { uuid, companyId } = useParams();

  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const certificate = useSelector(
    (state) => state.certificateReducer.certificate
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const loading = useSelector((state) => state.commonReducer.loading);
  setPaymentParam({ isSet: false });

  const downloadCertificate = (image, filename) => {
    const link = document.createElement("a");
    link.href = image;
    link.download = filename;
    link.click();
  };

  useLegacyEffect(() => {
    dispatch(setCurrentCertificateFlowStep(4));
    dispatch(getCertificate(uuid));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Container className="accepted">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <ChevronBar
            type={"shape"}
            stepsClass={"steps-flow-container"}
            stepClass={"step-flow"}
            stepText={"step-flow-text"}
            stepTextCurrent={"step-flow-text--current"}
            stepTextUncompleted={"step-flow-text--uncompleted"}
            stepTextCompleted={"step-flow-text--completed"}
            stepCompletedClass={"step-home--completed"}
            steps={flowSteps}
            currentStep={currentCertificateFlowStep}
          />

          <div className="mt-5 mb-3 d-flex justify-content-center">
            <img
              className="order-successful-icon mx-2"
              src={successIcon}
              alt="Successful Icon"
              aria-hidden="true"
            />

            <h1 className="text-success fs-4 mt-3 mx-2">
              {local.orderAccepted}
            </h1>
          </div>

          {certificate && <PDFViewer certificate={certificate} />}
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">
          <Button
            variant="secondary"
            onClick={() =>
              downloadCertificate(
                `data:${certificate.content_type};base64,${certificate.content}`,
                certificate.file_name
              )
            }
            className="text-white w-100 mt-3"
          >
            <i className="bi bi-download ms-2"></i>

            {local.certificateDownload}
          </Button>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">
          <Button
            variant="outline-success"
            onClick={() => dispatch(downloadReport(uuid))}
            className="w-100 mt-3"
          >
            <i className="bi bi-file-earmark-text ms-2 "></i>
            {local.reportDownload}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Accepted;

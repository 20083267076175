import { useLocation } from 'react-router-dom';
import {ssoCallBack} from '../../../store/Auth/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"; 
import useLegacyEffect from "../../../hooks/useLegacyEffect"
import Loader from '../../Common/Loader';

const CallbackHandler = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate();


  useLegacyEffect(() => {
    // send all the query params to callback
    const queryParams = new URLSearchParams(location.search);
    dispatch(ssoCallBack(navigate,queryParams))
  }, [location.search]);

  return <Loader />;

};

export default CallbackHandler;

import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import classificationDesktopar from "../../../../assets/images/classification-desktop-ar.png";
import classificationMobilear from "../../../../assets/images/classification-mobile-ar.png";
import classificationDesktopen from "../../../../assets/images/classification-desktop-en.png";
import classificationMobileen from "../../../../assets/images/classification-mobile-en.png";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Classification() {
  const { state } = useLocation();
  const { targetId } = state || {};
  const local = useSelector((state) => state.homeReducer.local);

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  return (
    <section className="about-classification" id="about-classification">
      <Container>
        <Row>
          <Col>
            <div className="text-center d-lg-block d-none">
              <img
                src={
                  local === "ar"
                    ? classificationDesktopar
                    : classificationDesktopen
                }
                alt="classificationDesktop"
              />
            </div>
            <div className="text-center d-lg-none d-block">
              <img
                src={
                  local === "ar"
                    ? classificationMobilear
                    : classificationMobileen
                }
                alt="classification Mobile"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Classification;

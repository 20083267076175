const formatBytes = (bytes) => {
  var formattedBytes = "";
  var units = ["Bytes", "KB", "MB", "GB", "TB"];

  // Calculate the appropriate size unit (e.g., KB, MB, GB, etc.)
  var index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  if (bytes === 0) {
    formattedBytes = "n/a";
  } else if (index === 0) {
    formattedBytes = bytes + " " + units[index];
  } else {
    formattedBytes =
      (bytes / Math.pow(1024, index)).toFixed(1) + " " + units[index];
  }

  return formattedBytes;
};

export default formatBytes;

import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <div className="d-grid align-items-center">
      <div className="text-center">
        <div className="spinner-grow text-secondary" role="status">
          <span className="visually-hidden">{local.loading}</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;

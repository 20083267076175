import React from "react";
import { Route, Routes } from "react-router-dom";
import SelectCard from "../../components/Payment/SelectCard";
import CardDetails from "../../components/Payment/CardDetails";
import Processing from "../../components/Payment/Processing";
import PaymentSuccessful from "../../components/Payment/PaymentSuccessful";
import PaymentFaild from "../../components/Payment/PaymentFaild";
import TransferDetails from "../../components/Payment/TransferDetails";
import SadadDetails from "../../components/Payment/SadadDetails";
import GenerateSadadInvoice from "../../components/Payment/SadadDetails/GenerateSadadInvoice";
import Review from "../../components/Payment/Review";
import NoMatch from "../../components/Common/NoMatch";
import Status from "../../components/Payment/TransferDetails/Status";

const EngineerRoutes = () => {
  return (
    <div className="content">
      <Routes>
        <Route path=":uuid/method/transfer" element={<TransferDetails />} />
        <Route path=":uuid/method/transfer/status" element={<Status />} />
        <Route path=":uuid/method/sadad" element={<SadadDetails />} />
        <Route
          path=":uuid/method/sadad/invoice"
          element={<GenerateSadadInvoice />}
        />

        <Route
          path=":uuid/method/:paymentMethod/faild"
          element={<PaymentFaild />}
        />
        <Route
          path=":uuid/method/:paymentMethod/successful"
          element={<PaymentSuccessful />}
        />
        <Route
          path=":uuid/method/:paymentMethod/:checkoutId/details/processing"
          element={<Processing />}
        />
        <Route
          path=":uuid/method/:paymentMethod/:checkoutId/details"
          element={<CardDetails />}
        />
        <Route path=":uuid/method" element={<SelectCard />} />
        <Route path=":uuid/review" element={<Review />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default EngineerRoutes;

import React, { useState } from "react";
import { Button, Col, Card, Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../../shared/FormElements/TextInput";
import { useDispatch, useSelector } from "react-redux";
import onChangeHandler from "../../../../utils/onChangeHandler";
import { resetError, resetErrors } from "../../../../store/Error/epics";
import formValidaiton from "../../../../utils/formValidaiton";
import Loader from "../../../Common/Loader";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { callResetPassword } from "../../../../store/Auth/actions";
import useQuery from "../../../../hooks/useQuery";
import BackButton from "../../../../shared/BackButton";

function ResetPassword() {
  const local = useSelector((state) => state.homeReducer.local);
  const errors = useSelector((state) => state.errorReducer.errors);
  const loading = useSelector((state) => state.commonReducer.loading);
  const query = useQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPasswords] = useState({
    new_password: "",
    confirmed_new_password: "",
  });
  useLegacyEffect(() => {
    dispatch(resetErrors());
  }, []);
  const handleOnChange = (event) => {
    onChangeHandler(
      event,
      setPasswords,
      password,
      errors,
      dispatch,
      resetError
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationFormData = {
      new_password: {
        value: password.new_password,
        type: "passwords",
        required: true,
      },
      confirmed_new_password: {
        value: password.confirmed_new_password,
        type: "passwords",
        required: true,
      },
    };
    const isValidForm = formValidaiton(validationFormData, dispatch);
    if (isValidForm) {
      const data = { ...password, token: query.get("token") };
      dispatch(callResetPassword(navigate, data));
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} label={local.backV2} />
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6}>
          <p className="text-primary fs-4">{local.resetPageDes}</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={6}>
            <Card className="login-card shadow w-100 px-4 py-5 px-lg-5 mb-5">
              <TextInput
                inputId={"new_password"}
                type={"password"}
                label={local.password}
                inputName={"new_password"}
                inputValue={password.new_password}
                isInvalid={errors.new_password}
                error={errors.new_password}
                onChange={handleOnChange}
              />
              <TextInput
                inputId={"confirmed_new_password"}
                type={"password"}
                label={local.passwordConfirm}
                inputName={"confirmed_new_password"}
                inputValue={password.confirmed_new_password}
                isInvalid={errors.confirmed_new_password}
                error={errors.confirmed_new_password}
                onChange={handleOnChange}
              />
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center mb-5">
          <Col xs={12} lg={6} className="text-center">
            <Button
              variant="secondary"
              className="text-white w-75"
              type="submit"
            >
              {local.save}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ResetPassword;

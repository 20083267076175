import React, { useState } from "react";
import { Button, Col, Container, Row, ButtonGroup } from "react-bootstrap";
import governmentSector from "../../../assets/images/government-sector-icon.svg";
import privateSector from "../../../assets/images/private-sector-icon.svg";
import { useNavigate } from "react-router-dom";
import { ssoAuthUriGenerator } from "../../../store/Auth/actions";
import { useDispatch, useSelector } from "react-redux";

const ImageRadioButton = ({ image, label, selected, onClick }) => {
  return (
    <Col xs={12} lg={6} className="text-center mb-4 mb-lg-5">
      <Button
        className={` ${selected ? "active" : ""}`}
        onClick={onClick}
        variant="clear"
      >
        <img src={image} alt={label} aria-hidden="true" />
        <p className="mt-3 mt-lg-4 mb-0 text-primary fs-5">{label}</p>
      </Button>
    </Col>
  );
};

function ChooseCompany() {
  const local = useSelector((state) => state.homeReducer.local);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLoginBySSO = () => {
    dispatch(ssoAuthUriGenerator());
  };
  const options = [
    { id: 2, label: local.privateCompanies, image: privateSector },
    { id: 1, label: local.governmentCompanies, image: governmentSector },
  ];
  const goToNextPage = (id) => {
    if (id === 1) {
      navigate("government/login");
    } else if (id === 2) {
      handleLoginBySSO();
    }
  };
  return (
    <Container className="d-flex justify-content-center align-items-center auth__choose-company mt-5">
      <Row className="align-items-center justify-content-center flex-column">
        <Col xs={12}>
          <ButtonGroup>
            <Row>
              {options.map((option) => (
                <ImageRadioButton
                  key={option.id}
                  label={option.label}
                  image={option.image}
                  selected={selectedOption === option.id}
                  onClick={() => setSelectedOption(option.id)}
                />
              ))}
            </Row>
          </ButtonGroup>
        </Col>
        <Col>
          <Row>
            <Col xs={12} lg={6} className="text-center mb-5 mx-auto">
              <Button
                variant="secondary"
                disabled={!selectedOption}
                className="text-white w-100"
                onClick={() => goToNextPage(selectedOption)}
              >
                {local.continueToLogin}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ChooseCompany;

import { AuthInstance } from "../../utils/axiosConfig";
import { setErrors } from "../Error/actions";
import { resetErrors } from "../Error/epics";
import { setLoading } from "../Common/actions";
import formatData from "../../utils/formatData";
import mergeAnswers from "../../utils/mergeAnswers";
import * as epics from "./epics";
import { setCompanyInfo } from "../Home/epics";
import deleteFileFromQuestion from "../../utils/deleteFileFromArray";

export const fetchCriteriasList = (navigate, assessmentOrderId, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(
        `criteria/?assessment_order_id=${assessmentOrderId}`
      );
      let data = response.data;

      dispatch(epics.setCriteriaIds(data.payload.data));
      const criteriaId = data.payload.data[0].id;

      // Assuming `fetchCriteriaDetails` is another async action
      await dispatch(fetchMyAnswers(assessmentOrderId, criteriaId));
      await dispatch(fetchCriteriaDetails(criteriaId, assessmentOrderId));

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
      navigate(`/${companyId}/certificate/`);
    }
  };
};

export const fetchCriteriaDetails = (criteriaId, assessmentOrderId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      const state = getState();
      const criterias = state.certificateReducer.criterias;
      const myAnswers = state.certificateReducer.myAnswers;

      let criteria = criterias.find((criteria) => criteria.id === criteriaId);
      if (!criteria) {
        let response = await AuthInstance.get(
          `/criteria/${criteriaId}/?assessment_order_id=${assessmentOrderId}`
        );
        let data = response.data;
        criteria = data.payload.data.criteria;
      }

      // next, rely on my Criterias instead of calling an API to get criteriasDetails that I have set on my criterias locally
      dispatch(epics.setCriterias(criteria));
      dispatch(epics.setCriteriaDetails(criteria));

      const formattedData = formatData(criteria);
      const currentPageQuestionsIds = formattedData.map(
        (item) => item.question_id
      );

      dispatch(epics.setCurrentPageQuestionsIds(currentPageQuestionsIds));

      const mergedAnswers = mergeAnswers(formattedData, myAnswers);
      dispatch(epics.setMyAnswers(mergedAnswers));

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const createAssessment = (navigate, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.post(`assessment_order/`, {
        company_id: companyId,
      });

      let data = await response.data;

      dispatch(epics.setAssessmentOrder(data.payload.data));

      navigate(`/${companyId}/certificate/${data.payload.data.id}`);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchAssessmentOrder = (
  navigate,
  orderId,
  companyId,
  currenStatus
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(`assessment_order/${orderId}/`);
      let data = await response.data;
      dispatch(epics.setAssessmentOrder(data.payload.data));
      if (data.payload.data.status !== currenStatus) {
        navigate(`/${companyId}`);
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callSubmitAssessment = (
  navigate,
  payload,
  uuid,
  companyId,
  isLastCriteria
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.post(`answers/`, payload);
      let data = await response.data;

      dispatch(epics.setAssessmentOrder(data));

      if (isLastCriteria) navigate(`/${companyId}/certificate/${uuid}/grade/`);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callSubmitAssessmentEvidence = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      await AuthInstance.post(`answers/upload_evidence/`, payload);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
export const callDeleteAssessmentEvidence = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      await AuthInstance.delete(`answers/delete_evidence/`, { data: payload });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callSubmitAssessmentAfterEvidence = (
  navigate,
  uuid,
  companyId
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      let response = await AuthInstance.post(
        `assessment_order/${uuid}/submit/`
      );
      let data = await response.data;

      dispatch(epics.setAssessmentOrder(data.payload.data));
      navigate(`/${companyId}/certificate/${uuid}/auditing`);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchMyAnswers = (assessmentOrderId, criteriaId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(
        `answers/?assessment_order_id=${assessmentOrderId}&criteria_id=${criteriaId}`
      );

      let data = await response.data;
      if (data.payload.data.length) {
        dispatch(epics.setMyAnswers(data.payload.data));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callRemoveFileFromMyAnswer = (
  fileName,
  question_id,
  evidence_id
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      const state = getState();
      const myAnswers = state.certificateReducer.myAnswers;
      if (evidence_id) {
        const formData = new FormData();
        formData.append("evidence_id", evidence_id);
        dispatch(callDeleteAssessmentEvidence(formData));
      }
      const filteredAnswers = deleteFileFromQuestion(
        fileName,
        myAnswers,
        question_id,
        evidence_id
      );

      dispatch(epics.removeFileFromMyAnswer(filteredAnswers));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchCurrentOrders = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      let response = await AuthInstance.get(
        `assessment_order/?company_id=${companyId}`
      );
      let data = await response.data;

      dispatch(epics.setCurrenOrders(data.payload.data));

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchOldOrders = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(
        `assessment_order/expired/?company_id=${companyId}`
      );
      let data = await response.data;

      dispatch(epics.setOldOrders(data.payload.data));

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const completePrivateCompanyRegisteration = (
  navigate,
  payload,
  companyId
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.patch(
        `companies/${companyId}/`,
        payload
      );
      let data = await response.data;

      dispatch(setCompanyInfo(data.payload.data));
      dispatch(setLoading(false));
      navigate(`/${companyId}/entity`);
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchPreview = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(
        `/assessment_order/preview/?company_id=${companyId}`
      );
      let data = await response.data;
      dispatch(epics.setPreview(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const getCertificate = (assessmentOrderId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(
        `/assessment_order/${assessmentOrderId}/certificate`
      );
      let data = await response.data;
      dispatch(epics.setCertificate(data?.payload?.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
export const downloadReport = (assessmentOrderId) => {
  return async (dispatch) => {
    try {
      let response = await AuthInstance.get(
        `/assessment_order/${assessmentOrderId}/report`
      );
      let data = await response.data;
      const link = document.createElement("a");
      link.href = data?.payload?.data?.url;
      link.download = `report_${assessmentOrderId}`;
      link.target = "_blank";
      link.click();
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
};

export const callApplyAssessmentDiscountCode = (assessmentOrderId, code) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      let response = await AuthInstance.post(
        `assessment_order/${assessmentOrderId}/apply_discount/`,
        code
      );
      let data = await response.data;
      dispatch(epics.setAssessmentOrder(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
export const callRemoveAssessmentDiscountCode = (assessmentOrderId) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      let response = await AuthInstance.post(
        `assessment_order/${assessmentOrderId}/remove_discount/`
      );
      let data = await response.data;
      dispatch(epics.setAssessmentOrder(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const downloadInvoice = (id) => {
  return async (dispatch) => {
    try {
      let response = await AuthInstance.get(`/invoices/${id}/pdf/`);
      let data = await response.data;
      const link = document.createElement("a");
      link.href = data?.payload?.data?.url;
      link.download = `invoice_${id}`;
      link.target = "_blank";
      link.click();
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
};

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function EnConsent({ setConsentGiven }) {
  return (
    <div>
      <div className="mb-2">
        <h4 className="fs-4 fw-bold text-black mb-2">
          We use cookies to improve your experience
        </h4>
        <p className="fs-6 p-1">
          We use cookies to deliver the best possible experience on our website.
          To learn more, visit our{" "}
          <Link
            to="/privacy-policy"
            className="text-primary link-opacity-25-hover link-underline-primary link-offset-1"
          >
            Privacy Policy
          </Link>
          . By continuing to use this site, you consent to our use of cookies.
        </p>
      </div>
      <div>
        <Button
          className="btn btn-primary text-white"
          onClick={() => {
            localStorage.setItem("cookie_consent", true);
            setConsentGiven(true);
          }}
        >
          Accept
        </Button>
      </div>
    </div>
  );
}

export default EnConsent;

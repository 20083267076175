import { AuthInstance } from "../../utils/axiosConfig";
import { setErrors } from "../Error/actions";
import { resetErrors } from "../Error/epics";
import { setLoading } from "../Common/actions";
import * as epics from "./epics";
import { fetchCompanyInfo } from "../Home/actions";

export const createWorkshopRequest = (navigate, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.post(`workshop_requests/`, {
        company_id: companyId,
      });
      let data = await response.data;
      dispatch(epics.setWorkshopRequest(data.payload.data));
      dispatch(fetchCompanyInfo(companyId));
      navigate(`/${companyId}/workshop/successful`);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

import React from "react";
import Hero from "./components/hero";
import Steps from "./components/steps";
import GoldCertified from "./components/goldCertified";
import AboutMowaamah from "./components/about";
import Specialities from "./components/specialities";

function MainPage() {
  return (
    <div className="main">
      <Hero />
      <Specialities />
      <Steps />
      <GoldCertified />
      <AboutMowaamah />
    </div>
  );
}

export default MainPage;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { Button, Card, Container } from "react-bootstrap";
import { orderType } from "../../../lookups/status";

const Grade = () => {
  const dispatch = useDispatch();
  let { uuid, companyId } = useParams();

  const local = useSelector((state) => state.homeReducer.local);
  const loading = useSelector((state) => state.commonReducer.loading);
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const assessmentOrder = useSelector(
    (state) => state.certificateReducer.assessmentOrder
  );
  const navigate = useNavigate();

  useLegacyEffect(() => {
    dispatch(
      fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
    );
    dispatch(setCurrentCertificateFlowStep(0.5));
  }, []);

  const handleNextStep = () => {
    navigate(
      `/${companyId}/payment/${uuid}/review?type=${orderType.certificate}`
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="grade my-5">
      <ChevronBar
        type={"shape"}
        stepsClass={"steps-flow-container"}
        stepClass={"step-flow"}
        stepText={"step-flow-text"}
        stepTextCurrent={"step-flow-text--current"}
        stepTextUncompleted={"step-flow-text--uncompleted"}
        stepTextCompleted={"step-flow-text--completed"}
        stepCompletedClass={"step-home--completed"}
        steps={flowSteps}
        currentStep={currentCertificateFlowStep}
      />
      <Card className="shadow p-4">
        <div className="text-center mt-3 d-flex flex-column align-items-center">
          <p className="mb-0 fs-4 text-success">{local.initialScore}</p>
          <h1 className="mb-1 text-success display-1">
            {assessmentOrder.score}%
          </h1>
          <p className="w-75 mb-4">
            {assessmentOrder.score > 14 ? local.highScore : local.lowScore}
          </p>

          <p className="card border border-tertiary py-2 px-4 grade-message-box fs-5 text-primary">
            {local.gradeNote1}
          </p>
        </div>
      </Card>

      <h6 className="text-success fs-4 text-center mt-5">{local.gradeNote2}</h6>

      <div className="text-center mt-4">
        <Button
          variant="secondary"
          className="text-white w-50"
          type="button"
          size="lg"
          onClick={handleNextStep}
        >
          {local.payCertificateFees}
        </Button>
      </div>

      <div className="text-center mt-4">
        <Button
          variant="link"
          className="text-secondary"
          onClick={() => navigate(`/${companyId}/`)}
        >
          {local.backToHomePage}
        </Button>
      </div>
    </Container>
  );
};

export default Grade;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import LeafletMap from "../../../shared/LeafletMap";
import { Button, Card, Container } from "react-bootstrap";
import TextInput from "../../../shared/FormElements/TextInput";
import Checkbox from "../../../shared/FormElements/Checkbox";
import MobileInput from "../../../shared/FormElements/MobileInput";
import onChangeHandler from "../../../utils/onChangeHandler";
import { resetError } from "../../../store/Error/epics";
import formValidaiton from "../../../utils/formValidaiton";
import { completePrivateCompanyRegisteration } from "../../../store/Certificate/actions";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import isEmpty from "../../../utils/isEmpty";
import DropDownInput from "../../../shared/FormElements/DropDownInput";
import { fetchCitiesList } from "../../../store/Home/actions";
import { toast } from "react-toastify";

const Update = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const loading = useSelector((state) => state.commonReducer.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const user = useSelector((state) => state.authReducer.user);
  const errors = useSelector((state) => state.errorReducer.errors);
  const regionsList = useSelector((state) => state.homeReducer.regionsList);
  const citiesList = useSelector((state) => state.homeReducer.citiesList);
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");

  const regionsListOptions =
    (!isEmpty(regionsList) &&
      regionsList.map((region) => ({
        value: region.id,
        label: local.language === "ar" ? region?.name_ar : region?.name_en,
      }))) ||
    [];

  const citiesListOptions =
    (!isEmpty(citiesList) &&
      citiesList.map((city) => ({
        value: city.id,
        label: local.language === "ar" ? city?.name_ar : city?.name_en,
      }))) ||
    [];

  const [state, setState] = useState({
    name_en: companyInfo.name_en || "",
    tax_number: companyInfo.tax_number || "",
    phone_number: companyInfo.phone_number || "",
    phone_number_extention: companyInfo.phone_number_extention || "",
    email: companyInfo.email || "",
    extra_email: companyInfo.extra_email || "",
    region_info: {
      name_ar: companyInfo?.address?.region_name_ar || "",
    },
    city_info: {
      name_ar: companyInfo?.address?.city_name_ar || "",
      region_id: companyInfo?.address?.region_id || "",
    },
    city: companyInfo?.address?.city_id || "",
    district: companyInfo?.address?.district || "",
    street: companyInfo?.address?.street || "",
    building_number: companyInfo?.address?.building_number || "",
    secondary_number: companyInfo?.address?.secondary_number || "",
    postal_code: companyInfo?.address?.postal_code || "",
    latitude: companyInfo?.address?.latitude || "",
    longitude: companyInfo?.address?.longitude || "",
    user_full_name_en: user.full_name_en || "",
    user_email: user.email || "",
    user_phone_number: user.phone_number || "",
    confirm: false,
  });

  useLegacyEffect(() => {
    setState({
      name_en: companyInfo.name_en || "",
      tax_number: companyInfo.tax_number || "",
      phone_number: companyInfo.phone_number || "",
      phone_number_extention: companyInfo.phone_number_extention || "",
      email: companyInfo.email || "",
      extra_email: companyInfo.extra_email || "",
      region_info: {
        name:
          local.language === "ar"
            ? companyInfo?.address?.region_name_ar
            : companyInfo?.address?.region_name_en || "",
      },
      city_info: {
        name:
          local.language === "ar"
            ? companyInfo?.address?.city_name_ar
            : companyInfo?.address?.city_name_en || "",

        region_id: companyInfo?.address?.region_id || "",
      },
      city: companyInfo?.address?.city_id || "",
      district: companyInfo?.address?.district || "",
      street: companyInfo?.address?.street || "",
      building_number: companyInfo?.address?.building_number || "",
      secondary_number: companyInfo?.address?.secondary_number || "",
      postal_code: companyInfo?.address?.postal_code || "",
      latitude: companyInfo?.address?.latitude || "",
      longitude: companyInfo?.address?.longitude || "",
      user_full_name_en: user.full_name_en || "",
      user_email: user.email || "",
      user_phone_number: user.phone_number || "",
      confirm: false,
    });

    if (
      !isEmpty(companyInfo) &&
      !companyInfo.able_to_edit &&
      companyInfo.is_registration_completed
    ) {
      navigate(`/${companyId}/entity`);
    }
  }, [companyInfo, user]);

  const handleSubmit = () => {
    const validationFormData = {
      name_en: { value: state.name_en, type: "text", required: true },
      tax_number: { value: state.tax_number, type: "number", required: true },
      phone_number: {
        value: state.phone_number,
        type: "mobile",
        required: true,
      },
      phone_number_extention: {
        value: state.phone_number_extention,
        type: "number",
        required: false,
      },
      email: { value: state.email, type: "email", required: true },
      extra_email: {
        value: state.extra_email,
        type: "extra_email",
        required: false,
      },
      city: {
        value: selectedCityId.value,
        type: "text",
        required: true,
      },
      district: { value: state.district, type: "text", required: true },
      street: { value: state.street, type: "text", required: true },
      building_number: {
        value: state.building_number,
        type: "number",
        required: true,
      },
      secondary_number: {
        value: state.secondary_number,
        type: "number",
        required: true,
      },
      postal_code: { value: state.postal_code, type: "number", required: true },
      latitude: { value: state.latitude, type: "text", required: true },
      longitude: { value: state.longitude, type: "text", required: true },
      user_full_name_en: {
        value: state.user_full_name_en,
        type: "text",
        required: true,
      },
      user_email: { value: state.user_email, type: "text", required: true },
      user_phone_number: {
        value: state.user_phone_number,
        type: "mobile",
        required: true,
      },
      confirm: { value: state.confirm, type: "boolean", required: true },
    };

    const isValidForm = formValidaiton(validationFormData, dispatch);

    if (isValidForm) {
      dispatch(completePrivateCompanyRegisteration(navigate, state, companyId));
    } else {
      if (state.confirm) {
        window.scrollTo(0, 0);
        if (state.latitude === "") {
          toast.error(local.fillMapFeild);
        } else {
          toast.error(local.correctFormErrors);
        }
      } else {
        toast.error(local.correctFormErrors);
      }
    }
  };

  const handleOnChange = (event) => {
    onChangeHandler(event, setState, state, errors, dispatch, resetError);
  };
  const handleSelectedCity = (data) => {
    setSelectedCityId(data);
    state.city = data.value;
  };
  const handleSelectedRegion = (data) => {
    setSelectedRegionId(data);
    state.city_info = "";
    state.city = "";
    setSelectedCityId("");
    dispatch(fetchCitiesList(data.value));
  };

  const setOnMapChange = (position) => {
    setState((prevState) => ({
      ...prevState,
      latitude: position.lat,
      longitude: position.lng,
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="entity my-5">
      <div className="grid">
        <h2 className="g-col-md-9 g-col-7">{local.updateCompanyInfo}</h2>
      </div>
      <Card className="shadow p-4 mt-5">
        <h3 className="mb-4 text-primary">{local.basicInfo}</h3>
        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              disabled={true}
              labelClass={"text-primary"}
              inputId={"name_ar"}
              type={"text"}
              label={local.companyName_ar}
              inputValue={companyInfo.name}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"name_en"}
              type={"text"}
              label={local.companyName_en}
              inputName={"name_en"}
              inputValue={state.name_en}
              isInvalid={errors.name_en}
              error={errors.name_en}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>
        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              disabled={true}
              labelClass={"text-primary"}
              inputId={"seven_hundred"}
              type={"text"}
              label={local.companyNo}
              inputValue={companyInfo.seven_hundred || ""}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              disabled={true}
              labelClass={"text-primary"}
              inputId={"company_size"}
              type={"text"}
              label={local.companySize}
              inputValue={
                local.language === "ar"
                  ? companyInfo?.type?.type_ar
                  : companyInfo?.type?.type_en
              }
            />
          </div>
        </div>
        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              disabled={true}
              labelClass={"text-primary"}
              inputId={"commercial_record_number"}
              type={"text"}
              label={local.commercialRecord}
              inputValue={companyInfo.commercial_record_number}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"tax_number"}
              type={"number"}
              label={local.taxNumber}
              inputName={"tax_number"}
              inputValue={state.tax_number}
              isInvalid={errors.tax_number}
              error={errors.tax_number}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>

        <hr />
        <h3 className="mb-4 mt-3">{local.contactInfo}</h3>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <MobileInput
              labelClass={"text-primary"}
              inputId={"phone_number"}
              type={"text"}
              label={local.comapnyPhoneNumber}
              inputName={"phone_number"}
              inputValue={state.phone_number}
              isInvalid={errors.phone_number}
              error={errors.phone_number}
              onChange={handleOnChange}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"phone_number_extention"}
              type={"number"}
              label={local.phoneNumberExtention}
              inputName={"phone_number_extention"}
              inputValue={state.phone_number_extention}
              isInvalid={errors.phone_number_extention}
              error={errors.phone_number_extention}
              onChange={handleOnChange}
              required={false}
            />
          </div>
        </div>
        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"email"}
              type={"email"}
              label={local.email}
              inputName={"email"}
              inputValue={state.email}
              isInvalid={errors.email}
              error={errors.email}
              onChange={handleOnChange}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"extra_email"}
              type={"email"}
              label={local.extraEmail}
              inputName={"extra_email"}
              inputValue={state.extra_email}
              isInvalid={errors.extra_email}
              error={errors.extra_email}
              onChange={handleOnChange}
              required={false}
            />
          </div>
        </div>

        <hr />
        <h3 className="mb-4 mt-3">{local.companyAddress}</h3>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <DropDownInput
              isregion={true}
              labelClass={"text-primary"}
              inputId="region"
              options={regionsListOptions}
              defaultOption={state.region_info}
              label={local.region}
              inputName={"region"}
              inputValue={selectedRegionId}
              isInvalid={errors.region}
              error={errors.region}
              onChange={handleSelectedRegion}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <DropDownInput
              iscity={true}
              labelClass={"text-primary"}
              inputId="city"
              options={citiesListOptions}
              defaultOption={state.city_info}
              label={local.city}
              inputName={"city"}
              inputValue={selectedCityId}
              isInvalid={errors.city}
              error={errors.city}
              onChange={handleSelectedCity}
              required={true}
            />
          </div>
        </div>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"district"}
              label={local.district}
              inputName={"district"}
              inputValue={state.district}
              isInvalid={errors.district}
              error={errors.district}
              onChange={handleOnChange}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"street"}
              label={local.street}
              inputName={"street"}
              inputValue={state.street}
              isInvalid={errors.street}
              error={errors.street}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"building_number"}
              type={"number"}
              label={local.buildingNumber}
              inputName={"building_number"}
              inputValue={state.building_number}
              isInvalid={errors.building_number}
              error={errors.building_number}
              onChange={handleOnChange}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"secondary_number"}
              type={"number"}
              label={local.secondaryNumber}
              inputName={"secondary_number"}
              inputValue={state.secondary_number}
              isInvalid={errors.secondary_number}
              error={errors.secondary_number}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"postal_code"}
              type={"number"}
              label={local.postalCode}
              inputName={"postal_code"}
              inputValue={state.postal_code}
              isInvalid={errors.postal_code}
              error={errors.postal_code}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid mb-5">
          <div className="g-col-12">
            <p className="text-primary fs-5 fw-medium">{local.location}</p>
            <LeafletMap
              zoom={15}
              scrollWheelZoom={false}
              draggable={true}
              setPosition={setOnMapChange}
              position={{
                lat: state.latitude || 24.652,
                lng: state.longitude || 46.7138,
              }}
              name={companyInfo.name}
              error={errors.latitude}
            />
          </div>
        </div>

        <hr />
        <h3 className="mb-4 mt-3">{local.userInfo}</h3>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"user_full_name_ar"}
              type={"text"}
              label={local.userName_ar}
              inputValue={user.full_name_ar}
              disabled={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"user_full_name_en"}
              type={"text"}
              label={local.userName_en}
              inputName={"user_full_name_en"}
              inputValue={state.user_full_name_en}
              isInvalid={errors.user_full_name_en}
              error={errors.user_full_name_en}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>

        <div className="grid mb-5">
          <div className="g-col-12 g-col-md-6">
            <MobileInput
              labelClass={"text-primary"}
              inputId={"user_phone_number"}
              type={"text"}
              label={local.userPhoneNumber}
              inputName={"user_phone_number"}
              inputValue={state.user_phone_number}
              isInvalid={errors.user_phone_number}
              error={errors.user_phone_number}
              onChange={handleOnChange}
              required={true}
            />
          </div>
          <div className="g-col-12 g-col-md-6">
            <TextInput
              labelClass={"text-primary"}
              inputId={"user_email"}
              type={"email"}
              label={local.userEmail}
              inputName={"user_email"}
              inputValue={state.user_email}
              isInvalid={errors.user_email}
              error={errors.user_email}
              onChange={handleOnChange}
              required={true}
            />
          </div>
        </div>
      </Card>
      <div className="grid my-4 ">
        <div className="g-col-12 g-col-md-12">
          <Checkbox
            inputId={"confirm_id"}
            checked={state.confirm}
            inputValue={state.confirm}
            label={local.confirmToEntityUpdate}
            inputName={"confirm"}
            isInvalid={errors.confirm}
            error={errors.confirm}
            onChange={handleOnChange}
            required={true}
          />
        </div>
      </div>

      <div className="form-btn my-4 d-flex justify-content-around">
        <Button
          variant="secondary"
          className="text-white w-50"
          onClick={handleSubmit}
        >
          {local.saveInfo}
        </Button>
      </div>
    </Container>
  );
};

export default Update;

export const SET_CRITERIA_IDS = "SET_CRITERIA_IDS";
export const SET_CRITERIAS = "SET_CRITERIAS";
export const SET_CRITERIA_DETAILS = "SET_CRITERIA_DETAILS";
export const SET_MY_ANSWERS = "SET_MY_ANSWERS";
export const REMOVE_FILE_FROM_MY_ANSWER = "REMOVE_FILE_FROM_MY_ANSWER";
export const SET_CURRENT_PAGE_QUESTIONS_IDS = "SET_CURRENT_PAGE_QUESTIONS_IDS";
export const SET_ASSESSMENT_ORDER = "SET_ASSESSMENT_ORDER";
export const SET_CURRENT_CERTIFICATE_FLOW_STEP =
  "SET_CURRENT_CERTIFICATE_FLOW_STEP";
export const SET_CURRENT_ORDERS = "SET_CURRENT_ORDERS";
export const SET_OLD_ORDERS = "SET_OLD_ORDERS";
export const SET_PREVIEW = "SET_PREVIEW";
export const SET_CERTIFICATE = "SET_CERTIFICATE";

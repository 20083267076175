import { setError } from "../store/Error/epics";
import isEmpty from "../utils/isEmpty";

const isSizeOk = (evidence) => {
  return evidence.filter((item) => {
    return item.size > 5000000;
  });
};

const assessmentValidation = (
  myAnswers,
  dispatch,
  location,
  currentPageQuestionsIds,
  uuid,
  companyId,
  local
) => {
  let isValid = true;
  const updatedErrors = {};
  myAnswers
    .filter((answer) => currentPageQuestionsIds.includes(answer.question_id))
    .forEach((answer) => {
      const question_id = answer.question_id.toString();

      if (answer.value === "" || !answer?.value) {
        isValid = false;
        updatedErrors[question_id] = {
          ...updatedErrors[question_id],
          option: local.cantLeavThisFieldEmpty,
        };
      }
      if (
        location === `/${companyId}/certificate/${uuid}/evidence` &&
        answer.value === "NOT_APPLICABLE" &&
        isEmpty(answer.justification)
      ) {
        isValid = false;
        updatedErrors[question_id] = {
          ...updatedErrors[question_id],
          justification: local.pleaseWriteJustification,
        };
      }
      if (
        location === `/${companyId}/certificate/${uuid}/evidence` &&
        answer.value === "YES" &&
        answer.evidences?.length === 0
      ) {
        isValid = false;
        updatedErrors[question_id] = {
          ...updatedErrors[question_id],
          evidences: local.pleaseAddEvidence,
        };
      }
      if (
        location === `/${companyId}/certificate/${uuid}/evidence` &&
        answer.value === "YES" &&
        answer.evidences?.length > 0 &&
        isSizeOk(answer.evidences).length > 0
      ) {
        isValid = false;
        updatedErrors[question_id] = {
          ...updatedErrors[question_id],
          evidences: local.pleaseCheckFileSize,
        };
      }
    });

  dispatch(setError(updatedErrors));
  return isValid;
};

export default assessmentValidation;

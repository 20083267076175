import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import logoSadad from "../../../assets/images/sadad.png";
import { Button, Container } from "react-bootstrap";

const SadadDetails = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const local = useSelector((state) => state.homeReducer.local);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="card justify-content-center">
            <div className=" text-center mb-5">
              <img className="payment-img" alt="SADAD" src={logoSadad} />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-md-4">
          <Button
            variant="secondary"
            className="text-white w-100"
            type="button"
            onClick={() => navigate(`/${companyId}/payment/sadad/invoice`)}
          >
            {local.proceed}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SadadDetails;

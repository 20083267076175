import React from "react";
import ArTerms from "./ar-terms";
import { useSelector } from "react-redux";
import EnTerms from "./en-terms";

function TermsCondition() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <div className="mt-5 mb-5 container">
      {local.language === "ar" ? <ArTerms /> : <EnTerms />}
    </div>
  );
}

export default TermsCondition;

import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import {
  createAssessment,
  fetchPreview,
} from "../../../store/Certificate/actions";
import { Container, Button, Card, Overlay, Tooltip } from "react-bootstrap";
import { companyTypes } from "../../../lookups/status";

const Instructions = () => {
  const dispatch = useDispatch();
  const [infoOpen, setInfoOpen] = useState(false);
  const target = useRef(null);

  const local = useSelector((state) => state.homeReducer.local);
  const loading = useSelector((state) => state.commonReducer.loading);
  const preview = useSelector((state) => state.certificateReducer.preview);
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const navigate = useNavigate();
  const { companyId } = useParams();
  useLegacyEffect(() => {
    const handleOutsideClick = (event) => {
      if (target.current && !target.current.contains(event.target)) {
        setInfoOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useLegacyEffect(() => {
    dispatch(fetchPreview(companyId));
  }, [companyId]);

  const cratiraList = preview?.criteria?.map((item, index) => (
    <p
      key={index}
      className="g-col-6 g-col-md-4 g-col-lg-3 fs-6 text-dark fw-medium"
    >
      {index + 1}. {item}
    </p>
  ));

  const sectionList = preview?.sections?.map((item, index) => (
    <p
      key={index}
      className="g-col-6 g-col-md-4 g-col-lg-3 fs-6 text-dark fw-medium"
    >
      {index + 1}. {item}
    </p>
  ));

  const companyTypeObj = companyTypes(local).find(
    (element) => element?.id === preview.company_type
  );

  useLegacyEffect(() => {
    dispatch(setCurrentCertificateFlowStep(0));
  }, []);

  const handleCertificateRequest = () => {
    dispatch(createAssessment(navigate, companyId));
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="instructions">
      <ChevronBar
        type={"shape"}
        stepsClass={"steps-flow-container"}
        stepClass={"step-flow"}
        stepText={"step-flow-text"}
        stepTextCurrent={"step-flow-text--current"}
        stepTextUncompleted={"step-flow-text--uncompleted"}
        stepTextCompleted={"step-flow-text--completed"}
        stepCompletedClass={"step-home--completed"}
        steps={flowSteps}
        currentStep={currentCertificateFlowStep}
      />

      <h3 className="mb-5">
        {local.selfAssessment}
        <span
          className="d-inline d-md-none mx-2"
          onClick={() => setInfoOpen(!infoOpen)}
        >
          <i ref={target} className="bi bi-info-circle text-secondary"></i>
          <Overlay target={target.current} show={infoOpen} placement="bottom">
            {(props) => (
              <Tooltip className="info-card" {...props}>
                {local.mowaamahCertificateEvaluationNote}
              </Tooltip>
            )}
          </Overlay>
        </span>
      </h3>
      <Card className="shadow p-4">
        <div className="mb-4">
          <span className="text-primary fs-5 fw-bold">
            {local.assessmentType}
          </span>
          <span className="text-success fs-5 fw-bold">
            {local.assessment + companyTypeObj?.text}
          </span>
        </div>

        <div className="mb-3">
          <span className="text-primary fs-5 fw-bold">
            {local.numberOfQuestions}
          </span>
          <span className="text-success fs-5 fw-bold">
            {preview.questions_count} {local.question}
          </span>
        </div>

        <hr />

        <h1 className="fs-6 text-dark mb-4">{local.previewIntroLine}</h1>
        <h1 className="fs-5 text-primary font-weight-bold">
          {local.criteria} :{" "}
        </h1>

        <div className="grid gap-1 mb-4">{cratiraList}</div>

        <h1 className="fs-5 text-primary font-weight-bold">
          {local.sections} :{" "}
        </h1>

        <div className="grid gap-1 mb-4">{sectionList}</div>

        <p className="fs-6 text-dark fw-medium mb-2">{local.assessmentNote1}</p>
        <p className="fs-6 text-dark fw-medium mb-1">{local.assessmentNote2}</p>
      </Card>

      <div className="grid mt-4 wizard-buttons">
        <Button
          variant="secondary"
          className="g-col-12 w-100 g-col-lg-4 text-white wizard-buttons--proceed"
          onClick={handleCertificateRequest}
        >
          {local.startAssessment}
        </Button>

        <Button
          variant="link"
          className="g-col-12 w-100 g-col-lg-3 text-dark wizard-buttons--cancel"
          onClick={() => navigate(`/${companyId}/certificate`)}
        >
          {local.cancel}
        </Button>
      </div>
    </Container>
  );
};

export default Instructions;

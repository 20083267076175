const RadioInputOptions = ({
  wrapperClass,
  checked,
  htmlFor,
  labelClass,
  label,
  inputName,
  inputValue,
  type,
  inputClass,
  inputId,
  onChange,
}) => {
  return (
    <div className={wrapperClass}>
      <input
        checked={checked}
        onChange={onChange}
        value={inputValue || ""}
        name={inputName}
        className={inputClass}
        type={type}
        id={inputId}
      />
      <div className={labelClass} htmlFor={inputId}>
        {label}
      </div>
    </div>
  );
};

RadioInputOptions.defaultProps = {
  wrapperClass: "form-check form-check-inline",
  htmlFor: "",
  labelClass: "form-check-label",
  required: true,
  type: "radio",
  inputClass: "form-check-input",
  inputId: "",
  inputValue: "",
};

export default RadioInputOptions;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callSendOtp, callVerifyOtp } from "../../../../store/Auth/actions";
import Loader from "../../../Common/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import TextInput from "../../../../shared/FormElements/TextInput";
import { resetError, resetErrors } from "../../../../store/Error/epics";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import formValidaiton from "../../../../utils/formValidaiton";
import onChangeHandler from "../../../../utils/onChangeHandler";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import BackButton from "../../../../shared/BackButton";

const GovernemntOtp = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const [seconds, setSeconds] = useState(60); // Initial time in seconds

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const errors = useSelector((state) => state.errorReducer.errors);
  const loading = useSelector((state) => state.commonReducer.loading);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const otpInfo = useSelector((state) => state.authReducer.otpInfo);

  useLegacyEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useLegacyEffect(() => {
    dispatch(resetErrors());
    if (!otpInfo.email) {
      navigate(-1);
    }
  }, []);
  useLegacyEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);
  const [user, setUser] = useState({
    otp: "",
  });

  const handleOnChange = (event) => {
    onChangeHandler(event, setUser, user, errors, dispatch, resetError);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationFormData = {
      otp: { value: user.otp, type: "text", required: true },
    };
    const isValidForm = formValidaiton(validationFormData, dispatch);

    if (isValidForm) {
      const data = {
        otp_session_id: otpInfo.otp_session_id,
        otp: user.otp,
      };
      dispatch(callVerifyOtp(data, navigate));
    }
  };
  const changeOtpMethod = (method) => {
    const data = {
      email: otpInfo.email,
      password: otpInfo.password,
      otp_method: method,
    };
    setSeconds(60);
    dispatch(callSendOtp(navigate, data));
  };
  const resendOtp = (method) => {
    const data = {
      email: otpInfo.email,
      password: otpInfo.password,
      otp_method: method,
    };
    setSeconds(60);
    dispatch(callSendOtp(navigate, data));
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} label={local.backV2} />
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6}>
          <p className="fs-4 text-primary">{local.governmentLogin}</p>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={6}>
            <Card className="login-card shadow w-100 px-4 py-5 px-lg-5 mb-5">
              <TextInput
                inputId={"otp"}
                type={"text"}
                label={
                  otpInfo.otp_method === "SMS"
                    ? local.sentOtpToPhone + otpInfo.masked_user_reference
                    : local.sentOtpToEmail + otpInfo.masked_user_reference
                }
                inputName={"otp"}
                inputValue={user.otp}
                isInvalid={errors.otp}
                error={errors.otp}
                onChange={handleOnChange}
              />
              <div className="input-group justify-content-center justify-content-md-between align-items-center">
                <p className="mb-0 text-primary">
                  {local.askAnotherOtp}
                  <span className="font-weight-bold text-success ms-2">{seconds}</span>
                </p>
                <Button
                  className="text-secondary"
                  disabled={seconds > 1}
                  variant="link"
                  onClick={() => resendOtp(otpInfo.otp_method)}
                >
                  {local.resendOtp}
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center mb-5">
          <Col xs={12} lg={6} className="text-center">
            <Button
              variant="secondary"
              className="text-white w-75"
              type="submit"
            >
              {local.signIn}
            </Button>
            <Button
              variant="outline-secondary"
              className="w-75 my-3"
              onClick={() =>
                changeOtpMethod(otpInfo.otp_method === "SMS" ? "EMAIL" : "SMS")
              }
            >
              {otpInfo.otp_method === "SMS"
                ? local.sendOtpToEmail
                : local.sendOtpToPhone}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default GovernemntOtp;

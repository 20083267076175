import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { resetErrors } from "../../../../store/Error/epics";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { Button, Container } from "react-bootstrap";
import ChevronBar from "../../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../../store/Certificate/epics";
import useQuery from "../../../../hooks/useQuery";
import { fetchAssessmentOrder } from "../../../../store/Certificate/actions";
import { fetchTrainingOrder } from "../../../../store/Training/actions";
import { orderType } from "../../../../lookups/status";
import formatDate from "../../../../utils/formatDate";
import SurveyModal from "../../Survey";

const GenerateSadadInvoice = () => {
  let { companyId, uuid } = useParams();

  useLegacyEffect(() => {
    dispatch(resetErrors());
  }, []);
  const local = useSelector((state) => state.homeReducer.local);
  const query = useQuery();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const dispatch = useDispatch();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  var order = useSelector((state) =>
    query.get("type") === orderType.certificate
      ? state.certificateReducer.assessmentOrder
      : query.get("type") === orderType.training
      ? state.trainingReducer.trainingOrder
      : ""
  );
  const formattedDate = formatDate(order?.sadad?.expiry, "dateTime");

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_SADAD_PAYMENT")
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, "PENDING_SADAD_PAYMENT")
      );
    } else {
      navigate(`/${companyId}`);
    }
    dispatch(resetErrors());
  }, []);
  const goToOrders = () => {
    if (query.get("type") === orderType.certificate) {
      navigate(`/${companyId}/certificate`);
    } else if (query.get("type") === orderType.training) {
      navigate(`/${companyId}/training`);
    }
  };
  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          {query.get("type") === orderType.certificate && (
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          )}

          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <div className="card shadow p-4 p-lg-5 mb-4 mt-5">
                <h1 className="mb-3 text-success fs-4">
                  {local.sadadInvoiceSuccessful}
                </h1>
                {query.get("type") === orderType.certificate && (
                  <p className="mb-4">{local.sadadInvoiceCertificateNote}</p>
                )}
                {query.get("type") === orderType.training && (
                  <p className="mb-4">{local.sadadInvoiceTrainingNote}</p>
                )}

                <div className="line"></div>

                <div className="mt-3 grid">
                  <div className="g-col-6 g-col-lg-4">
                    <p>{local.totalCost}</p>
                    <p>{local.sadadInvoiceNumber}</p>
                    <p>{local.validUntil}</p>
                    <p>{local.paymentFor}</p>
                    <p>{local.sadadCode}</p>
                  </div>
                  <div className="text-success g-col-6 g-col-lg-8">
                    <p>{order?.sadad?.amount}</p>
                    <p>{order?.sadad?.sadad_invoice_number}</p>
                    <p>{formattedDate}</p>
                    <p>{order?.sadad?.company_name}</p>
                    <p>{order?.sadad?.company_number}</p>
                  </div>
                </div>
              </div>

              <p className="text-center">{local.sadadInvoiceNote}</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <Button
                variant="secondary"
                className="text-white w-100 mt-3"
                type="button"
                onClick={() => goToOrders()}
              >
                {local.backToHomePage}
              </Button>
            </div>
          </div>
        </>
      )}
      <SurveyModal uuid={uuid} />
    </Container>
  );
};

export default GenerateSadadInvoice;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Container } from "react-bootstrap";

function Register() {
  const navigate = useNavigate();
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="about-register">
      <Container>
        <Row className="justify-content-end align-items-center">
          <Col xs={12} lg={6} xl={4}>
            <h2 className="text-success fs-1 mb-5 z-10">
              {local.registerNowNote}
            </h2>
            <Button
              onClick={() => navigate("/auth")}
              variant="secondary"
              className="text-white z-10"
              type="submit"
            >
              {local.registerNow}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Register;

import addQuestionAndFilesAndAnswers from "./addQuestionAndFilesAndAnswers";
import addFiles from "./addFiles";

const onChangeHandler = (
  event,
  setState,
  state,
  errors,
  dispatch,
  resetError,
  type,
  question_id
) => {
  if (type === "assesment") {
    addQuestionAndFilesAndAnswers(
      event,
      setState,
      state,
      question_id,
      dispatch
    );
  } else if (event.target.files) {
    addFiles(setState, state, event);
  } else if (event.target.type === "checkbox") {
    setState({ ...state, [event.target.name]: !state[event.target.name] });
  } else {
    setState({ ...state, [event.target.name]: event.target.value });
  }

  const errorFound = event.target.name in errors;
  if (errorFound) {
    dispatch(resetError(event.target.name));
  }
};

export default onChangeHandler;

// onChangeHandler(event,setState,state,errors,dispatch,resetError)

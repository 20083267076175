import { SET_TRANSACTION, SET_PAYMENT_STATUS } from "./types";

const initialState = {
  checkoutId: "",
  transactionId: "",
  status: "PENDING",
  transaction: {},
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };

    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };

    default:
      return state;
  }
};

export default paymentReducer;

import React, { useRef } from "react";
import { callLogout, fetchUserInfo } from "../../../../store/Auth/actions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../../utils/isEmpty";
import { useNavigate } from "react-router-dom";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { resetErrors } from "../../../../store/Error/epics";
import {
  fetchPrivateCompaniesList,
  fetchGovCompaniesList,
  fetchCompanyInfo,
} from "../../../../store/Home/actions";
import Dropdown from "react-multilevel-dropdown";

const Menu = ({ isLanding }) => {
  const local = useSelector((state) => state.homeReducer.local);
  const user = useSelector((state) => state.authReducer.user);
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const myCompaniesList = useSelector(
    (state) => state.homeReducer.myCompaniesList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  useLegacyEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(resetErrors());
  }, []);
  useLegacyEffect(() => {
    if (isEmpty(myCompaniesList) && user.user_type === "GOVERNMENT") {
      dispatch(fetchGovCompaniesList(navigate, local));
    } else if (isEmpty(myCompaniesList) && user.user_type === "PRIVATE") {
      dispatch(fetchPrivateCompaniesList({ is_login: false }));
    }
  }, [user]);

  const handleLogout = () => {
    dispatch(callLogout());
  };
  const handleSelect = (comapnyId) => {
    if (ref.current) {
      ref.current.toggle();
    }
    const company = myCompaniesList.find((company) => company.id === comapnyId);
    localStorage.setItem("companyName", company.name);
    dispatch(fetchCompanyInfo(comapnyId, navigate));

    // if (company.is_registration_completed) {
    //   navigate(`/${comapnyId}`);
    // } else {
    //   navigate(`/${comapnyId}/entity/update`);
    // }
  };

  return (
    <Dropdown
      className="text-dark d-flex d-lg-block mx-4 outer"
      title={
        <>
          <span className={`${companyInfo?.name?.length < 10 ? "ms-5" : ""}`}>
            {!isEmpty(companyInfo)
              ? local.language === "ar"
                ? companyInfo.name
                : companyInfo.name_en
              : localStorage.getItem("companyName")}
          </span>
          <i className="bi bi-caret-down-fill mt-1"></i>
        </>
      }
      menuClassName="header-menu"
      ref={ref}
    >
      <Dropdown.Item className="text-dark justify-content-between fs-6">
        {isLanding ? local.companiesList : local.changeCompany}
        <i
          className={`bi bi-caret-${
            local.language === "en" ? "right" : "left"
          }-fill`}
        ></i>
        <Dropdown.Submenu className="header-menu" position="right">
          {!isEmpty(myCompaniesList) &&
            myCompaniesList.map((company) => (
              <Dropdown.Item
                isDisabled={companyInfo?.name === company?.name}
                className="text-dark fs-6 submenu"
                key={company.id}
                onClick={() => handleSelect(company.id)}
              >
                {local.language === "ar" ? company.name : company.name_en}
              </Dropdown.Item>
            ))}
        </Dropdown.Submenu>
      </Dropdown.Item>
      <Dropdown.Item className="text-dark fs-6" onClick={handleLogout}>
        {local.signOut}
      </Dropdown.Item>
    </Dropdown>
  );
};

export default Menu;

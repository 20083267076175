const RadioInput = ({
  htmlFor,
  labelClass,
  label,
  isInvalid,
  outerWrapperClass,
  question,
  innerWrapperClass,
  error,
  options,
  defaultOption,
}) => {
  return (
    <div className={outerWrapperClass}>
      <label
        htmlFor={htmlFor}
        className={error ? labelClass + " isInvalid" : labelClass}
      >
        {label}
      </label>
      <div className={innerWrapperClass}>
        <p className="question mb-0 d-flex justify-content-start">{question}</p>
        <div className="options d-flex flex-row justify-content-md-end">{options}</div>
      </div>
      <div id="validationServerUsernameFeedback" className="text-danger p-2">
        {error}
      </div>
    </div>
  );
};

RadioInput.defaultProps = {
  defaultOption: "",
  options: [],
  htmlFor: "",
  labelClass: "form-label",
  required: true,
  type: "text",
  outerWrapperClass: "mowaamah-radio d-flex flex-column",
  innerWrapperClass: "d-flex flex-column flex-md-row justify-content-between w-100",
  isInvalid: false,
  inputId: "",
  error: "",
};

export default RadioInput;

import React, { useRef } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ certificate }) => {
  const documentWrapperRef = useRef(null);
  return (
    <div ref={documentWrapperRef} className="d-flex justify-content-center">
      <Document
        file={`data:${certificate.content_type};base64,${certificate.content}`}
        className={"mowaamah-certificate"}
        renderMode={"canvas"}
      >
        <Page
          height={500}
          width={700}
          pageNumber={1}
          className={"border border-primary"}
        />
      </Document>
    </div>
  );
};

export default PDFViewer;

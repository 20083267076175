import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function RegistrationSuccess() {
  const local = useSelector((state) => state.homeReducer.local);

  const navigate = useNavigate();

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col>
          <h2 className="text-success text-center mb-4">
            {local.registrationSent}
          </h2>
          <h4 className="text-center text-primary font-weight-bold mb-3">
            {local.verifyingInformation}
          </h4>
          <h5 className="text-center mb-5">{local.confirmationEmail}</h5>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6} className="text-center">
          {/* <Button variant="outline-secondary" className="w-75 mt-3 mb-2">
            {local.resendEmail}
          </Button> */}
          <Button
            onClick={() => navigate("/home")}
            variant="secondary"
            className="text-white w-75 mt-3 mb-2"
          >
            {local.backToHomePage}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default RegistrationSuccess;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import ArConsent from "./ar-consent";
import EnConsent from "./en-consent";
import useLegacyEffect from "../../hooks/useLegacyEffect";

function CookieConsent() {
  const local = useSelector((state) => state.homeReducer.local);
  const [consentGiven, setConsentGiven] = useState(false);
  var consent = localStorage.getItem("cookie_consent");
  useLegacyEffect(() => {
    setConsentGiven(!!consent);
  }, []);
  return (
    <div className={`consent-modal ${consentGiven ? "d-none" : "d-block"}`}>
      <div className="p-3">
        {local.language === "ar" ? (
          <ArConsent setConsentGiven={setConsentGiven} />
        ) : (
          <EnConsent setConsentGiven={setConsentGiven} />
        )}
      </div>
    </div>
  );
}

export default CookieConsent;

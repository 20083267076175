import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import Loader from "../../Common/Loader";
import { setPaymentParam } from "../../../utils/localStorage";
import failureIcon from "../../../assets/images/Payment/failureIcon.svg";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { Button, Container } from "react-bootstrap";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import { orderType } from "../../../lookups/status";

const PaymentFaild = () => {
  const local = useSelector((state) => state.homeReducer.local);

  const dispatch = useDispatch();
  const query = useQuery();
  let { uuid, companyId } = useParams();
  const navigate = useNavigate();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const loading = useSelector((state) => state.commonReducer.loading);
  setPaymentParam({ isSet: false });

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
    } else {
      navigate(`/${companyId}`);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          {query.get("type") === orderType.certificate && (
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          )}

          <img
            className="payment-successful-icon mt-5"
            src={failureIcon}
            alt="Success Icon"
            aria-hidden="true"
          />

          <h1 className="text-danger mt-3 mb-5">{local.paymentFaild}</h1>

          <p className="fs-2 text-gray">{local.paymentFaildlNote}</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">
          <Button
            variant="secondary"
            onClick={() =>
              navigate(
                `/${companyId}/payment/${uuid}/review?type=${query.get("type")}`
              )
            }
            className="text-white w-100 mt-5"
            type="submit"
          >
            {local.backToPaymentPage}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default PaymentFaild;

import * as types from "./types";
export const setCriterias = (payload) => ({
  type: types.SET_CRITERIAS,
  payload: payload,
});
export const setCriteriaIds = (payload) => ({
  type: types.SET_CRITERIA_IDS,
  payload: payload,
});

export const setCriteriaDetails = (payload) => ({
  type: types.SET_CRITERIA_DETAILS,
  payload: payload,
});

export const setMyAnswers = (payload) => ({
  type: types.SET_MY_ANSWERS,
  payload: payload,
});

export const removeFileFromMyAnswer = (payload) => ({
  type: types.REMOVE_FILE_FROM_MY_ANSWER,
  payload: payload,
});

export const setCurrentPageQuestionsIds = (payload) => ({
  type: types.SET_CURRENT_PAGE_QUESTIONS_IDS,
  payload: payload,
});

export const setAssessmentOrder = (payload) => ({
  type: types.SET_ASSESSMENT_ORDER,
  payload: payload,
});

export const setCurrentCertificateFlowStep = (payload) => ({
  type: types.SET_CURRENT_CERTIFICATE_FLOW_STEP,
  payload: payload,
});

export const setCurrenOrders = (payload) => ({
  type: types.SET_CURRENT_ORDERS,
  payload: payload,
});

export const setOldOrders = (payload) => ({
  type: types.SET_OLD_ORDERS,
  payload: payload,
});

export const setPreview = (payload) => ({
  type: types.SET_PREVIEW,
  payload: payload,
});

export const setCertificate = (payload) => ({
  type: types.SET_CERTIFICATE,
  payload: payload,
});

import * as types from "./types";
import Arabic from "../../localization/Arabic/index.json";
import English from "../../localization/English/index.json";

const initialState = {
  companyInfo: {},
  myCompaniesList: [],
  regionsList: [],
  citiesList: [],
  local: Arabic,
  currentLanguage: "en",
  faqsList: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.payload,
      };
    case types.SET_My_COMPANIES_LIST:
      return {
        ...state,
        myCompaniesList: action.payload,
      };
    case types.SET_REGIONS_LIST:
      return {
        ...state,
        regionsList: action.payload,
      };
    case types.SET_CITIES_LIST:
      return {
        ...state,
        citiesList: action.payload,
      };
    case types.SET_FAQS_LIST:
      return {
        ...state,
        faqsList: action.payload,
      };
    case types.SWITCH_CURREN_LANGUAGE:
      return {
        ...state,
        local: state.local.language === "ar" ? English : Arabic,
        currentLanguage: state.currentLanguage === "ar" ? "en" : "ar",
      };
    case types.SET_CURREN_LANGUAGE:
      return {
        ...state,
        local: action.payload === "en" ? English : Arabic,
        currentLanguage: action.payload === "ar" ? "en" : "ar",
      };
    default:
      return state;
  }
};

export default homeReducer;

import React from "react";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";
import ProgressSteps from "../../shared/ProgressSteps";
import { useNavigate, useParams } from "react-router-dom";
import { certifiedStatus } from "../../lookups/status";
import { Button, Container, Card, Modal } from "react-bootstrap";
import isEmpty from "../../utils/isEmpty";
import { certificateSteps, trainingSteps } from "../../lookups/steps";
import useLegacyEffect from "../../hooks/useLegacyEffect";

const Home = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  let { companyId } = useParams();
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const loading = useSelector((state) => state.commonReducer.loading);
  const user = useSelector((state) => state.authReducer.user);
  const [popupShow, setPopupShow] = React.useState(false);

  const certifiedStatusObj = certifiedStatus(local).find(
    (element) => element?.code === companyInfo?.certificate?.grade?.code
  );
  useLegacyEffect(() => {
    if (localStorage.getItem("openWorkshopModal") === "true") {
      setPopupShow(true);
      localStorage.setItem("openWorkshopModal", "false");
    }
  }, []);

  if (loading && !isEmpty(companyInfo)) {
    return <Loader />;
  }

  return (
    <Container className="mowaamah-home mb-5">
      <div className="company-info-container grid gap-0">
        <h2 className="company-name g-col-12 g-col-md-6 g-col-xl-8">
          {local.language === "ar" ? companyInfo.name : companyInfo.name_en} -
          {companyInfo.commercial_record_number}
        </h2>

        <div className="company-cert-status g-col-12 g-col-md-6 g-col-xl-4">
          {certifiedStatusObj?.image && (
            <img
              className="company-cert-status__icon"
              src={certifiedStatusObj?.image}
              alt="logo"
              aria-hidden="true"
            />
          )}
          <div>
            <Button
              variant={certifiedStatusObj?.class}
              size="lg"
              className={`rounded-pill certificate-badge ${
                certifiedStatusObj?.code !== undefined && "pe-5 w-100"
              }`}
            >
              {certifiedStatusObj?.text}
            </Button>
            {certifiedStatusObj?.image && (
              <div className="mt-2">
                <span className="px-2">{local.certificateValidUntil}</span>
                <span>{companyInfo?.certificate?.end_date}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Card className="mt-5 shadow p-4 p-md-5">
        <h2 className="fs-4">{local.stepsToGetCertificate}</h2>
        <ProgressSteps
          stepLabelText={"steps-home-text"}
          stepLabelTextActive={"steps-home-text"}
          type={"image"}
          stepsClass={"steps-home"}
          stepClass={"step-home"}
          stepCompletedClass={"step-home--completed"}
          steps={certificateSteps}
        />

        <div className="certificate-btn grid mt-5">
          <Button
            disabled={!companyInfo.able_to_order || !user.is_email_verified}
            variant="secondary"
            className="certificate-btn__get g-col-12 g-col-md-4 text-white g-0"
            onClick={() => navigate(`/${companyId}/certificate/instructions`)}
          >
            {local.getCertificate}
          </Button>

          <Button
            variant="outline-secondary"
            className="certificate-btn__view g-col-12 g-col-md-3 g-0"
            onClick={() => navigate(`/${companyId}/certificate`)}
          >
            {local.showOrders}
          </Button>
        </div>
      </Card>

      <Card className="mt-5 shadow p-4 p-md-5 mb-5">
        <h2 className="fs-4">{local.stepsToGetTraining}</h2>
        <ProgressSteps
          stepLabelText={"steps-home-text"}
          stepLabelTextActive={"steps-home-text"}
          type={"image"}
          stepsClass={"steps-home"}
          stepClass={"step-home"}
          stepCompletedClass={"step-home--completed"}
          steps={trainingSteps}
        />

        <div className="training-btn grid mt-5">
          <Button
            disabled={
              !companyInfo.able_to_request_training || !user.is_email_verified
            }
            variant="secondary"
            className="training-btn__get g-col-12 g-col-md-4 text-white"
            onClick={() => navigate(`/${companyId}/training/goals`)}
          >
            {local.getTraining}
          </Button>

          <Button
            variant="outline-secondary"
            className="training-btn__view g-col-12 g-col-md-3"
            onClick={() => navigate(`/${companyId}/training`)}
          >
            {local.showOrders}
          </Button>
        </div>
      </Card>
      <Modal
        show={popupShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="workshop-modal"
      >
        <div className="px-5 py-3">
          <button
            type="button"
            className={`btn-close position-absolute top-0 ${
              local.language === "ar" ? "start-0" : "end-0"
            }  m-3 p-3`}
            aria-label="Close"
            onClick={() => setPopupShow(false)}
          ></button>
          <Modal.Body className="fs-4 text-success fw-bold mt-5">
            <p>{local.takeWorkshopModalQuestion}</p>
          </Modal.Body>
          <Modal.Footer className="flex-column align-items-center border-0">
            <Button
              variant="secondary"
              className="text-white workshop-btn"
              onClick={async () => {
                setPopupShow(false);
                navigate(`/${companyId}/workshop`);
              }}
            >
              {local.yesInterested}
            </Button>
            <Button
              className="px-4 workshop-btn-outline"
              variant="link"
              onClick={() => setPopupShow(false)}
            >
              {local.remindMeLater}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </Container>
  );
};

export default Home;

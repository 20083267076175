import { SET_ERRORS,RESET_ERRORS,RESET_SPECIFC_ERRORS,SET_ERROR,SET_ERROR_STATUS} from "./types";

const initialState = {
  errors:{},
  errorStatus:0
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_STATUS:
      return {
        ...state,
        errorStatus:action.payload,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors:action.payload,
      };

      case SET_ERROR:
      return {
        ...state,
        errors:{...state.errors, ...action.payload},
      };

    case RESET_ERRORS:
      return {
        ...state,
        errors:{},
        errorStatus:0
      };

    case RESET_SPECIFC_ERRORS:
      const errorFound = action.payload in state.errors;
      let errorObj = Object.keys(state.errors)

      if(errorFound){
        errorObj = errorObj.filter(key =>
          key !== action.payload).reduce((obj, key) =>
          {
              obj[key] = state.errors[key];
              return obj;
          }, {});
      }


    return {
      ...state,
      errors:errorObj
    };

    default:
      return state;
  }
}

export default errorReducer
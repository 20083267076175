import React from "react";
import { Card, Col, Row, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function AboutMowaamah() {
  const navigate = useNavigate();
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="about">
      <Container>
        <Row className="justify-content-between px-4 px-xl-0">
          <Col xs={12} lg={6} xl={7}>
            <h2 className="display-4 font-weight-bold text-tertiary mb-5 mt-0 mt-xl-5">
              {local.aboutMowaamahProgram}
            </h2>
            <p className="text-white fs-4 mb-5 mb-lg-0">
              {local.aboutMowaamahContent}
            </p>
          </Col>
          <Col xs={12} lg={5} xl={3}>
            <Card className="mb-5">
              <h3 className="text-tertiary fs-3">
                {local.certificateClassifications}
              </h3>
              <p className="text-white mb-4">{local.classificationNote}</p>
              <Button
                variant="secondary"
                className="text-white"
                onClick={() => {
                  navigate("/about", {
                    state: { targetId: "about-classification" },
                  });
                }}
              >
                {local.showClassifications}
              </Button>
            </Card>
            <Card className="">
              <h3 className="text-tertiary fs-3">{local.mowaamahCriterias}</h3>
              <p className="text-white mb-4">{local.criteriasNote}</p>
              <Button
                variant="secondary"
                className="text-white"
                onClick={() => {
                  navigate("/about", {
                    state: { targetId: "about-standards" },
                  });
                }}
              >
                {local.showCriterias}
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutMowaamah;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import notFound from "../../../assets/images/404.svg";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../../store/Error/epics";

const NoMatch = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const redirectPage = () => {
    dispatch(resetErrors());
    if (companyId) {
      navigate(`/${companyId}`);
    } else {
      navigate(`/`);
    }
  };

  const navigate = useNavigate();

  return (
    <Container fluid
      className="d-flex flex-column align-items-center justify-content-center"
    >
      <Row>
          <Col xs={12} className="text-center mb-5 w-100">
            <Button
              variant="secondary"
              className="text-white mt-5"
              onClick={redirectPage}
            >
              {local.backToHomePage}
            </Button>
          </Col>
      </Row>
      <Row className="w-75 mx-auto">
        <Col xs={12}>
        <img src={notFound} className="w-100" />
        </Col>
      </Row>
      
      
    </Container>
  );
};

export default NoMatch;

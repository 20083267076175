import React from "react";
import { useSelector } from "react-redux";

const ChevronBar = ({
  steps,
  currentStep,
  onClickStep,
  stepsClass,
  stepClass,
  stepText,
  stepTextCurrent,
  stepTextUncompleted,
  stepTextCompleted,
}) => {
  const local = useSelector((state) => state.homeReducer.local);

  const handleClickStep = (step) => {
    if (onClickStep) {
      onClickStep(step);
    }
  };

  return (
    <React.Fragment>
      <div className="d-none d-md-block mb-4">
        <div className={stepsClass}>
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`${stepText} ${
                index === currentStep
                  ? stepTextCurrent
                  : index > currentStep
                  ? stepTextUncompleted
                  : stepTextCompleted
              }`}
              onClick={() => handleClickStep(step)}
            >
              <span>{local.language === "ar" ? step.text : step.text_en}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="d-block d-md-none mb-4">
        <div className={stepsClass}>
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`${stepText} ${
                index === currentStep
                  ? stepTextCurrent
                  : index > currentStep
                  ? stepTextUncompleted
                  : stepTextCompleted
              }`}
              onClick={() => handleClickStep(step)}
            ></div>
          ))}
        </div>
        <div className="d-flex justify-content-between mt-1">
          <span className="text-secondary fs-4">
            {steps[currentStep]?.text}
          </span>
          <span className="fs-4">{steps[+currentStep + 1]?.text}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

ChevronBar.defaultProps = {
  stepLabelTextActive: "step-label-text--active",
  stepLabelText: "step-label-text",
  stepsClass: "steps",
  type: "shape",
  steps: "",
  currentStep: 0,
  onClickStep: "",
  stepClass: "step",
  stepCompletedClass: "step--completed",

  stepText: "step-text",
  stepTextCurrent: "step-text--current",
  stepTextUncompleted: "step-text--uncompleted",
  stepTextCompleted: "step-text--completed",
};

export default ChevronBar;

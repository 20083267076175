import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Common/Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { resetErrors } from "../../store/Error/epics";
import assessmentValidation from "../../utils/assessmentValidation";
import ProgressSteps from "../../shared/ProgressSteps";
import {
  fetchCriteriaDetails,
  callSubmitAssessment,
  callSubmitAssessmentEvidence,
  callSubmitAssessmentAfterEvidence,
  fetchMyAnswers,
  fetchCriteriasList,
  fetchAssessmentOrder,
} from "../../store/Certificate/actions";
import isEmpty from "../../utils/isEmpty";
import SectionsList from "./SectionsList";
import useLegacyEffect from "../../hooks/useLegacyEffect";
import ChevronBar from "../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../store/Certificate/epics";
import { Button, Container, Modal } from "react-bootstrap";
import { companyTypes } from "../../lookups/status";
import { toast } from "react-toastify";

const Certificate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { uuid, companyId } = useParams();
  const [dataLoaded, setDataLoaded] = useState(false);

  const local = useSelector((state) => state.homeReducer.local);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loading);
  const criteriaIds = useSelector(
    (state) => state.certificateReducer.criteriaIds
  );
  const criteria = useSelector((state) => state.certificateReducer.criteria);
  const myAnswers = useSelector((state) => state.certificateReducer.myAnswers);
  const currentPageQuestionsIds = useSelector(
    (state) => state.certificateReducer.currentPageQuestionsIds
  );
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const [currentCriteria, setCurrentCriteria] = useState(0);
  const [isLastCriteria, setIsLastCriteria] = useState(false);
  const assessmentOrder = useSelector(
    (state) => state.certificateReducer.assessmentOrder
  );
  const companyTypeObj = companyTypes(local).find(
    (element) => element?.id === assessmentOrder?.assessment?.name_ar
  );
  const [popupShow, setPopupShow] = React.useState(false);

  useLegacyEffect(() => {
    const fetchData = async () => {
      if (location.pathname === `/${companyId}/certificate/${uuid}/evidence`) {
        await dispatch(
          fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_EVIDENCE")
        );
        await dispatch(fetchCriteriasList(navigate, uuid, companyId));
        await dispatch(setCurrentCertificateFlowStep(2));
      } else {
        await dispatch(
          fetchAssessmentOrder(navigate, uuid, companyId, "INITIAL")
        );
        await dispatch(fetchCriteriasList(navigate, uuid, companyId));
      }
      setDataLoaded(true);
    };

    fetchData();
    dispatch(resetErrors());
  }, []);
  useLegacyEffect(() => {
    setIsLastCriteria(currentCriteria + 1 === criteriaIds.length);
  }, [currentCriteria, criteriaIds]);
  const handleClickStep = (step) => {
    // setCurrentCriteria(criteriaIds.findIndex((s) => s.id === step.id));
  };

  const handleNextStep = async () => {
    setCurrentCriteria(currentCriteria + 1);

    let criteriaId = criteriaIds[currentCriteria + 1].id;
    await dispatch(fetchMyAnswers(uuid, criteriaIds[currentCriteria + 1]?.id));
    dispatch(fetchCriteriaDetails(criteriaId, uuid));
  };

  const handlePreviousStep = async () => {
    setCurrentCriteria(currentCriteria - 1);
    await dispatch(fetchMyAnswers(uuid, criteriaIds[currentCriteria - 1]?.id));

    let criteriaId = criteriaIds[currentCriteria - 1].id;
    dispatch(fetchCriteriaDetails(criteriaId, uuid));
  };

  const handleAssessmentSubmission = async (isSaveProgress) => {
    if (
      assessmentValidation(
        myAnswers,
        dispatch,
        location.pathname,
        currentPageQuestionsIds,
        uuid,
        companyId,
        local
      )
    ) {
      if (location.pathname === `/${companyId}/certificate/${uuid}/evidence`) {
        for (const answer of myAnswers) {
          const formData = new FormData();
          formData.append("question_id", answer.question_id);
          formData.append("assessment_order_id", uuid);
          formData.append("value", answer.value);
          if (answer.value === "YES") {
            if (answer.evidences?.length < 1) continue;
            for (const evidence of answer.evidences) {
              if (evidence.path) {
                try {
                  const response = await fetch(evidence.path);
                  const fileBlob = await response.blob();
                  const file = new File([fileBlob], evidence.file_name, {
                    type: fileBlob.type,
                  }); // Specify the content type
                  formData.append("evidences", file);
                } catch (error) {
                  console.error(
                    `Failed to fetch or process file ${evidence.file_name}`,
                    error
                  );
                }
              }
            }
          } else if (answer.value === "NO") {
            formData.append("evidences", null);
            formData.append("justification", "");
          } else if (answer.value === "NOT_APPLICABLE") {
            if (answer.justification === "") continue;
            formData.append("evidences", null);
            formData.append("justification", answer.justification);
          } else {
            continue;
          }
          await dispatch(callSubmitAssessmentEvidence(formData));
        }
        if (!isSaveProgress) {
          if (!isLastCriteria) {
            handleNextStep();
          } else {
            setPopupShow(true);
          }
        } else {
          navigate(`/${companyId}/certificate`);
        }
      } else {
        if (!isLastCriteria) {
          const data = {
            assessment_order_id: uuid,
            answers: myAnswers.filter((item) => item.value !== ""),
            is_submit: isLastCriteria,
          };
          await dispatch(
            callSubmitAssessment(
              navigate,
              data,
              uuid,
              companyId,
              isLastCriteria
            )
          );
          handleNextStep();
        } else {
          setPopupShow(true);
        }
      }
    } else {
      toast.error(local.correctFormErrors);
      window.scrollTo(0, 0);
    }
  };

  const handleSaveProgress = async () => {
    if (location.pathname !== `/${companyId}/certificate/${uuid}/evidence`) {
      const data = {
        assessment_order_id: uuid,
        answers: myAnswers.filter((item) => item.value !== ""),
        is_submit: false,
      };
      await dispatch(
        callSubmitAssessment(navigate, data, uuid, companyId, false)
      );
      navigate(`/${companyId}/certificate`);
    } else {
      handleAssessmentSubmission(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(resetErrors());
    handleAssessmentSubmission(false);
  };

  const allSections =
    !isEmpty(criteria) &&
    criteria.sections.map((section, sectionIndex) => {
      return (
        <SectionsList
          key={sectionIndex}
          section={section}
          sectionIndex={sectionIndex}
        />
      );
    });

  if (loading || !dataLoaded) {
    return <Loader />;
  }

  return (
    <Container className="certificate">
      <div>
        <ChevronBar
          type={"shape"}
          stepsClass={"steps-flow-container"}
          stepClass={"step-flow"}
          stepText={"step-flow-text"}
          stepTextCurrent={"step-flow-text--current"}
          stepTextUncompleted={"step-flow-text--uncompleted"}
          stepTextCompleted={"step-flow-text--completed"}
          stepCompletedClass={"step-home--completed"}
          steps={flowSteps}
          currentStep={currentCertificateFlowStep}
        />

        <h2 className="mb-4">{local.assessment + companyTypeObj?.text}</h2>
        <p className="mb-4 card border border-tertiary p-3">
          {local.assesmentNote}
        </p>

        <ProgressSteps
          steps={criteriaIds}
          currentStep={currentCriteria}
          onClickStep={handleClickStep}
        />

        <form onSubmit={handleSubmit} noValidate>
          <h4 className="text-success">
            <span className="d-inline d-md-none">{+currentCriteria + 1}.</span>
            {criteriaIds[currentCriteria][`name_${local.language}`]}
          </h4>
          {allSections}
          <div className="form-btn my-4 d-flex justify-content-around">
            {currentCriteria > 0 && (
              <Button variant="outline-secondary" onClick={handlePreviousStep}>
                {local.back}
              </Button>
            )}

            <Button
              variant="link"
              className="text-success d-none d-md-inline"
              onClick={handleSaveProgress}
            >
              {local.save}
            </Button>
            <Button variant="secondary" className="text-white" type="submit">
              {isLastCriteria ? local.send : local.next}
            </Button>
          </div>
        </form>
        <Modal
          show={popupShow}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Title className="p-3 fw-normal">
            <p>{local.confirmationTitle}</p>
          </Modal.Title>
          {location.pathname ===
            `/${companyId}/certificate/${uuid}/evidence` && (
            <Modal.Body className="fs-5">
              <p>{local.evidencesConfirmation}</p>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              onClick={async () => {
                if (
                  location.pathname ===
                  `/${companyId}/certificate/${uuid}/evidence`
                ) {
                  await dispatch(
                    callSubmitAssessmentAfterEvidence(navigate, uuid, companyId)
                  );
                } else {
                  const data = {
                    assessment_order_id: uuid,
                    answers: myAnswers.filter((item) => item.value !== ""),
                    is_submit: isLastCriteria,
                  };
                  await dispatch(
                    callSubmitAssessment(
                      navigate,
                      data,
                      uuid,
                      companyId,
                      isLastCriteria
                    )
                  );
                }
                setPopupShow(false);
              }}
            >
              {local.yes}
            </Button>
            <Button
              className="px-4 btn-outline-primary"
              variant="outline"
              onClick={() => setPopupShow(false)}
            >
              {local.no}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

export default Certificate;

import assessmentIcon from "../../assets/images/CertificateFlow/assessmentIcon.svg";
import paymentIcon from "../../assets/images/CertificateFlow/paymentIcon.svg";
import evidenceIcon from "../../assets/images/CertificateFlow/evidenceIcon.svg";
import auditingIcon from "../../assets/images/CertificateFlow/auditingIcon.svg";
import approvalIcon from "../../assets/images/CertificateFlow/approvalIcon.svg";
import certificateIcon from "../../assets/images/CertificateFlow/certificateIcon.svg";
import Choose from "../../assets/images/TrainerRequestFlow/Choose.svg";
import Date from "../../assets/images/TrainerRequestFlow/Date.svg";
import GetTraining from "../../assets/images/TrainerRequestFlow/GetTraining.svg";

export const certificateSteps = [
  {
    id: 1,
    class: "certificate-flow-1",
    image: assessmentIcon,
    text: "١",
    name_ar: "التقييم الذاتي",
    name_en: "Self Assessment",
  },
  {
    id: 2,
    class: "certificate-flow-2",
    image: paymentIcon,
    text: "٢",
    name_ar: "دفع الرسوم",
    name_en: "Pay fees",
  },
  {
    id: 3,
    class: "certificate-flow-3",
    image: evidenceIcon,
    text: "٣",
    name_ar: "إرفاق الأدلة والتوضيحات",
    name_en: "Attach evidences and justifications",
  },
  {
    id: 4,
    class: "certificate-flow-4",
    image: auditingIcon,
    text: "٤",
    name_ar: "التدقيق",
    name_en: "Auditing",
  },
  {
    id: 5,
    class: "certificate-flow-5",
    image: approvalIcon,
    text: "٥",
    name_ar: "القبول",
    name_en: "Acceptance",
  },
  {
    id: 6,
    class: "certificate-flow-6",
    image: certificateIcon,
    text: "٦",
    name_ar: "الحصول على الشهادة",
    name_en: "Get certificate",
  },
];

export const trainingSteps = [
  {
    id: 1,
    class: "trainer-request-flow-1",
    image: Choose,
    text: "١",
    name_ar: "اختيار نوع التدريب",
    name_en: "Choose training type",
  },
  {
    id: 2,
    class: "trainer-request-flow-2",
    image: Date,
    text: "٢",
    name_ar: "اختيار التاريخ المناسب",
    name_en: "Choose appropriate date",
  },
  {
    id: 3,
    class: "trainer-request-flow-3",
    image: paymentIcon,
    text: "٣",
    name_ar: "دفع الرسوم",
    name_en: "Pay fees",
  },
  {
    id: 4,
    class: "trainer-request-flow-4",
    image: GetTraining,
    text: "٤",
    name_ar: "احصل على التدريب",
    name_en: "Get training",
  },
];

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import ChevronBar from "../../../../shared/ChevronBar";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { fetchAssessmentOrder } from "../../../../store/Certificate/actions";
import { setCurrentCertificateFlowStep } from "../../../../store/Certificate/epics";
import { resetErrors } from "../../../../store/Error/epics";
import useQuery from "../../../../hooks/useQuery";
import failureIcon from "../../../../assets/images/Payment/failureIcon.svg";
import BackButton from "../../../../shared/BackButton";
import { orderType, rejectionReasons } from "../../../../lookups/status";
import { fetchTrainingOrder } from "../../../../store/Training/actions";

const Status = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const { companyId, uuid } = useParams();
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const dispatch = useDispatch();
  const query = useQuery();
  var order = useSelector((state) =>
    query.get("type") === orderType.certificate
      ? state.certificateReducer.assessmentOrder
      : query.get("type") === orderType.training
      ? state.trainingReducer.trainingOrder
      : ""
  );

  const rejectionReasonObj = rejectionReasons(local).find(
    (element) => element?.id === order?.bank_transfer?.rejection_reason
  );

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, query.get("status"))
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, query.get("status"))
      );
    } else {
      navigate(`/${companyId}`);
    }
    dispatch(resetErrors());
  }, []);

  const handleClick = () => {
    if (order?.bank_transfer?.rejection_reason === "INSUFFICIENT_AMOUNT") {
      navigate(
        `/${companyId}/payment/${uuid}/method/transfer?status=PENDING_BANK_TRANSFER_PAYMENT&type=${query.get(
          "type"
        )}`
      );
    } else {
      navigate(
        `/${companyId}/payment/${uuid}/review?type=${query.get("type")}`
      );
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      {query.get("type") === orderType.certificate && (
        <div className="row justify-content-center">
          <div className="col-12">
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          </div>
        </div>
      )}

      <BackButton onClick={() => navigate(-1)} label={local.backV2} />

      {!order?.bank_transfer?.rejection_reason ? (
        <div>
          <div className="row justify-content-center mt-5">
            <div className="col-12 py-5 text-center">
              <h2 className="mb-4 text-success">
                {local.PendingTransferNote1}
              </h2>
              <p className="mb-4">
                {query.get("type") === orderType.certificate &&
                  local.PendingTransferCertificateNote2}
                {query.get("type") === orderType.training &&
                  local.PendingTransferTrainingNote2}
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 text-center">
              <Button
                variant="secondary"
                className="text-white w-100"
                type="button"
                onClick={() => navigate(`/${companyId}`)}
              >
                {local.backToHomePage}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row justify-content-center">
            <div className="col-12 py-5 text-center">
              <img
                className="status-icon"
                src={failureIcon}
                alt="Failure Icon"
                aria-hidden="true"
              />

              <h1 className="text-danger mt-3 mb-5">
                {local.paymentTransferFaild}
              </h1>
              <p className="mb-1 fs-4">{local.RejectionReason}</p>
              <p className="fs-4 mb-4">
                -{" "}
                {rejectionReasonObj?.id === "OTHER"
                  ? order?.bank_transfer?.note
                  : rejectionReasonObj?.text + order?.bank_transfer?.note}
              </p>
              <p className="text-success fs-5">{local.PleaseResubmit}</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-4 text-center">
              <Button
                variant="secondary"
                className="text-white w-100"
                type="button"
                onClick={handleClick}
              >
                {local.backToPaymentPage}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Status;

import formatBytes from "./formatBytes";

const addQuestionAndFilesAndAnswers = (
  event,
  setState,
  state,
  question_id,
  dispatch
) => {
  // Convert files to an array of file objects if it's not already

  if (!question_id) {
    console.warn("addQuestionAndFilesAndAnswers: question_id is undefined");
    return;
  }

  const fileList = event.target.files
    ? Array.isArray(event.target.files)
      ? event.target.files
      : Array.from(event.target.files).map((file) => ({
          file_name: file.name,
          path: URL.createObjectURL(file),
          file_size: formatBytes(file.size),
          size: file.size,
        }))
    : [];

  // Check if the question_id already exists in the state
  let index;
  if (question_id !== null && question_id !== undefined) {
    index = state.findIndex((item) => item.question_id === question_id);
  }

  if (index !== -1) {
    // Merge the new files with the existing files, if they exist
    const newState = [...state];
    const existingFiles = newState[index].evidences || [];
    const newFiles = fileList.filter(
      (file) =>
        !existingFiles.some(
          (existingFile) => existingFile.file_name === file.file_name
        )
    );

    newState[index] = {
      ...newState[index],
      question_id,
      [event.target.name.startsWith("value") ? "value" : event.target.name]:
        event.target.files
          ? existingFiles.concat(newFiles)
          : event.target.value,
    };
    dispatch(setState(newState));
  } else {
    // If the question_id doesn't exist, add a new item to the state with the files
    const newState = [
      ...state,
      {
        question_id,
        [event.target.name.startsWith("value") ? "value" : event.target.name]:
          event.target.files ? fileList : event.target.value,
      },
    ];
    dispatch(setState(newState));
  }
};

export default addQuestionAndFilesAndAnswers;

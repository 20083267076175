import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import Loader from "../../../Common/Loader";
import { setPaymentParam } from "../../../../utils/localStorage";
import failureIcon from "../../../../assets/images/Payment/failureIcon.svg";
import ChevronBar from "../../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../../store/Certificate/epics";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import { Button, Container } from "react-bootstrap";
import { orderType } from "../../../../lookups/status";

const Rejected = () => {
  const local = useSelector((state) => state.homeReducer.local);

  const dispatch = useDispatch();
  let { uuid, companyId } = useParams();
  const navigate = useNavigate();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  // const loading = useSelector((state) => state.commonReducer.loading);
  setPaymentParam({ isSet: false });

  useLegacyEffect(() => {
    dispatch(setCurrentCertificateFlowStep(4));
  }, []);

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <ChevronBar
            type={"shape"}
            stepsClass={"steps-flow-container"}
            stepClass={"step-flow"}
            stepText={"step-flow-text"}
            stepTextCurrent={"step-flow-text--current"}
            stepTextUncompleted={"step-flow-text--uncompleted"}
            stepTextCompleted={"step-flow-text--completed"}
            stepCompletedClass={"step-home--completed"}
            steps={flowSteps}
            currentStep={currentCertificateFlowStep}
          />

          <img
            className="status-icon mt-5"
            src={failureIcon}
            alt="Failure Icon"
            aria-hidden="true"
          />

          <h1 className="text-danger mt-3 fs-2">{local.orderRejected}</h1>
          <p className="mb-5 fs-5">{local.checkMailForReasons}</p>

          <p className="text-success mt-5 fs-4">{local.reorderNote}</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 text-center">
          <Button
            variant="secondary"
            onClick={() =>
              navigate(
                `/${companyId}/certificate/${uuid}/evidence?type=${orderType.certificate}`
              )
            }
            className="text-white w-100 mt-3"
            type="submit"
          >
            {local.editAssessment}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Rejected;

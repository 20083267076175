import React from "react";
import { Button, Container, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createWorkshopRequest } from "../../store/Workshop/actions";

function WorkshopPage() {
  const local = useSelector((state) => state.homeReducer.local);
  let { companyId } = useParams();
  const navigate = useNavigate();
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const dispatch = useDispatch();

  const handleWorkshopRequest = () => {
    dispatch(createWorkshopRequest(navigate, companyId));
  };

  return (
    <Container className="p-5 ">
      <h2 className="text-primary mb-5">{local.workshop}</h2>
      <Card className="shadow p-4 p-md-5  mx-auto col-xl-8">
        <p className="text-success fs-4">{local.mowaamahWorkshop}</p>
        <p className="fs-5">{local.workshopDescription}</p>
      </Card>
      {!companyInfo.able_to_request_workshop ? (
        <Card className="p-3 mt-5 mx-auto col-xl-8 border outline-card">
          <p className="fs-6 m-0">{local.registeredAlready}</p>
        </Card>
      ) : (
        <div className="row justify-content-center mt-5">
          <div className="col-12 col-md-4 text-center">
            <Button
              variant="secondary"
              className="text-white w-100 mt-3"
              onClick={handleWorkshopRequest}
            >
              {local.registerNow}
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
}

export default WorkshopPage;

import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import locationPinSolid from "../../assets/images/locationPinSolid.svg";
import L from "leaflet";

const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    if (lat) map.flyTo([lat, lng], map.getZoom());
  }, [lat, lng]);
  return null;
};

function DraggableMarker({ draggable, position, setPosition, name }) {
  const skaterIcon = L.icon({
    iconUrl: locationPinSolid,
    iconSize: [35, 35],
  });
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );
  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={skaterIcon}
    >
      {/* <Popup minWidth={90}>{name}</Popup> */}
    </Marker>
  );
}

const LeafletMap = ({
  label,
  zoom,
  scrollWheelZoom,
  draggable,
  position,
  setPosition,
  currentLocationBtn,
  name,
  error,
}) => {

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      L.DomEvent.disableClickPropagation(ref.current);
    }
  });

  const ClickableMarker = () => {
      useMapEvents({
        click(e) {
          setPosition(e.latlng);
        },
      });
      return null;
    };
  

  const getCurrentLocation = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setPosition({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, [setPosition]);

  const LocationButton = () => {
    const map = useMap();

    return (
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-control leaflet-bar">
          <button
            ref={ref}
            className="leaflet-control-zoom-in"
            title="Get my location"
            role="button"
            onClick={(e) => {
              e.preventDefault();
              getCurrentLocation();
              map.flyTo(position, map.getZoom());
            }}
          >
            ⛯
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <MapContainer
        center={position}
        zoom={zoom}
        style={{ height: "400px", border: error ? "2px solid red" : "none" }}
        scrollWheelZoom={scrollWheelZoom}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <DraggableMarker
          draggable={draggable}
          position={position}
          setPosition={setPosition}
          name={name}
        />
        <ClickableMarker />
        <RecenterAutomatically lat={position.lat} lng={position.lng} />
        {currentLocationBtn && <LocationButton />}
      </MapContainer>
      <div id="validationServerUsernameFeedback" className="text-danger mb-2">
        {error}
      </div>
    </>
  );
};

export default LeafletMap;

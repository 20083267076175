import { useDispatch, useSelector } from "react-redux";
import { fetchRegionsList, fetchCitiesList } from "../../../store/Home/actions";
import isEmpty from "../../../utils/isEmpty";
import Select from "react-select";
import { errorMessages } from "../../../lookups/status";

const DropDownInput = ({
  showDefaultOption,
  htmlFor,
  labelClass,
  label,
  inputName,
  required,
  inputValue,
  isInvalid,
  inputClass,
  inputId,
  onChange,
  error,
  options,
  defaultOption,
  isregion,
  iscity,
}) => {
  const dispatch = useDispatch();
  const regionsList = useSelector((state) => state.homeReducer.regionsList);
  const citiesList = useSelector((state) => state.homeReducer.citiesList);
  const local = useSelector((state) => state.homeReducer.local);
  let errorMessageObj = "";
  const handleClick = () => {
    if (isregion && isEmpty(regionsList)) {
      dispatch(fetchRegionsList());
    }
    if (iscity && isEmpty(citiesList)) {
      dispatch(fetchCitiesList(defaultOption.region_id));
    }
  };

  if (error) {
    errorMessageObj = errorMessages(local).find(
      (element) => element?.id === error
    );
  }

  return (
    <div>
      <label htmlFor={htmlFor} className={"form-label " + labelClass}>
        {label}
        {required && <i className="text-danger mx-1">*</i>}
      </label>
      <Select
        classNamePrefix="filter"
        options={options}
        onMenuOpen={handleClick}
        placeholder={(showDefaultOption && defaultOption.name) || local.choose}
        name={inputName}
        required={required}
        onChange={onChange}
        value={inputValue}
        className={isInvalid ? "search-select" + " isInvalid" : "search-select"}
        id={inputId}
        noOptionsMessage={() =>
          inputId === "select_company"
            ? local.noCompaniesFound
            : local.noOptions
        }
        styles={{
          menuList: (provided) => ({
            ...provided,
            maxHeight: "230px", // list maximum height
          }),
        }}
      />
      <div id="validationServerUsernameFeedback" className="text-danger mt-2">
        {errorMessageObj?.value || error}
      </div>
    </div>
  );
};

DropDownInput.defaultProps = {
  showDefaultOption: true,
  defaultOption: "",
  options: [],
  htmlFor: "",
  labelClass: "",
  required: false,
  inputClass: "form-select mx-0 ",
  isInvalid: false,
  inputId: "",
  error: "",
};

export default DropDownInput;

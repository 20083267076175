import * as types from "./types";

export const setUserInfo = (payload) => ({
  type: types.SET_USER_INFO,
  payload: payload,
});

export const setIsUserAuthenticated = (payload) => ({
  type: types.SET_IS_USER_AUTHENTICATED,
  payload: payload,
});
export const setOtpInfo = (payload) => ({
  type: types.SET_OTP_INFO,
  payload: payload,
});

import {SET_LOADING,SET_TOKEN_LOADING} from "./types";

const initialState = {
    loading: false,
    tokenLoading: false,
};

const commonReducer = (state = initialState, action) => {

    switch (action.type) {

    case SET_LOADING:
        return {
            ...state,
            loading:action.payload,
        };
    case SET_TOKEN_LOADING:
        return {
            ...state,
            tokenLoading:action.payload,
        };

    default:
        return state;
    }
}

export default commonReducer
    
import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Hero() {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  return (
    <section className="hero vh-100">
      <Container className="z-3 d-flex align-items-center h-100 px-4 px-xl-0">
        <Row className="align-items-center pb-5">
          <Col className="pb-5">
            <Row>
              <Col xs={12} xl={6} className="mb-4">
                <h1 className="text-white display-2 font-bold z-3">
                  {local.enablingPeopleWithDisabilities}
                </h1>
                <p className="fs-4 text-white z-3">
                  {local.registerYourEntity}
                </p>
              </Col>
            </Row>
            {!isAuthenticated && (
              <Row className="pb-5">
                <Col xs={6} md={3} className="pb-5">
                  <Button
                    variant="secondary"
                    className="text-white w-100"
                    size="lg "
                    onClick={() => {
                      navigate("/auth");
                    }}
                  >
                    {local.registerNow}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Hero;

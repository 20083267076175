import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { Button, Container } from "react-bootstrap";
import Loader from "../../Common/Loader";
import successIcon from "../../../assets/images/Payment/successIcon.svg";
import failureIcon from "../../../assets/images/Payment/failureIcon.svg";
import { PublicInstance } from "../../../utils/axiosConfig";
import { fetchUserInfo } from "../../../store/Auth/actions";

function VerifyEmail() {
  const local = useSelector((state) => state.homeReducer.local);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const query = useQuery();
  const navigate = useNavigate();
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useLegacyEffect(() => {
    callVerifyEmail();
  }, []);
  const callVerifyEmail = async () => {
    setLoading(true);
    try {
      let response = await PublicInstance.post(`auth/verify-email/`, {
        token: query.get("token"),
      });
      if (response) {
        setResult(true);
        if (isAuthenticated) {
          dispatch(fetchUserInfo());
        }
      }
    } catch (error) {
      setResult(false);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Container>
      {result ? (
        <div className="mt-5 mb-3 d-flex justify-content-center">
          <img
            className="order-successful-icon mx-2"
            src={successIcon}
            alt="Successful Icon"
            aria-hidden="true"
          />

          <h1 className="text-success fs-4 mt-3 mx-2">
            {local.emailSuccessfullyVeryfied}
          </h1>
        </div>
      ) : (
        <div className="mt-5 mb-3 d-flex justify-content-center">
          <img
            className="order-successful-icon mx-2"
            src={failureIcon}
            alt="Failure Icon"
            aria-hidden="true"
          />

          <h1 className="text-danger fs-4 mt-3 mx-2">
            {local.emailVeryfiFailed}
          </h1>
        </div>
      )}
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-4 text-center">
          <Button
            variant="secondary"
            onClick={() => navigate("/auth")}
            className="text-white w-100 mt-3"
          >
            {local.signIn}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default VerifyEmail;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import onChangeHandler from "../../../utils/onChangeHandler";
import { resetError, resetErrors } from "../../../store/Error/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import FileUploadInput from "../../../shared/FormElements/FileUploadInput";
import FilesList from "../../../shared/FilesList";
import deleteFile from "../../../utils/deleteFile";
import { Button, Container } from "react-bootstrap";
import {
  callCreateBankTransaction,
  callCreateTrainingBankTransaction,
} from "../../../store/Payment/actions";
import ChevronBar from "../../../shared/ChevronBar";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import useQuery from "../../../hooks/useQuery";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import BackButton from "../../../shared/BackButton";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import { orderType } from "../../../lookups/status";

const TransferDetails = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  const { companyId, uuid } = useParams();
  const query = useQuery();
  const loading = useSelector((state) => state.commonReducer.loading);
  const errors = useSelector((state) => state.errorReducer.errors);
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const [state, setState] = useState({
    payment_receipts: [],
  });

  var order = useSelector((state) =>
    query.get("type") === orderType.certificate
      ? state.certificateReducer.assessmentOrder
      : query.get("type") === orderType.training
      ? state.trainingReducer.trainingOrder
      : ""
  );

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, query.get("status"))
      );
      dispatch(setCurrentCertificateFlowStep(1));
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, query.get("status"))
      );
    } else {
      navigate(`/${companyId}`);
    }

    dispatch(resetErrors());
  }, []);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    onChangeHandler(e, setState, state, errors, dispatch, resetError);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("payment_method", "BANK_TRANSFER");
    for (const receipt of state.payment_receipts) {
      try {
        const response = await fetch(receipt.path);
        const fileBlob = await response.blob();
        const file = new File([fileBlob], receipt.file_name, {
          type: fileBlob.type,
        }); // Specify the content type
        formData.append("payment_receipts", file);
      } catch (error) {
        console.error(
          `Failed to fetch or process file ${receipt.file_name}`,
          error
        );
      }
    }
    if (query.get("type") === orderType.certificate) {
      formData.append("assessment_order_id", uuid);
      dispatch(callCreateBankTransaction(navigate, formData, companyId, uuid));
    } else if (query.get("type") === orderType.training) {
      formData.append("training_requisition_id", uuid);
      dispatch(
        callCreateTrainingBankTransaction(navigate, formData, companyId, uuid)
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12">
          {query.get("type") === orderType.certificate && (
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          )}

          <BackButton onClick={() => navigate(-1)} label={local.backV2} />

          <div className="row justify-content-center mt-5">
            <div className="col-12 col-lg-8">
              <div className="card shadow p-4 p-lg-5">
                <p className="text-center">{local.uploadBankRecipt}</p>

                <div className="mb-3 text-success fs-4 text-center">
                  <span className="mx-1">{local.totalCost}</span>
                  <span className="mx-1">
                    {order?.pricing_summary?.grand_total} SAR
                  </span>
                </div>

                <div className="line"></div>

                <div className="grid w-100 my-4">
                  <div className="g-col-6 g-col-lg-4">
                    <p>{local.Bank}</p>
                    <p>{local.beneficiaryName}</p>
                    <p>{local.accountNumber}</p>
                    <p>{local.IBAN}</p>
                  </div>
                  <div className="g-col-6 g-col-lg-8 text-success">
                    <p>{local.takamolBankName}</p>
                    <p>{"شركة تكامل القابضة لخدمات الاعمال"}</p>
                    <p>{"896-010311-001"}</p>
                    <p>{"SA1945000000896010311001"}</p>
                  </div>
                </div>
                {state?.payment_receipts?.length != 0 && (
                  <FilesList
                    state={state}
                    setState={setState}
                    files={state.payment_receipts}
                    deleteFn={deleteFile}
                    filesName={"payment_receipts"}
                  />
                )}
                <div className="mt-3 mb-2">
                  <FileUploadInput
                    multiple={false}
                    inputId={"recipt_file_input"}
                    disabledInputId={"recipt_file_disabled_input"}
                    label={
                      state?.payment_receipts?.length
                        ? local.uploadAnotherFile
                        : local.uploadRecipt
                    }
                    inputName={"payment_receipts"}
                    htmlFor={"payment_receipts"}
                    inputValue={state.payment_receipts}
                    isInvalid={errors.file}
                    error={errors.file}
                    onChange={handleOnChange}
                    inputClass={"disabled-input d-none"}
                    style={
                      state?.payment_receipts?.length
                        ? "btn btn--outlined"
                        : "btn btn--upload"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center my-5">
        <div className="col-12 col-md-4">
          <Button
            variant="secondary"
            className="text-white w-100"
            type="button"
            disabled={!state?.payment_receipts?.length}
            onClick={(e) => handleSubmit(e)}
          >
            {local.submitPayment}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default TransferDetails;

import React from "react";
import { Route, Routes } from "react-router-dom";
import NoMatch from "../../components/Common/NoMatch";
import WorkshopPage from "../../components/Workshop";
import RegistartionSuccessful from "../../components/Workshop/RegistartionSuccessful/index";

const WorkshopRoutes = () => {
  return (
    <div className="content">
      <Routes>
        <Route path="/successful" element={<RegistartionSuccessful />} />
        <Route path="/" element={<WorkshopPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default WorkshopRoutes;

import React from "react";
import { Container, Button, Card, Col, Row } from "react-bootstrap";
import visionLogo from "../../../../assets/images/vision-logo-white.png";
import ProgressSteps from "../../../../shared/ProgressSteps";
import { certificateSteps } from "../../../../lookups/steps";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Steps() {
  const navigate = useNavigate();
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="main-steps bg-primary">
      <Container className="px-4 px-xl-0">
        <Row>
          <Col>
            <Card className="shadow bg-success vision-card">
              <Row className="g-5">
                <Col
                  xs={12}
                  xl={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={visionLogo} alt="" className="p-2" />
                </Col>
                <Col xs={12} xl={9}>
                  <p className="text-white fs-2 px-0 px-xl-3">
                    {local.visionDescription}
                  </p>
                  <span className="text-white fs-4 px-0 px-xl-3">
                    {local.princeMohammedBinSalman}
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <h2 className="text-white text-center fs-1">
                {local.stepsToGetCertificate}
              </h2>
              <ProgressSteps
                stepLabelText={"steps-main-text text-tertiary"}
                stepLabelTextActive={"steps-main-text text-tertiary"}
                type={"image"}
                stepsClass={"steps-main"}
                stepClass={"step-main"}
                stepCompletedClass={"step-main--completed"}
                steps={certificateSteps}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xs={8} xl={3} className="text-center mt-4">
            <Button
              variant="secondary"
              className="text-white w-100"
              onClick={() => {
                navigate("/auth");
              }}
            >
              {local.getCertificate}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Steps;

import React from "react";
import { Route, Routes } from "react-router-dom";

import Entity from "../../components/Entity";
import Update from "../../components/Entity/Update";
import NoMatch from "../../components/Common/NoMatch";

const EntityRoutes = () => {
  return (
    <div className="content">
      <Routes>
        <Route path="/update" element={<Update />} />
        <Route path="/" element={<Entity />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default EntityRoutes;

import React from "react";
import { Route, Routes } from "react-router-dom";
import CallbackHandler from "../../components/Auth/CallbackHandler";
import ChooseCompany from "../../components/Auth/ChooseCompany";
import RegistrationInstructions from "../../components/Auth/GovernmentCompany/Instructions";
import GovernmentLogin from "../../components/Auth/GovernmentCompany/Login";
import GovernemntOtp from "../../components/Auth/GovernmentCompany/Otp";
import Landing from "../../components/Auth/Landing";
import ResetPassword from "../../components/Auth/GovernmentCompany/ResetPassword";
import NoMatch from "../../components/Common/NoMatch";
import VerifyEmail from "../../components/Auth/VerifyEmail";
import GovernmentRegistration from "../../components/Auth/GovernmentCompany/Register";
import RegistrationSuccess from "../../components/Auth/GovernmentCompany/RegistrationSuccess";

const AuthRoutes = () => {
  return (
    <div className="content">
      <Routes>
        {/* <Route path="/landing" element={<Landing />} /> */}
        <Route path="/" element={<ChooseCompany />} />
        <Route path="/government/login" element={<GovernmentLogin />} />
        <Route
          path="/government/instruction"
          element={<RegistrationInstructions />}
        />
        <Route
          path="/government/register"
          element={<GovernmentRegistration />}
        />
        <Route
          path="/government/register/success"
          element={<RegistrationSuccess />}
        />
        <Route path="/government/otp" element={<GovernemntOtp />} />
        <Route path="/government/reset-password" element={<ResetPassword />} />
        <Route path="/callback" element={<CallbackHandler />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default AuthRoutes;

import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import certificatePhoto from "../../../../assets/images/certificate-photo.png";
import { useSelector } from "react-redux";

function MowaamahCertificate() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="about-hero" id="about-hero">
      <Container>
        <Row className="justify-content-start">
          <Col xs={12} lg={6}>
            <h1 className="display-4 mb-5 font-weight-bold">
              {local.aboutMowaamahCertificate}
            </h1>
            <p>{local.aboutMowaamahContent}</p>
          </Col>
        </Row>
      </Container>
      <img
        src={certificatePhoto}
        alt="certificate photo"
        className="about-hero-img position-absolute"
      />
    </section>
  );
}

export default MowaamahCertificate;

import * as types from "./types";
import {  toast } from 'react-toastify';


export const resetErrors = () => {
  return {
    type: types.RESET_ERRORS,
  };
};

export const setErroStatus = (payload) => {
  return async dispatch => {
      dispatch({
          type: types.SET_ERROR_STATUS,
          payload: payload
      })
  }
};

export const setError = (payload) => {
  return async dispatch => {
      toast.error(payload.message)
      dispatch({
          type: types.SET_ERROR,
          payload: payload
      })
  }
};


export const setErrors = (payload) => {
  return async dispatch => {
      dispatch({
        type: types.SET_ERRORS,
        payload: payload
    })
  }
};

export const resetError = (payload) => {
  return async dispatch => {
      dispatch({
          type: types.RESET_SPECIFC_ERRORS,
          payload: payload
      })
  }
};
import { setError } from "../store/Error/epics";
import arLocal from "../localization/Arabic/index.json";
import enLocal from "../localization/English/index.json";
import { toast } from "react-toastify";

const formValidaiton = (data, dispatch) => {
  // const local = Arabic

  var lang = localStorage.getItem("prefered_language");
  const local = lang === "en" ? enLocal : arLocal;
  const validateNin = (num) => {
    if (num.length === 10 && (num.startsWith(1) || num.startsWith(2))) {
      return true;
    } else {
      return false;
    }
  };

  const validateMobile = (num) => {
    var regex = new RegExp(
      /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    );
    if (regex.test(num)) {
      return true;
    } else {
      return false;
    }
  };

  const validateEmail = (email) => {
    var regex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  };
  const validateNumber = (number) => {
    var regex = new RegExp(/^\d+$/);
    if (regex.test(number)) {
      return true;
    } else {
      return false;
    }
  };

  let passwords = {};
  const validatePassword = (key, value) => {
    passwords[key] = value;
    if (value === "") {
      isValid = false;
      dispatch(setError({ [key]: local.cantLeavThisFieldEmpty }));
    } else if (Object.keys(passwords).length === 2) {
      if (
        passwords[Object.keys(passwords)[0]] !==
        passwords[Object.keys(passwords)[1]]
      ) {
        dispatch(
          setError({ [Object.keys(passwords)[0]]: local.passwordsMustMatch })
        );
        dispatch(
          setError({ [Object.keys(passwords)[1]]: local.passwordsMustMatch })
        );
        isValid = false;
      }
    }
  };

  const validate700Number = (num) => {
    const regex = /^700\d{7}$/;
    return regex.test(num);
  };

  const validatePostalCode = (code) => {
    const regex = /^\d{5}$/;
    return regex.test(code);
  };

  let isValid = true;
  for (const key in data) {
    if (data[key].type === "map" && data[key].value === "") {
      dispatch(setError({ [key]: local.mapRequired }));
    } else if (data[key].value === "") {
      if (data[key].required) {
        isValid = false;
        dispatch(setError({ [key]: local.cantLeavThisFieldEmpty }));
      }
    } else if (data[key].type === "boolean") {
      if (data[key].value === false) {
        isValid = false;
        dispatch(setError({ [key]: local.mustCheckTheBox }));
      }
    } else if (data[key].type === "file") {
      const file = data[key].value;

      if (!file || file.name === "") {
        isValid = false;
        dispatch(setError({ [key]: local.attachmentRequired }));
      }
      if (file?.size > 10 * 1024 * 1024) {
        //10 megabytes
        console.log("file size", file.size);
        isValid = false;
        toast.error(local.fileSizeLessThan10MB);
        dispatch(setError({ [key]: local.fileSizeLessThan10MB }));
      }
    } else if (data[key].type === "passwords") {
      validatePassword(key, data[key].value);
    } else if (data[key].type === "nin") {
      if (!validateNin(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.enterCorrectNin }));
      }
    } else if (data[key].type === "mobile") {
      if (!validateMobile(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.enterCorrectPhoneNumber }));
      }
    } else if (data[key].type === "email") {
      if (!validateEmail(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.enterCorrectEmail }));
      }
    } else if (data[key].type === "number") {
      if (!validateNumber(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.enterOnlyNumbers }));
      }
    } else if (data[key].type === "700") {
      if (!validate700Number(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.enterCorrect700 }));
      }
    } else if (data[key].type === "postal") {
      if (!validatePostalCode(data[key].value)) {
        isValid = false;
        dispatch(setError({ [key]: local.mustBe5Digits }));
      }
    }
  }
  return isValid;
};

export default formValidaiton;

import React from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import nitaqatLogo from "../../../../assets/images/nitaqat-logo.png";
import mwLogo from "../../../../assets/images/mw-logo.png";
import hrsdLogo from "../../../../assets/images/hrsd-logo-ar.svg";
import { useSelector } from "react-redux";

function Specialities() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="specialities">
      <Container>
        <Row className="justify-content-around">
          <Col>
            <h2 className="text-tertiary text-center mb-5 mb-xl-4">
              {local.mowaamahCertificateFeatures}
            </h2>
          </Col>
        </Row>
        <Row className="g-5">
          <Col xs={12} lg={4}>
            <Card>
              <img src={nitaqatLogo} alt="logo" />
              <p className="fs-5">{local.nitaqatFeature}</p>
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card className="mt-xl-4 mb-5">
              <img src={mwLogo} alt="logo" />
              <p className="fs-5">{local.mowaamahFeature}</p>
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card>
              <img src={hrsdLogo} alt="logo" />
              <p className="fs-5">{local.hrsdFeature}</p>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Specialities;

import { Button } from "react-bootstrap";

const ButtonWithInput = ({
  buttonHolder,
  buttonOnClick,
  placeholder,
  inputDir,
  htmlFor,
  labelClass,
  label,
  inputName,
  required,
  inputValue,
  type,
  isInvalid,
  inputClass,
  inputId,
  onChange,
  error,
}) => {
  let inputClassValue = () => {
    if (isInvalid) {
      return inputClass + " isInvalid";
    } else {
      return inputClass;
    }
  };

  return (
    <div>
      <label htmlFor={htmlFor} className={"form-label " + labelClass}>
        {" "}
        {label}
      </label>

      <div className="d-flex align-items-center">
        <input
          placeholder={placeholder}
          dir={inputDir}
          required={required}
          name={inputName}
          value={inputValue}
          type={type}
          className={inputClassValue()}
          id={inputId}
          aria-describedby="basic-addon3"
          onChange={onChange}
        />
        <div id="validationServerUsernameFeedback" className="text-danger mt-2">
          {error}
        </div>

        <Button
          type="button"
          className="text-white me-3 px-4"
          onClick={buttonOnClick}
          variant="success"
          size="sm"
        >
          {buttonHolder}
        </Button>
      </div>
    </div>
  );
};

ButtonWithInput.defaultProps = {
  buttonHolder: "تفعيل",
  placeholder: "",
  inputDir: "",
  htmlFor: "",
  labelClass: "",
  required: false,
  type: "text",
  validation: "text",
  inputClass: "form-control",
  isInvalid: false,
  inputId: "",
  error: "",
};

export default ButtonWithInput;

function deleteFileFromQuestion(fileName, state, question_id, evidence_id) {
  // Find the question object with the given ID
  const question = state.find(
    (q) => q.question_id.toString() === question_id.toString()
  );
  // const index = state.findIndex(item => item.question_id === question_id);

  // If the question doesn't have any files, return the state as is
  if (!question || !question.evidences) {
    return state;
  }

  // Filter out the file with the given fileName
  const updatedFiles = question.evidences.filter((file) =>
    file.id ? file.id != evidence_id : file.file_name !== fileName
  );

  // Create a new question object with the updated files array
  const updatedQuestion = {
    ...question,
    evidences: updatedFiles,
  };

  // Create a new array of questions with the updated question object
  const updatedState = state.map((q) => {
    if (q.question_id.toString() !== question_id.toString()) {
      return q;
    }
    return updatedQuestion;
  });

  // Return the updated state
  return updatedState;
}

export default deleteFileFromQuestion;

import React from "react";
import { Route, Routes } from "react-router-dom";
import TrainingOrders from "../../components/Training/TrainingOrders";
import Goals from "../../components/Training/Goals";
import NoMatch from "../../components/Common/NoMatch";

const TrainingRoutes = () => {
  return (
    <div className="content">
      <Routes>
        <Route path="goals" element={<Goals />} />
        <Route path="/" element={<TrainingOrders />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default TrainingRoutes;

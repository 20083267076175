import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AuthInstance } from "../../../utils/axiosConfig";
import * as Sentry from "@sentry/react";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { setSurveyGiven } from "../../../utils/localStorage";
import Loader from "../../Common/Loader";

function SurveyModal({ uuid, type }) {
  const local = useSelector((state) => state.homeReducer.local);
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyLink, setSurveyLink] = useState(null);
  const [loading, setLoading] = useState(false);

  useLegacyEffect(() => {
    const getSurveyData = async () => {
      setLoading(true);
      try {
        let response = await AuthInstance.get(
          `/assessment_order/${uuid}/survey/`
        );
        if (response?.data?.payload?.data?.survey_url) {
          setShowSurvey(true);
          setSurveyLink(response.data.payload.data.survey_url);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (!isSurveyGiven()) {
      if (!surveyLink) {
        getSurveyData();
      }
    }
  }, []);
  const isSurveyGiven = () => {
    let given = false;
    console.log(JSON.parse(localStorage.getItem("survey") || "[]"));
    if (
      type === "payment" &&
      JSON.parse(localStorage.getItem("survey") || "[]")?.length > 0
    ) {
      let surveyList = JSON.parse(localStorage.getItem("survey") || "[]");
      surveyList.map((item) => {
        if (item.id === uuid && item.type === type) {
          given = true;
        }
      });
    }
    return given;
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Modal
        show={showSurvey}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title className="p-3">{local.surveyTitle}</Modal.Title>
        <Modal.Body>
          <p>{local.surveyRecipent}</p>
          <p>{local.surveyDescription}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-light"
            onClick={() => {
              setShowSurvey(false);
            }}
          >
            {local.close}
          </Button>
          <Link
            className="btn btn-primary"
            to={surveyLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              setShowSurvey(false);
              if (type === "payment") {
                setSurveyGiven([
                  ...JSON.parse(localStorage.getItem("survey") || "[]"),
                  { id: uuid, type: type },
                ]);
              }
            }}
          >
            {local.surveyButton}
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SurveyModal;

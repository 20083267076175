import {SET_LOADING,SET_TOKEN_LOADING} from "./types";

export const setLoading = (payload) => {
    return async dispatch => {
        dispatch({
            type: SET_LOADING,
            payload: payload
        })
    }
};


export const setTokenLoading = (payload) => {
    return async dispatch => {
        dispatch({
            type: SET_TOKEN_LOADING,
            payload: payload
        })
    }
};
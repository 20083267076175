import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Common/Loader";
import OrdersTable from "../../../shared/OrdersTable";
import { Button, Card, Container } from "react-bootstrap";

const Orders = () => {
  let { companyId } = useParams();

  const local = useSelector((state) => state.homeReducer.local);

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const currentOrders = useSelector(
    (state) => state.certificateReducer.currentOrders
  );
  const oldOrders = useSelector((state) => state.certificateReducer.oldOrders);
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const user = useSelector((state) => state.authReducer.user);

  const currentOrdersList = currentOrders.map((order) => {
    return (
      <OrdersTable
        buttonFlag={order?.button_flag}
        order={order}
        key={order?.id}
      />
    );
  });
  const oldOrdersList = oldOrders.map((order) => {
    return (
      <OrdersTable
        buttonFlag={order?.button_flag}
        order={order}
        key={order?.id}
      />
    );
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="order">
      <h2 className="text-primary mb-5">{local.mowaamahCertificate}</h2>

      {!currentOrders?.length > 0 ? (
        <div
          className={
            oldOrders.length !== 0 ? "vertical-center-full-screen" : ""
          }
        >
          <p className="text-dark fs-4">{local.noCertificates}</p>

          <div className="w-50">
            <Button
              variant="secondary"
              disabled={!companyInfo.able_to_order || !user.is_email_verified}
              onClick={() => navigate(`/${companyId}/certificate/instructions`)}
              className="text-white mt-5 w-50"
              type="submit"
            >
              {local.getCertificate}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <h2 className="mb-3 text-success fs-4 mt-5">{local.currentOrders}</h2>
          <Card className="current shadow p-4 mb-5">
            <div className="grid d-none d-md-grid">
              <p className="g-col-3 mb-0 fw-bold">{local.orderType}</p>
              <p className="g-col-3 mb-0 fw-bold">{local.orderDate}</p>
              <p className="g-col-3 mb-0 fw-bold">{local.status}</p>
              <p className="g-col-3 mb-0"></p>
            </div>
            <hr className="d-none d-md-block my-0" />
            {currentOrdersList}
          </Card>
        </>
      )}

      {oldOrders?.length > 0 && (
        <>
          <h2 className="mb-3 text-success fs-4 mt-5">
            {local.previousOrders}
          </h2>
          <Card className="old shadow p-4 mb-5">
            <div className="grid d-none d-md-grid">
              <p className="g-col-3 mb-0 fw-bold">{local.orderType}</p>
              <p className="g-col-3 mb-0 fw-bold">{local.orderDate}</p>
              <p className="g-col-3 mb-0 fw-bold">{local.status}</p>
              <p className="g-col-3 mb-0"></p>
            </div>
            <hr className="d-none d-md-block my-0" />
            {oldOrdersList}
          </Card>
        </>
      )}
    </Container>
  );
};

export default Orders;

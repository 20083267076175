import React, { useEffect } from "react";
import standard1 from "../../../../assets/images/standard-icon1.png";
import standard2 from "../../../../assets/images/standard-icon2.png";
import standard3 from "../../../../assets/images/standard-icon3.png";
import standard4 from "../../../../assets/images/standard-icon4.png";
import standard5 from "../../../../assets/images/standard-icon5.png";
import standard6 from "../../../../assets/images/standard-icon6.png";
import standard7 from "../../../../assets/images/standard-icon7.png";
import standard8 from "../../../../assets/images/standard-icon8.png";
import { Col, Row, Card, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function Standards() {
  const { state } = useLocation();
  const { targetId } = state || {};
  const local = useSelector((state) => state.homeReducer.local);

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
        alignToTop: true,
      });
    }
  }, [targetId]);

  return (
    <section className="about-standards" id="about-standards">
      <Container>
        <Row className="mb-5">
          <Col>
            <h2 className="fs-1 text-white text-center mb-5">
              {local.mowaamahCriterias}
            </h2>
          </Col>
        </Row>
        <Row className="gy-5">
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard1} alt="standard 1" className="" />
              </figure>
              <h3>{local.criteriasList[0]}</h3>
              <p>{local.criteriasDescriptionList[0]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard2} alt="standard 2" className="" />
              </figure>
              <h3>{local.criteriasList[1]}</h3>
              <p>{local.criteriasDescriptionList[1]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard3} alt="standard 3" />
              </figure>
              <h3>{local.criteriasList[2]}</h3>
              <p>{local.criteriasDescriptionList[2]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard4} alt="standard 4" />
              </figure>
              <h3>{local.criteriasList[3]}</h3>
              <p>{local.criteriasDescriptionList[3]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard5} alt="standard 5" />
              </figure>
              <h3>{local.criteriasList[4]}</h3>
              <p>{local.criteriasDescriptionList[4]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard6} alt="standard 6" />
              </figure>
              <h3>{local.criteriasList[5]}</h3>
              <p>{local.criteriasDescriptionList[5]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard7} alt="standard 7" />
              </figure>
              <h3>{local.criteriasList[6]}</h3>
              <p>{local.criteriasDescriptionList[6]}</p>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card>
              <figure>
                <img src={standard8} alt="standard 8" />
              </figure>
              <h3>{local.criteriasList[7]}</h3>
              <p>{local.criteriasDescriptionList[7]}</p>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Standards;

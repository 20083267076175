import React from "react";
import { Accordion, Card, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqsList } from "../../../store/Home/actions";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import Loader from "../../Common/Loader";
import sanitizeHtml from "sanitize-html";

function FaqPage() {
  const local = useSelector((state) => state.homeReducer.local);
  const dispatch = useDispatch();
  const faqsList = useSelector((state) => state.homeReducer.faqsList);
  const loading = useSelector((state) => state.commonReducer.loading);

  useLegacyEffect(() => {
    dispatch(fetchFaqsList());
  }, [local.language]);
  if (loading) {
    return <Loader />;
  }
  return (
    <section className="faq-page">
      <Container>
        <Row>
          <Col>
            <h1 className="text-primary text-center mb-5 font-weight-bold">
              {local.FAQs}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="p-4 p-lg-5 shadow">
              <Accordion flush>
                {faqsList?.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(item.answer),
                        }}
                      ></div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FaqPage;

const mergeDataById = (newData, existingData) => {
  const arr = [...existingData]; // spread existingData into a new array

  // Check if array already contains an object with the same id as newData
  const alreadyExists = arr.some((obj) => obj.id === newData.id);

  // If newData doesn't already exist, add it to the array
  if (!alreadyExists) {
    arr.push(newData);
  }

  return arr;
};

export default mergeDataById;

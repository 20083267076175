import React, { useState, useRef, useEffect } from "react";
import mowaamahLogo from "../../../assets/images/mowaamah-logo-color.svg";
import mowaamahLogoWhite from "../../../assets/images/mowaamah-logo-white.svg";
import hrsdLogo from "../../../assets/images/hrsd-emblem-color.svg";
import hrsdLogoWhite from "../../../assets/images/hrsd-emblem-white.svg";
import { useDispatch, useSelector } from "react-redux";
import Menu from "./Menu";
import { Button, Container, Nav, Navbar, Collapse } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { callLogout } from "../../../store/Auth/actions";
import { switchCurrentLanguage } from "../../../store/Home/epics";
import isEmpty from "../../../utils/isEmpty";
import { landingRoutes } from "../../../lookups/status";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [isLanding, setIsLanding] = useState(false);
  const navbarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [isXsScreen, setIsXsScreen] = useState(false);

  const currentLanguage = useSelector(
    (state) => state.homeReducer.currentLanguage
  );

  const local = useSelector((state) => state.homeReducer.local);
  const loading = useSelector((state) => state.commonReducer.loading);

  const handleLocalization = () => {
    dispatch(switchCurrentLanguage());
    document.body.setAttribute("dir", local.language === "ar" ? "ltr" : "rtl");
    document.documentElement.setAttribute(
      "dir",
      local.language === "ar" ? "ltr" : "rtl"
    );
    document.documentElement.setAttribute(
      "lang",
      local.language === "ar" ? "en" : "ar"
    );
    localStorage.setItem("prefered_language", currentLanguage);
  };

  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const user = useSelector((state) => state.authReducer.user);
  let companyId = companyInfo.id;

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  useEffect(() => {
    if (window.innerWidth < 576) {
      setIsXsScreen(true);
    }

    const handleIsXsScreen = (event) => {
      if (window.innerWidth < 576) {
        setIsXsScreen(true);
      } else {
        setIsXsScreen(false);
      }
    };
    window.addEventListener("resize", handleIsXsScreen);

    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/home") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
    const checkLandingPage = landingRoutes.filter((route) =>
      route.includes(location.pathname)
    );
    if (checkLandingPage.length > 0) {
      setIsLanding(true);
    } else {
      setIsLanding(false);
    }
    if (typeof window !== "undefined") {
      changeNavbarColor();
      window.addEventListener("scroll", changeNavbarColor);
    }
  }, [location]);
  const goToPage = (path) => {
    navigate(path);
    setOpen(false);
  };
  const changeNavbarColor = () => {
    const { scrollY } = window;
    if (window.location.pathname === "/home") {
      if (scrollY >= 50) {
        setIsHome(false);
      } else {
        setIsHome(true);
      }
    }
  };
  return (
    <>
      <Navbar
        className={`mowaamah-header ${
          isHome ? "bg-transparent shadow-none home-nav" : "bg-white"
        }`}
        ref={navbarRef}
        expand={"lg"}
      >
        <Container fluid={!isLanding}>
          <div
            className={`align-items-center w-100 justify-content-between ${
              isAuthenticated ? "d-lg-flex d-none" : "d-flex"
            }`}
          >
            <Navbar.Brand href="/home" className="d-none d-lg-block">
              <img
                src={isHome ? mowaamahLogoWhite : mowaamahLogo}
                alt="logo"
                aria-hidden="true"
              />
            </Navbar.Brand>

            <Navbar.Brand className="d-block d-lg-none me-0" href={`/home`}>
              <img
                src={isHome ? mowaamahLogoWhite : mowaamahLogo}
                alt="logo"
                aria-hidden="true"
              />
            </Navbar.Brand>

            {isLanding && (
              <>
                <Navbar.Toggle aria-controls="home-navbar-nav" />
                <Navbar.Collapse
                  id="home-navbar-nav"
                  className={`home-nav ${
                    isHome ? "bg-transparent" : "bg-white"
                  }`}
                >
                  <Nav>
                    <Nav.Link
                      as={Link}
                      className={`px-4 ${
                        isHome ? "text-white" : "text-primary"
                      } ${location.pathname === "/home" && "active"}`}
                      to="/home"
                    >
                      {local.home}
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className={`px-4 ${
                        isHome ? "text-white" : "text-primary"
                      } ${location.pathname === "/about" && "active"}`}
                      to="/about"
                    >
                      {local.aboutUs}
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className={`px-4 ${
                        isHome ? "text-white" : "text-primary"
                      } ${location.pathname === "/faq" && "active"}`}
                      to="/faq"
                    >
                      {local.FAQs}
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className={`px-4 ${
                        isHome ? "text-white" : "text-primary"
                      } ${location.pathname === "/contact-us" && "active"}`}
                      to="/contact-us"
                    >
                      {local.contactUs}
                    </Nav.Link>
                  </Nav>
                  <Button
                    variant={"outline-primary"}
                    className="d-block d-lg-none m-2"
                    onClick={handleLocalization}
                  >
                    {currentLanguage}
                  </Button>
                  {!isAuthenticated && (
                    <Button
                      variant="secondary"
                      className="text-white mx-2 px-3 d-block d-lg-none"
                      size="sm"
                      onClick={() => {
                        navigate("/auth");
                      }}
                    >
                      {local.signIn}
                    </Button>
                  )}
                </Navbar.Collapse>
              </>
            )}
          </div>

          <div className="d-flex align-items-center">
            <Button
              variant={isHome ? "outline-white" : "outline-primary"}
              className="d-none d-lg-block"
              onClick={handleLocalization}
            >
              {currentLanguage}
            </Button>

            {!isAuthenticated && isLanding && (
              <Button
                variant="secondary"
                className="text-white mx-2 px-3 d-none d-lg-block"
                size="sm"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                {local.signIn}
              </Button>
            )}

            {isAuthenticated && (
              <Navbar.Brand className="d-block d-lg-none" href={`/home`}>
                <img
                  src={isHome ? mowaamahLogoWhite : mowaamahLogo}
                  alt="logo"
                  aria-hidden="true"
                />
              </Navbar.Brand>
            )}
            {isAuthenticated && !isXsScreen && location.pathname !== "/" && (
              <Menu isLanding={isLanding} />
            )}
            <Navbar.Brand className="d-none d-lg-block ms-3" href={`/home`}>
              <img
                src={isHome ? hrsdLogoWhite : hrsdLogo}
                alt="logo"
                aria-hidden="true"
              />
            </Navbar.Brand>
          </div>

          {companyInfo?.id &&
            isAuthenticated &&
            companyInfo.is_registration_completed && (
              <Button
                variant="outline-primary"
                className="border-0 bg-transparent shadow-none d-block d-lg-none"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
              >
                <i
                  className={`bi bi-${
                    open ? "x-lg" : "list"
                  } fs-1 text-primary`}
                ></i>
              </Button>
            )}

          <div className="mowaamah-menu position-absolute top-50 mt-5 d-block d-lg-none">
            <Collapse in={open} dimension="width">
              <div>
                <Nav
                  className="mobile-nav justify-content-end flex-grow-1 text-bg-primary"
                  style={{ width: "300px" }}
                >
                  {isAuthenticated && (
                    <Navbar.Text className="text-white fs-2 pe-3">
                      {local.language === "ar"
                        ? user.full_name_ar
                        : user.full_name_en}
                    </Navbar.Text>
                  )}
                  <Nav.Link onClick={() => goToPage(`/${companyId}`)}>
                    <i className="bi bi-house-door"></i> {local.home}
                  </Nav.Link>
                  <Nav.Link onClick={() => goToPage(`/${companyId}/entity`)}>
                    <i className="bi bi-file-text"></i> {local.entityDetails}
                  </Nav.Link>
                  <Nav.Link
                    onClick={() => goToPage(`/${companyId}/certificate`)}
                  >
                    <i className="bi bi-file-earmark-medical"></i>
                    {local.mowaamahCertificate}
                  </Nav.Link>
                  <Nav.Link onClick={() => goToPage(`/${companyId}/training`)}>
                    <i className="bi bi-person-rolodex"></i> {local.training}
                  </Nav.Link>
                  <Nav.Link onClick={() => goToPage(`/${companyId}/workshop`)}>
                    <i className="bi bi-person-fill-gear"></i> {local.workshop}
                  </Nav.Link>
                  <Nav.Link onClick={() => goToPage(`/${companyId}/support`)}>
                    <i className="bi bi-chat-left-text"></i> {local.contactUs}
                  </Nav.Link>
                  {isAuthenticated && (
                    <Nav.Link
                      className="logout-btn"
                      onClick={() => dispatch(callLogout())}
                    >
                      <i className="bi bi-box-arrow-right"></i> {local.signOut}
                    </Nav.Link>
                  )}
                </Nav>
              </div>
            </Collapse>
          </div>
        </Container>
        {isAuthenticated && isXsScreen && location.pathname !== "/" && (
          <div className=" my-2 mx-3">
            <Menu isLanding={isLanding} />
          </div>
        )}
      </Navbar>
      {!user.is_email_verified && !isLanding && isAuthenticated && !loading && (
        <div className="w-100 bg-tertiary p-2 text-white text-center fs-5">
          {local.emailVerificationMessage}
        </div>
      )}
    </>
  );
};

export default Header;

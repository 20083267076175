import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Common/Loader";
import { useNavigate } from "react-router-dom";
import LeafletMap from "../../shared/LeafletMap";
import { Button, Card, Container } from "react-bootstrap";
import useLegacyEffect from "../../hooks/useLegacyEffect";
import { fetchUserInfo } from "../../store/Auth/actions";

const Entity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loading);
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const user = useSelector((state) => state.authReducer.user);
  const local = useSelector((state) => state.homeReducer.local);
  const [position, setPosition] = useState({ lat: 24.712082, lng: 46.673481 });

  useLegacyEffect(() => {
    if (!companyInfo?.address?.latitude) {
      setPosition({ lat: 24.712082, lng: 46.673481 });
    } else {
      setPosition({
        lat: +companyInfo?.address?.latitude,
        lng: +companyInfo?.address?.longitude,
      });
    }
  }, [companyInfo]);
  useLegacyEffect(() => {
    dispatch(fetchUserInfo());
  }, []);
  const handleMarkerDragEnd = (point) => {
    setPosition(point);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="entity">
      <div className="grid">
        <h2 className="g-col-md-9 g-col-7">{local.entityDetails}</h2>
        {companyInfo.able_to_edit && (
          <Button
            variant="outline-secondary"
            className="px-4 py-1 g-col-md-3 g-col-5"
            onClick={() => navigate("update")}
          >
            <i className="bi bi-pen ms-2"></i> {local.updateInfo}
          </Button>
        )}
      </div>
      <Card className="shadow p-4 mt-5">
        <h2 className="mb-4 fs-4 text-success">{local.basicInfo}</h2>
        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.companyName_ar}</p>
            <p className="fs-5 font-medium">{companyInfo.name}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.companyName_en}</p>
            <p className="fs-5">{companyInfo.name_en}</p>
          </div>
        </div>
        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.companyNo}</p>
            <p className="fs-5">{companyInfo.seven_hundred}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.companySize}</p>
            <p className="fs-5">
              {local.language === "ar"
                ? companyInfo?.type?.type_ar
                : companyInfo?.type?.type_en}
            </p>
          </div>
        </div>
        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.commercialRecord}</p>
            <p className="fs-5">{companyInfo.commercial_record_number}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.taxNumber}</p>
            <p className="fs-5">{companyInfo.tax_number}</p>
          </div>
        </div>

        <hr />
        <h2 className="mb-4 mt-3 fs-4 text-success">{local.contactInfo}</h2>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.comapnyPhoneNumber}</p>
            <p className="fs-5 phone-number">{companyInfo.phone_number}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.phoneNumberExtention}</p>
            <p className="fs-5">{companyInfo.phone_number_extention}</p>
          </div>
        </div>
        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.email}</p>
            <p className="fs-5">{companyInfo.email}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.extraEmail}</p>
            <p className="fs-5">{companyInfo.extra_email}</p>
          </div>
        </div>

        <hr />
        <h2 className="mb-4 mt-3 fs-4 text-success">{local.companyAddress}</h2>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.region}</p>
            <p className="fs-5">
              {local.language === "ar"
                ? companyInfo?.address?.region_name_ar
                : companyInfo?.address?.region_name_en}
            </p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.city}</p>
            <p className="fs-5">
              {local.language === "ar"
                ? companyInfo?.address?.city_name_ar
                : companyInfo?.address?.city_name_en}
            </p>
          </div>
        </div>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.district}</p>
            <p className="fs-5">{companyInfo?.address?.district}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.street}</p>
            <p className="fs-5">{companyInfo?.address?.street}</p>
          </div>
        </div>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.buildingNumber}</p>
            <p className="fs-5">{companyInfo?.address?.building_number}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.secondaryNumber}</p>
            <p className="fs-5">{companyInfo?.address?.secondary_number}</p>
          </div>
        </div>
        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.postalCode}</p>
            <p className="fs-5">{companyInfo?.address?.postal_code}</p>
          </div>
        </div>

        <div className="grid mb-4">
          <div className="g-col-12">
            <p className="text-primary fs-5">{local.location}</p>

            <LeafletMap
              position={position}
              zoom={15}
              scrollWheelZoom={false}
              draggable={false}
              setPosition={handleMarkerDragEnd}
            />
          </div>
        </div>

        <hr />
        <h2 className="mb-4 mt-3 fs-4 text-success">{local.userInfo}</h2>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.userName_ar}</p>
            <p className="fs-5">{user.full_name_ar}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.userName_en}</p>
            <p className="fs-5">{user.full_name_en}</p>
          </div>
        </div>

        <div className="grid mb-4">
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary"> {local.userPhoneNumber}</p>
            <p className="fs-5 phone-number">{user.phone_number}</p>
          </div>
          <div className="g-col-12 g-col-md-6">
            <p className="text-primary">{local.userEmail}</p>
            <p className="fs-5">{user.email}</p>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default Entity;

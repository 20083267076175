import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Common/Loader";
import ChevronBar from "../../../shared/ChevronBar";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { Button, Container } from "react-bootstrap";
import SurveyModal from "../../Payment/Survey";

const Auditing = () => {
  const local = useSelector((state) => state.homeReducer.local);

  const dispatch = useDispatch();
  const { uuid, companyId } = useParams();

  const navigate = useNavigate();
  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);
  const loading = useSelector((state) => state.commonReducer.loading);

  useLegacyEffect(() => {
    dispatch(setCurrentCertificateFlowStep(3));
  }, []);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <ChevronBar
                type={"shape"}
                stepsClass={"steps-flow-container"}
                stepClass={"step-flow"}
                stepText={"step-flow-text"}
                stepTextCurrent={"step-flow-text--current"}
                stepTextUncompleted={"step-flow-text--uncompleted"}
                stepTextCompleted={"step-flow-text--completed"}
                stepCompletedClass={"step-home--completed"}
                steps={flowSteps}
                currentStep={currentCertificateFlowStep}
              />

              <h1 className="text-success mt-5 mb-3">
                {local.underAuditingProcess}
              </h1>

              <p className="fs-4 mb-5">{local.waitForEmail}</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-4 text-center">
              <Button
                variant="secondary"
                onClick={() => navigate(`/${companyId}`)}
                className="text-white w-100"
                type="button"
              >
                {local.backToHomePage}
              </Button>
            </div>
          </div>
        </>
      )}
      <SurveyModal uuid={uuid} type={"audit"} />
    </Container>
  );
};

export default Auditing;

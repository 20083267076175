import React, { useState } from "react";
import Loader from "../../Common/Loader";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../../hooks/useQuery";
import { callUpdateTransactionStatus } from "../../../store/Payment/actions";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { Container } from "react-bootstrap";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import { orderType } from "../../../lookups/status";

const Processing = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const query = useQuery();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);

  let { uuid, paymentMethod, checkoutId, companyId } = useParams();

  useLegacyEffect(() => {
    const payload = {
      checkoutId: checkoutId,
      paymentMethod: paymentMethod,
      uuid: uuid,
    };

    const fetchData = async () => {
      // if (query.get("type") === orderType.certificate) {
      //   await dispatch(
      //     fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      //   );
      // } else if (query.get("type") === orderType.training) {
      //   dispatch(
      //     fetchTrainingOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      //   );
      // } else {
      //   // navigate(`/${companyId}`);
      // }

      await dispatch(
        callUpdateTransactionStatus(
          navigate,
          payload,
          companyId,
          query.get("type")
        )
      );
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  if (loading || !dataLoaded) {
    return <Loader />;
  }

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <div className="col text-center">
        <i className="bi bi-arrow-repeat display-2 text-primary mb-3"></i>
        <h1 className="text-primary">{local.processing}</h1>
      </div>
    </Container>
  );
};

export default Processing;

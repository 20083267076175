import { errorMessages } from "../../../lookups/status";
import { useSelector } from "react-redux";

const TextInput = ({
  placeholder,
  inputDir,
  htmlFor,
  labelClass,
  label,
  inputName,
  required,
  inputValue,
  type,
  isInvalid,
  inputClass,
  inputId,
  onChange,
  error,
  disabled,
  maxLength,
  max,
  min,
}) => {
  const local = useSelector((state) => state.homeReducer.local);
  let errorMessageObj = "";

  let inputClassValue = () => {
    if (error) {
      if (inputName === "new_password") {
        errorMessageObj = errorMessages(local).find(
          (element) => element?.id === error[0]
        );
      }
      return inputClass + " isInvalid";
    } else {
      return inputClass;
    }
  };

  return (
    <div>
      {label && (
        <label htmlFor={htmlFor} className={"form-label " + labelClass}>
          {label}
          {required && <i className="text-danger mx-1">*</i>}

          {maxLength && ( //character count display
            <small className="text-muted mx-2">
              {inputValue.length}/{maxLength}
            </small>
          )}
        </label>
      )}
      <div className="mb-4">
        <input
          disabled={disabled}
          placeholder={placeholder}
          dir={inputDir}
          required={required}
          name={inputName}
          value={inputValue}
          type={type}
          className={inputClassValue() + " form-control"}
          id={inputId}
          aria-describedby="basic-addon3"
          onChange={onChange}
          maxLength={maxLength}
          min={min}
          max={max}
        />
        <div id="validationServerUsernameFeedback" className="text-danger mt-2">
          {errorMessageObj?.value || error}
        </div>
      </div>
    </div>
  );
};

TextInput.defaultProps = {
  disabled: false,
  placeholder: "",
  inputDir: "",
  htmlFor: "",
  labelClass: "",
  required: false,
  type: "text",
  validation: "text",
  inputClass: "",
  isInvalid: false,
  inputId: "",
  error: "",
  inputValue: "",
  maxLength: undefined,
  min: 0,
  max: "",
};

export default TextInput;

import { AuthInstance } from "../../utils/axiosConfig";
import { setErrors } from "../Error/actions";
import { resetErrors } from "../Error/epics";
import { setLoading } from "../Common/actions";
import * as epics from "./epics";
import { orderType } from "../../lookups/status";

export const fetchCurrentTrainingOrders = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      let response = await AuthInstance.get(
        `training_requisition/?company_id=${companyId}`
      );
      let data = await response.data;
      dispatch(epics.setCurrentTrainingOrders(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchOldTrainingOrders = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());
      let response = await AuthInstance.get(
        `training_requisition/expired/?company_id=${companyId}`
      );
      let data = await response.data;
      dispatch(epics.setOldTrainingOrders(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const createTrainingOrder = (navigate, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.post(`training_requisition/`, {
        company_id: companyId,
      });
      let data = await response.data;
      dispatch(epics.setTrainingOrder(data.payload.data));
      navigate(
        `/${companyId}/payment/${data.payload.data.id}/review?type=${orderType.training}`
      );

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const fetchTrainingOrder = (
  navigate,
  orderId,
  companyId,
  currentStatus
) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetErrors());

      let response = await AuthInstance.get(`training_requisition/${orderId}/`);
      let data = await response.data;
      dispatch(epics.setTrainingOrder(data.payload.data));
      if (data.payload.data.status !== currentStatus) {
        navigate(`/${companyId}`);
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};

export const callApplyTrainingDiscountCode = (trainingOrderId, code) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      let response = await AuthInstance.post(
        `training_requisition/${trainingOrderId}/apply_discount/`,
        code
      );
      let data = await response.data;
      dispatch(epics.setTrainingOrder(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
export const callRemoveTrainingDiscountCode = (trainingOrderId) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      let response = await AuthInstance.post(
        `training_requisition/${trainingOrderId}/remove_discount/`
      );
      let data = await response.data;
      dispatch(epics.setTrainingOrder(data.payload.data));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    }
  };
};
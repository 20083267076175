import useLegacyEffect from "../hooks/useLegacyEffect";

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export const ZendeskAPI = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

export default function Zendesk(props) {
  const onScriptLoaded = () => {
    if (typeof props.onLoaded === "function") {
      props.onLoaded();
    }
  };

  const insertScript = (zendeskKey, defer) => {
    const script = document.createElement("script");
    if (defer) {
      script.defer = true;
    } else {
      script.async = true;
    }
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener("load", onScriptLoaded);
    document.body.appendChild(script);
  };
  useLegacyEffect(() => {
    if (canUseDOM && !window.zE) {
      const { defer, zendeskKey, ...other } = props;
      insertScript(zendeskKey, defer);
      window.zESettings = other;
    }
    return () => {
      if (!canUseDOM || !window.zE) {
        return;
      }
      delete window.zE;
      delete window.zESettings;
    };
  }, []);

  return null;
}

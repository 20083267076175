import React from "react";
import MowaamahCertificate from "./components/mowaamahCertificate";
import Goals from "./components/goals";
import Classification from "./components/classification";
import Standards from "./components/standards";
import Register from "./components/register";

function AboutPage() {
  return (
    <div className="about">
      <MowaamahCertificate />
      <Goals />
      <Classification />
      <Standards />
      <Register />
    </div>
  );
}

export default AboutPage;

import Gold from "../../assets/images/BadgesIcons/Gold.svg";
import Silver from "../../assets/images/BadgesIcons/Silver.svg";
import Bronze from "../../assets/images/BadgesIcons/Bronze.svg";
import Participant from "../../assets/images/BadgesIcons/Participant.svg";

export const certifiedStatus = (local) => {
  return [
    {
      code: undefined,
      image: "",
      class: "outline-dark",
      text: local.notCertified,
    },
    {
      code: "gold",
      image: Gold,
      class: "outline-success",
      text: local.certifiedGold,
    },
    {
      code: "silver",
      image: Silver,
      class: "outline-success",
      text: local.certifiedSilver,
    },
    {
      code: "bronze",
      image: Bronze,
      class: "outline-success",
      text: local.certifiedBronze,
    },
    {
      code: "participant",
      image: Participant,
      class: "outline-success",
      text: local.certifiedParticipant,
    },
  ];
};

export const currenOrdersStatus = (local) => {
  return [
    {
      id: "INITIAL",
      buttonText: local.continueAssessment,
      status: local.AssessmentNotCompleted,
      buttonFlag: true,
      path: "?",
      certificateDomain: "certificate",
      trainingDomain: "training",
    },
    {
      id: "PENDING_PAYMENT",
      buttonText: local.continueToPayFees,
      status: local.pendingPaymentFees,
      buttonFlag: true,
      path: "review?",
      certificateDomain: "payment",
      trainingDomain: "payment",
    },
    {
      id: "PENDING_SADAD_PAYMENT",
      buttonText: local.continueToSadad,
      status: local.pendingSadadPaymentFees,
      buttonFlag: true,
      path: "method/sadad/invoice?",
      certificateDomain: "payment",
      trainingDomain: "payment",
    },
    {
      id: "PENDING_BANK_TRANSFER_PAYMENT",
      buttonText: local.continueToBankStatus,
      status: local.pendingBankPaymentFees,
      buttonFlag: true,
      path: "method/transfer/status?status=PENDING_BANK_TRANSFER_PAYMENT&",
      certificateDomain: "payment",
      trainingDomain: "payment",
    },
    {
      id: "INSUFFICIENT_AMOUNT",
      buttonText: local.seeResult,
      status: local.paymentTransferRejected,
      buttonFlag: true,
      path: "method/transfer/status?status=PENDING_BANK_TRANSFER_PAYMENT&",
      certificateDomain: "payment",
      trainingDomain: "payment",
    },
    {
      id: "BANK_TRANSFER_PAYMENT_FAILED",
      buttonText: local.seeResult,
      status: local.paymentTransferRejected,
      buttonFlag: true,
      path: "method/transfer/status?status=PENDING_PAYMENT&",
      certificateDomain: "payment",
      trainingDomain: "payment",
    },
    {
      id: "PENDING_EVIDENCE",
      buttonText: local.continueToAddEvidence,
      status: local.paid,
      buttonFlag: true,
      path: "evidence?",
      certificateDomain: "certificate",
    },
    {
      id: "PENDING_AUDIT",
      buttonText: "",
      status: local.underAuditing,
      buttonFlag: false,
    },
    {
      id: "PENDING_QUALITY",
      buttonText: "",
      status: local.pendingAdmin,
      buttonFlag: false,
    },
    {
      id: "REJECTED_BY_AUDITOR",
      buttonText: "",
      status: local.underAuditing,
      buttonFlag: false,
    },
    {
      id: "ACCEPTED_BY_AUDITOR",
      buttonText: "",
      status: local.underAuditing,
      buttonFlag: false,
    },
    {
      id: "PENDING_ADMIN",
      buttonText: "",
      status: local.pendingAdmin,
      buttonFlag: false,
    },
    {
      id: "REJECTED",
      buttonText: local.seeResult,
      status: local.rejected,
      buttonFlag: true,
      path: "rejected?",
      certificateDomain: "certificate",
      trainingDomain: "training",
    },
    {
      id: "ACCEPTED",
      buttonText: local.previewCertificate,
      status: local.accepted,
      buttonFlag: true,
      certificateDomain: "certificate",
      path: "accepted?",
    },
    {
      id: "EXPIRED",
      buttonText: local.expired,
      status: local.expired,
      buttonFlag: false,
    },
    {
      id: "PENDING_TRAINING_SCHEDULE",
      buttonText: local.seeResult,
      status: local.pendingTrainingSchedule,
      buttonFlag: false,
    },
    {
      id: "TRAINING_SCHEDULED",
      buttonText: local.seeResult,
      status: local.trainingScheduled,
      buttonFlag: false,
    },
    {
      id: "TRAINING_DONE",
      status: local.trainingDone,
      buttonFlag: false,
    },
  ];
};

export const rejectionReasons = (local) => {
  return [
    {
      id: "INVALID_ATTACHMENT",
      text: local.invalidAttachment,
    },
    {
      id: "UNCLEAR_ATTACHMENT",
      text: local.unclearAttachment,
    },
    {
      id: "INSUFFICIENT_AMOUNT",
      text: local.insufficientAmount,
    },
    {
      id: "OTHER",
      text: local.other,
    },
  ];
};

export const orderType = {
  certificate: "CERTIFICATE",
  training: "TRAINING",
};

export const companyTypes = (local) => {
  return [
    {
      id: "حكومية",
      text: local.government,
    },
    {
      id: "عملاقة",
      text: local.hugeCompanies,
    },
    {
      id: "كبيرة",
      text: local.bigCompanies,
    },
    {
      id: "متوسطة",
      text: local.mediumCompanies,
    },
    {
      id: "صغيرة",
      text: local.smallCompanies,
    },
  ];
};
export const landingRoutes = [
  "/home",
  "/contact-us",
  "/faq",
  "/about",
  "/privacy-policy",
  "/terms-condition",
];

export const errorMessages = (local) => [
  {
    id: "This password is too short. It must contain at least 8 characters.",
    value: local.shortPassword,
  },
  {
    id: "This password is too common.",
    value: local.easyPassword,
  },
  {
    id: "Authentication Failed",
    value: local.authFailed,
  },
  {
    id: "Invalid OTP. Please try again",
    value: local.invalidOTP,
  },
  {
    id: "This Field May Not Be Null.",
    value: local.cantLeavThisFieldEmpty,
  },
  {
    id: "Reuired Fields Incorrect Or Missing",
    value: local.correctFormErrors,
  },
  {
    id: "The password reset link has expired",
    value: local.resetLinkExpired,
  },
  {
    id: "The maximum OTP entry attempts has been exceeded",
    value: local.otpLimitExceeded,
  },
];

import React from "react";
import { Route, Routes } from "react-router-dom";

import Certificate from "../../components/Certificate";
import Grade from "../../components/Certificate/Grade";
import Auditing from "../../components/Certificate/Auditing";
import Orders from "../../components/Certificate/Orders";
import Instructions from "../../components/Certificate/Instructions";
import Rejected from "../../components/Certificate/Result/Rejected";
import Accepted from "../../components/Certificate/Result/Accepted";
import NoMatch from "../../components/Common/NoMatch";

const CertificateRoutes = () => {
  return (
    <div className="content">
      <Routes>
        <Route path=":uuid" element={<Certificate />} />
        <Route path=":uuid/grade" element={<Grade />} />
        <Route path=":uuid/evidence" element={<Certificate />} />
        <Route path=":uuid/auditing" element={<Auditing />} />
        <Route path=":uuid/rejected" element={<Rejected />} />
        <Route path=":uuid/accepted" element={<Accepted />} />
        <Route path="instructions" element={<Instructions />} />
        <Route path="" element={<Orders />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default CertificateRoutes;

export const setPaymentParam = ({
  entityId = "",
  entityType = "",
  isSet = true,
}) => {
  try {
    if (isSet) {
      localStorage.setItem("entityId", entityId);
      localStorage.setItem("entityType", entityType);
    } else {
      localStorage.removeItem("entityId");
      localStorage.removeItem("entityType");
    }
  } catch (e) {}
};

export const setAccessTokenInStorage = (access) => {
  try {
    if (access) {
      localStorage.setItem("access", access);
    } else {
      localStorage.removeItem("access");
    }
  } catch (e) {}
};

export const setOidcState = (oidc_states) => {
  try {
    if (oidc_states) {
      localStorage.setItem("oidc_states", oidc_states);
    } else {
      localStorage.removeItem("oidc_states");
    }
  } catch (e) {}
};

export const setIsOIDCAuth = (oidcIdToken) => {
  try {
    if (oidcIdToken) {
      localStorage.setItem("is_oidc_auth", oidcIdToken);
    } else {
      localStorage.removeItem("is_oidc_auth");
    }
  } catch (e) {}
};

export const setRefreshTokenInStorage = (refresh) => {
  try {
    if (refresh) {
      localStorage.setItem("refresh", refresh);
    } else {
      localStorage.removeItem("refresh");
    }
  } catch (e) {}
};

export const setUserDataInStorage = (userData) => {
  try {
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    } else {
      localStorage.removeItem("userData");
    }
  } catch (e) {}
};

export const setFromLocation = (location) => {
  try {
    if (location) {
      localStorage.setItem("from", JSON.stringify(location));
    } else {
      localStorage.removeItem("from");
    }
  } catch (e) {}
};
export const setSurveyGiven = (survey) => {
  try {
    if (survey) {
      localStorage.setItem("survey", JSON.stringify(survey));
    } else {
      localStorage.removeItem("survey");
    }
  } catch (e) {}
};

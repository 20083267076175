import * as types from "./types";

const initialState = {
  currentTrainingOrders: [],
  oldTrainingOrders: [],
  trainingOrder: {},
};

const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TRAINING_ORDER:
      return {
        ...state,
        trainingOrder: action.payload,
      };
    case types.SET_CURRENT_TRAINING_ORDERS:
      return {
        ...state,
        currentTrainingOrders: action.payload,
      };
    case types.SET_OLD_TRAINING_ORDERS:
      return {
        ...state,
        oldTrainingOrders: action.payload,
      };
    default:
      return state;
  }
};

export default trainingReducer;

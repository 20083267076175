import formatBytes from "./formatBytes";

const addFiles = (setState, prevState, event) => {
  // Convert files to an array of file objects if it's not already
  const fileList = Array.isArray(event.target.files)
    ? event?.target?.files
    : Array.from(event?.target?.files).map((file) => ({
        file_name: file.name,
        path: URL.createObjectURL(file),
        file_size: formatBytes(file.size),
        size: file.size,
      }));

  const existingFiles = prevState[event?.target?.name] || [];
  const newFiles = fileList.filter(
    (file) =>
      !existingFiles.some(
        (existingFile) => existingFile.file_name === file.file_name
      )
  );

  const newState = {
    ...prevState,
    [event?.target?.name]: existingFiles.concat(newFiles),
  };
  setState(newState);
};

export default addFiles;

import * as types from "./types";

export const setTrainingOrder = (payload) => ({
  type: types.SET_TRAINING_ORDER,
  payload: payload,
});

export const setCurrentTrainingOrders = (payload) => ({
  type: types.SET_CURRENT_TRAINING_ORDERS,
  payload: payload,
});

export const setOldTrainingOrders = (payload) => ({
  type: types.SET_OLD_TRAINING_ORDERS,
  payload: payload,
});

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callSendOtp } from "../../../../store/Auth/actions";
import Loader from "../../../Common/Loader";
import { useNavigate, Link } from "react-router-dom";
import TextInput from "../../../../shared/FormElements/TextInput";
import { resetError, resetErrors } from "../../../../store/Error/epics";
import useLegacyEffect from "../../../../hooks/useLegacyEffect";
import formValidaiton from "../../../../utils/formValidaiton";
import onChangeHandler from "../../../../utils/onChangeHandler";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import BackButton from "../../../../shared/BackButton";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
const LoginForm = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const errors = useSelector((state) => state.errorReducer.errors);
  const loading = useSelector((state) => state.commonReducer.loading);
  useLegacyEffect(() => {
    dispatch(resetErrors());
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    let token = "";

    try {
      token = (await executeRecaptcha("login")) || "";
    } catch (e) {
      console.error(e);
    }

    setUser((prevUser) => ({
      ...prevUser,
      recaptcha: token || "",
    }));
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const [user, setUser] = useState({
    email: "",
    password: "",
    otp_method: "SMS",
    recaptcha: "",
  });

  const handleOnChange = (event) => {
    onChangeHandler(event, setUser, user, errors, dispatch, resetError);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationFormData = {
      email: { value: user.email, type: "email", required: true },
      password: { value: user.password, type: "text", required: true },
      //recaptcha: { value: user.recaptcha, type: "text", required: true },
    };
    const isValidForm = formValidaiton(validationFormData, dispatch);

    if (isValidForm) {
      dispatch(callSendOtp(navigate, user, true));
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6}>
          <Card className="login-card shadow w-100 px-4 py-5 px-lg-5 mb-5">
            <TextInput
              inputId={"login_email"}
              type={"email"}
              label={local.email}
              inputName={"email"}
              inputValue={user.email}
              isInvalid={errors.email}
              error={errors.email}
              onChange={handleOnChange}
            />
            <TextInput
              inputId={"login_password"}
              type={"password"}
              label={local.password}
              inputName={"password"}
              inputValue={user.password}
              isInvalid={errors.password}
              error={errors.password}
              onChange={handleOnChange}
            />
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6} className="text-center">
          <Button variant="secondary" className="text-white w-75" type="submit">
            {local.signIn}
          </Button>
          <p className="text-center my-4">
            <span className="mx-2 d-inline-block">{local.dontHaveAccount}</span>
            <Link className="text-secondary " to={"/auth/government/register"}>
              {local.createAccount}
            </Link>
          </p>
        </Col>
      </Row>
    </Form>
  );
};
const GovernmentLogin = () => {
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  useLegacyEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <Container>
      <BackButton onClick={() => navigate("/auth")} label={local.backV2} />
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6}>
          <p className="fs-3 text-primary font-weight-bold">
            {local.governmentLogin}
          </p>
        </Col>
      </Row>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <LoginForm />
      </GoogleReCaptchaProvider>
    </Container>
  );
};

export default GovernmentLogin;

import React from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../utils/isEmpty";
import useLegacyEffect from "../../hooks/useLegacyEffect";
import NoMatch from "../../components/Common/NoMatch";
import { fetchCompanyInfo } from "../../store/Home/actions";
import { fetchUserInfo } from "../../store/Auth/actions";
import { toast } from "react-toastify";
import {
  fetchCurrentOrders,
  fetchOldOrders,
} from "../../store/Certificate/actions";
import {
  fetchCurrentTrainingOrders,
  fetchOldTrainingOrders,
} from "../../store/Training/actions";
import { setFromLocation } from "../../utils/localStorage";

const AuthenticatedComponentsWrapper = ({ children }) => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);
  const errorStatus = useSelector((state) => state.errorReducer.errorStatus);
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const { companyId } = useParams();
  const local = useSelector((state) => state.homeReducer.local);
  const navigate = useNavigate();
  // const currentOrders = useSelector(
  //   (state) => state.certificateReducer.currentOrders
  // );
  // const oldOrders = useSelector((state) => state.certificateReducer.oldOrders);
  // const previousCompanyId = useRef(companyId);

  useLegacyEffect(() => {
    const fetchAssessmentOrders = async () => {
      await dispatch(fetchCurrentOrders(companyId));
      await dispatch(fetchOldOrders(companyId));
    };
    const fetchTrainingOrders = async () => {
      await dispatch(fetchCurrentTrainingOrders(companyId));
      await dispatch(fetchOldTrainingOrders(companyId));
    };
    if (isAuthenticated && isEmpty(companyInfo) && companyId) {
      dispatch(fetchCompanyInfo(companyId, null));
    }
    if (
      !isEmpty(companyInfo) &&
      !companyInfo.is_registration_completed &&
      location.pathname !== `/${companyId}/entity/update`
    ) {
      navigate(`/${companyId}/entity/update`);
      toast.error(local.mustUpadateCompanyInfo);
    } else if (
      location.pathname === `/${companyId}/certificate` &&
      isAuthenticated
      // &&
      // (previousCompanyId.current !== companyId ||
      //   isEmpty(oldOrders) ||
      //   isEmpty(currentOrders))
    ) {
      fetchAssessmentOrders();
    } else if (
      location.pathname === `/${companyId}/training` &&
      isAuthenticated
    ) {
      fetchTrainingOrders();
    }
  }, [location.pathname, companyId, isAuthenticated]);

  if (errorStatus === 404) {
    return <NoMatch />;
  }

  if (!isAuthenticated) {
    if (location.pathname !== "/") {
      setFromLocation(location);
    }
    return <Navigate to="/home" state={{ from: location }} replace />;
  }
  return children;
};

export default AuthenticatedComponentsWrapper;

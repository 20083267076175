function deleteFile(filename, state, setState, filesName) {
  // Filter out the file with the given filename
  const updatedFiles = state[filesName].filter(
    (file) => file.file_name !== filename
  );

  // Update the state with the new array of questions
  setState({ ...state, [filesName]: updatedFiles });
}

export default deleteFile;

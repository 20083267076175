import * as types from "./types";

const initialState = {
  user: {},
  isAuthenticated: false,
  otpInfo: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };

    case types.SET_IS_USER_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case types.SET_OTP_INFO:
      return {
        ...state,
        otpInfo: { ...state.otpInfo, ...action.payload },
      };

    default:
      return state;
  }
};

export default authReducer;

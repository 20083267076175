import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import goal1 from "../../../../assets/images/goal-icon1.png";
import goal2 from "../../../../assets/images/goal-icon2.png";
import goal3 from "../../../../assets/images/goal-icon3.png";
import goal4 from "../../../../assets/images/goal-icon4.png";
import { useSelector } from "react-redux";

function Goals() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <section className="goals">
      <Container>
        <Row className="mb-5">
          <Col xs={12}>
            <h2 className="text-white fs-1 font-weight-bold mb-3">
              {local.mowaamahCertificateGoals}
            </h2>
          </Col>
        </Row>
        <Row className="gy-5">
          <Col xs={12} md={6} xl={3}>
            <div className="goal-card">
              <img src={goal1} alt="Goal 1" />
              <p className="text-white">
                {local.mowaamahCertificateGoalsList[0]}
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <div className="goal-card">
              <img src={goal2} alt="Goal 2" />
              <p className="text-white">
                {local.mowaamahCertificateGoalsList[1]}
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <div className="goal-card">
              <img src={goal3} alt="Goal 3" />
              <p className="text-white">
                {local.mowaamahCertificateGoalsList[2]}
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={3}>
            <div className="goal-card">
              <img src={goal4} alt="Goal 4" />
              <p className="text-white">
                {local.mowaamahCertificateGoalsList[3]}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Goals;

import React from "react";
import BackButton from "../../../../shared/BackButton";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Card, Container, Row } from "react-bootstrap";

function RegistrationInstructions() {
  const navigate = useNavigate();
  const local = useSelector((state) => state.homeReducer.local);
  return (
    <Container>
      <BackButton onClick={() => navigate(-1)} label={local.backV2} />
      <Row className="justify-content-center align-items-center">
        <Col xs={12} lg={6}>
          <Card className="login-card shadow px-4 py-5 px-lg-5 w-100 mb-4">
            <div className="mb-5">
              <p className="text-primary fs-5">{local.askForAccount}</p>
              <a
                href="mailto:info@mowaamah.com"
                target="_blank"
                className="text-secondary fs-4"
                rel="noreferrer"
              >
                info@mowaamah.com
              </a>
            </div>
            <div className="mb-3">
              <p className="text-primary fs-5">{local.orContactNumbers}</p>
              <p className="text-secondary fs-4 mb-0">
                <span className="ms-4 d-inline-flex ltr">011-2817874</span>
                <span>011-4076510</span>
              </p>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mb-5">
        <Col className="text-center" xs={12} lg={6}>
          <p>
            <span className="mx-2">{local.haveAccount}</span>
            <Link className="text-secondary" onClick={() => navigate(-1)}>
              {local.signIn}
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default RegistrationInstructions;

import React from "react";
import { useSelector } from "react-redux";

function EnPrivacy() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <div>
      <div className="text-left">
        <h1 className="mb-5">{local.privacyPolicy}</h1>
        <p className="text-justify">
          The website{" "}
          <a href="https://www.mowaamah.sa" target="_blank" rel="noreferrer">
            https://www.mowaamah.sa
          </a>{" "}
          ("our website") contains information that users must be aware of
          regarding the confidentiality of information and the privacy of highly
          important users’ data. Our goal and commitment are to provide a
          high-quality service to all users while maintaining the highest levels
          of privacy. Therefore, this privacy policy has been developed to help
          you understand the nature of the personal data we collect from you,
          the purpose of collecting and processing that data, and to keep you
          informed about how Mowaamah Program ("the Company") uses your personal
          data, and your rights regarding the processing of your personal data
          in accordance with the relevant laws and regulations in the Kingdom of
          Saudi Arabia. This policy and the information provided below are
          considered part of the terms of use of our website.
        </p>
        <h4>Acceptance of This Privacy Policy:</h4>

        <p className="text-justify">
          By using our website, you agree to the privacy policy, and accepting
          this policy means you agree to the following, including but not
          limited to collecting your personal data, processing your personal
          data, storing your personal data, disclosing and sharing your personal
          data with government entities as required by regulatory and legal
          requirements, and in accordance with the policies, regulations, and
          terms that govern the website.
        </p>
        <h4>Changes to This Privacy Policy:</h4>
        <p className="text-justify">
          You are responsible for regularly reviewing this policy and its
          associated policies to stay informed of any updates or changes we may
          make to this privacy policy. Your use of the website signifies your
          awareness and consent to the privacy policy and any ongoing
          modifications. Additionally, you are solely responsible for the
          completeness and accuracy of the data you enter through this website.
        </p>
        <h4>Data and Information We Collect: </h4>
        <p className="text-justify">
          By visiting our website, we may automatically collect and use the
          following data and information:
        </p>
        <ul>
          <li>
            Personal data, this includes the information you provide when
            registering on our website or using our services, such as names,
            personal identification numbers, addresses, contact numbers, user’s
            photos, and other required information. It also includes payment
            data, such as the information collected for payment transactions,
            bank account numbers, and credit card information. Additionally, it
            includes data exchanged during communication with you, such as
            customer support requests and reports received from you.
          </li>
          <li>
            Cookies, the website may store cookies on your computer when you
            visit the website. Cookies are pieces of data that uniquely
            identifies you as a user and can be used to improve your knowledge
            of the website and its user base.
          </li>
          <li>
            Most web browsers automatically accept cookies, but you can modify
            your browser settings to decline all cookies or receive
            notifications when cookies are being sent. Please note that some or
            all of the services provided on this website may not function
            properly if you disable cookies. We may also link the information
            stored in cookies with any personally identifiable information you
            provide on our website.
          </li>
          <li>
            When you visit our website, the web server automatically records
            your IP address (the unique address assigned to your computer),
            which allows other devices connected to the Internet to determine
            the origin of the data being sent. The server may also collect
            additional information, such as browser type, search engine, date
            and time of visit, and the URL of any website that referred you to
            our website. However, this information does not identify your
            personal identity.
          </li>
          <li>
            Occasionally, users may be asked to provide their geographical
            location to benefit from certain services provided on the website.
          </li>
        </ul>
        <h4>How We Collect Your Personal Data:</h4>
        <p className="text-justify">
          Personal data may be collected directly from you through various
          means, including:
        </p>
        <ul>
          <li>
            Products and services - when you access, provide your data, use our
            website, or sign a contract to become our client and use one of our
            products or services.
          </li>
          <li>
            Direct or indirect interactions - When you complete and submit a
            form to us through our website, mobile applications, email, mail, or
            via phone call to our contact center. This includes any personal
            data you provide when making an inquiry, participating in a
            promotional offer, or any other form of communication with us.
          </li>
          <li>
            Social media interactions - Any posts and interactions you have
            directly with us on our social media channels.
          </li>
          <li>
            Browsing surveys - Data about how you use our website, including the
            date and time of your visit, the type of internet browser you use,
            and how you were referred to our website.
          </li>
          <li>
            Web surveys - Our online web surveys enable us to collect specific
            data, such as your feedback on the appearance and functionality of
            our website and mobile applications. Additionally, we may also
            request information about various customer service elements. Given
            that providing your name and other details is optional.
          </li>
        </ul>
        <h4>Links to Other Websites and Third Parties on The Internet:</h4>
        <p className="text-justify">
          This policy applies to our website and all the services and programs
          it provides. If you navigate to another website or platform through
          our website, you should read the privacy policies of that site to
          determine their information security practices and privacy policy.
        </p>
        <h4>Purpose of Collecting Personal Data:</h4>
        <p className="text-justify">
          Personal data is collected and used for several reasons, including but
          not limited to:
        </p>
        <ul>
          <li>
            Collecting and processing personal data to meet legal, regulatory,
            operational, and developmental requirements.
          </li>
          <li>
            Fulfilling the necessary data requirements for providing services
            and electronic transactions.
          </li>
          <li>Understanding users’ needs to improve products and services.</li>
          <li>
            Enabling the development, operation, and improvement of the
            company’s products and services and the marketing to the company’s
            clients.
          </li>
          <li>Allowing users to access services and conduct transactions.</li>
          <li>
            Analyzing data and issuing reports that serve the necessary business
            requirements, such as statistical reports.
          </li>
          <li>
            Archiving this information and data and/or using it for future
            communication with you.
          </li>
          <li>
            Resolving inquiries or complaints you may have and improving the
            user experience across all company’s communication channels.
          </li>
        </ul>
        <h4>
          Your Rights Regarding the Processing and Protection of Your Personal
          Data:
        </h4>
        <p className="text-justify">
          Under the Personal Data Protection Law in the Kingdom of Saudi Arabia,
          data’s owner have the following rights, which primarily depend on the
          purpose of collecting and processing personal data:
        </p>
        <ul>
          <li>
            <b>Right to be informed: </b> Data’s owner has the right to be
            informed of the purpose of collecting and processing their personal
            data, the legal basis for this purpose, the content of the data to
            be processed, the method of collection, the means of storage, and
            the parties to whom the data will be disclosed (where privacy
            notices can be one of the means through which data subjects can
            exercise this right).
          </li>
          <li>
            <b>Right to access your personal data: </b>Data’s owner has the
            right to request a copy of their personal data through the available
            channels or via the email address provided in the contact details
            below.
          </li>
          <li>
            <b>Right to correct your personal data: </b>Data’s owner has the
            right to request the correction of their personal data if they
            believe it is inaccurate, incorrect, or incomplete, through the
            authorized channels or via the email address provided in the contact
            details below.
          </li>
          <li>
            <b>Right to destruct your personal data: </b>Data’s owner has the
            right to request the destruction of their personal data when the
            purpose of its collection and processing has been fulfilled, under
            certain conditions that do not contradict legal principles and
            legitimate interests, and upon the expiration of the legal and
            regulatory retention period.
          </li>
          <li>
            <b>Right to object to processing: </b>Data’s owner has the right to
            object to the (collection, processing, storage, sharing, or
            disclosure) of their personal data without explicit or implicit
            consent, or if processing is for direct marketing purposes.
          </li>
          <li>
            <b>Right to restrict processing: </b>Data’s owner has the right to
            request the restriction of processing their personal data for any
            purposes.
          </li>
          <li>
            <b>Right to transfer personal data: </b>Data’s owner has the right
            to request the transfer of their personal data (processed data) that
            was collected directly from them and with their explicit consent to
            any party according to the policies followed by the company.
          </li>
        </ul>
        <p className="text-justify">
          For more details about processing your personal data and how to
          exercise your rights, you can refer to the Personal Data Protection
          Law issued by Royal Decree No. (M/19) dated 09/02/1443H and its
          executive regulations.
        </p>
        <h4>Sharing your Personal Data:</h4>
        <p className="text-justify">
          We may exchange, process, and share any information and data you
          provide us with your full knowledge and/or collect from you with our
          partners, or third-party suppliers for legitimate purposes based on
          regulatory basis or operational needs, including but not limited to,
          conducting analyzes and studies, search engine service provider, and
          advertisers. Data is shared in specific ways aimed at achieving a
          public interest without causing any harm to national interests, the
          activities of entities, or individuals' privacy. This excludes data
          and entities exempted by official orders. When sharing your personal
          data, we ensure that it is shared through a secure and trusted
          environment, in accordance with relevant laws, regulations, and
          policies. We may take additional steps to ensure the protection of
          your data by signing a data sharing agreement according to specific
          terms and conditions that aligns with data sharing principles.
        </p>
        {/* <h4>Contact Us:</h4>
        <p className="text-justify">
          If you have any comments, inquiries, or complaints regarding the
          privacy policy of Mowaamah Program, you can contact the Data
          Management Office via the following email:
          <a href="mailto:Info@Mowaamah.com"> Info@Mowaamah.com</a>
        </p> */}
      </div>
    </div>
  );
}

export default EnPrivacy;

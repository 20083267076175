import React from "react";
import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import successIcon from "../../../assets/images/Payment/successIcon.svg";

function RegistartionSuccessful() {
  const local = useSelector((state) => state.homeReducer.local);
  let { companyId } = useParams();
  const navigate = useNavigate();

  return (
    <Container className="p-5 ">
      <div className="row justify-content-center mb-5">
        <div className="col-12 text-center">
          <img
            className="payment-successful-icon mt-5"
            src={successIcon}
            alt="Success Icon"
            aria-hidden="true"
          />

          <h1 className="fs-2 text-success mt-3 mb-5">
            {local.registartionSuccessful}
          </h1>

          <p className="fs-4 text-gray">
            {local.wishSuccessInEducationalJourney}
          </p>
          <div className="row justify-content-center mt-5">
            <div className="col-12 col-md-4 text-center">
              <Button
                variant="secondary"
                className="text-white w-100 mt-3"
                onClick={async () => {
                  navigate(`/${companyId}/workshop`);
                }}
              >
                {local.backToWorkshopPage}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default RegistartionSuccessful;

const formatDate = (dateString, type) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();

  var formattedDate = "";

  if (type === "date") {
    formattedDate = `${day}/${month}/${year}`;
  } else if (type === "dateTime") {
    formattedDate = `${hour}:${minute} - ${day}/${month}/${year}`;
  }
  return formattedDate;
};

export default formatDate;

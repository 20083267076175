import React from "react";
import { useSelector } from "react-redux";

function BackButton({ onClick, label }) {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <button className="btn p-1 text-end mb-4" onClick={() => onClick()}>
      <i
        className={`bi bi-chevron-${
          local.language === "ar" ? "right" : "left"
        } ms-1 text-dark fs-5`}
      ></i>
      <span className="fs-6 align-text-bottom text-dark">{label}</span>
    </button>
  );
}

export default BackButton;

const Checkbox = ({
  wrapperClass,
  error,
  required,
  checked,
  htmlFor,
  labelClass,
  label,
  inputName,
  inputValue,
  type,
  inputClass,
  inputId,
  onChange,
  isInvalid,
}) => {
  return (
    <div className={wrapperClass}>
      <input
        className={isInvalid ? inputClass + " isInvalid" : inputClass}
        checked={checked}
        onChange={onChange}
        value={inputValue}
        name={inputName}
        type={type}
        id={inputId}
      />
      <label className={labelClass} htmlFor={htmlFor}>
        {label}
      </label>
      <div id="validationServerUsernameFeedback" className="text-danger me-2">
        {error}
      </div>
    </div>
  );
};

Checkbox.defaultProps = {
  wrapperClass: "form-check form-check-inline",
  inputValue: "",
  error: "",
  htmlFor: "",
  checked: false,
  labelClass: "form-check-label",
  required: true,
  type: "checkbox",
  inputClass: "form-check-input",
  inputId: "",
};

export default Checkbox;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  callCreateTransaction,
  callCreateTrainingTransaction,
} from "../../../store/Payment/actions";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import logoVisa from "../../../assets/images/logo-visa.svg";
import logoMasterCard from "../../../assets/images/logo-mastercard.svg";
import logoMada from "../../../assets/images/logo-mada.svg";
import logoTransfer from "../../../assets/images/transfer.png";
import logoSadad from "../../../assets/images/sadad.png";
import isEmpty from "../../../utils/isEmpty";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import ChevronBar from "../../../shared/ChevronBar";
import { fetchAssessmentOrder } from "../../../store/Certificate/actions";
import { setCurrentCertificateFlowStep } from "../../../store/Certificate/epics";
import { Button, Container, Modal } from "react-bootstrap";
import BackButton from "../../../shared/BackButton";
import useQuery from "../../../hooks/useQuery";
import { fetchTrainingOrder } from "../../../store/Training/actions";
import { orderType } from "../../../lookups/status";

const SelectCard = () => {
  let { uuid, companyId } = useParams();
  const local = useSelector((state) => state.homeReducer.local);
  const [sadadShow, setSadadShow] = React.useState(false);
  const query = useQuery();
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.commonReducer.loading);

  const currentCertificateFlowStep = useSelector(
    (state) => state.certificateReducer.currentCertificateFlowStep
  );
  const flowSteps = useSelector((state) => state.certificateReducer.flowSteps);

  useLegacyEffect(() => {
    if (query.get("type") === orderType.certificate) {
      dispatch(setCurrentCertificateFlowStep(1));
      dispatch(
        fetchAssessmentOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
    } else if (query.get("type") === orderType.training) {
      dispatch(
        fetchTrainingOrder(navigate, uuid, companyId, "PENDING_PAYMENT")
      );
    } else {
      navigate(`/${companyId}`);
    }

    if (isEmpty(uuid) && !isEmpty(companyId)) {
      navigate(`/${companyId}`);
    } else if (isEmpty(uuid) || isEmpty(companyId)) {
      navigate(`/`);
    }
  }, []);

  const handleClick = (method) => {
    setPaymentMethod(method);
  };

  const handleSubmit = () => {
    if (paymentMethod === "BANK_TRANSFER") {
      navigate(
        `/${companyId}/payment/${uuid}/method/transfer?status=PENDING_PAYMENT&type=${query.get(
          "type"
        )}`
      );
    } else if (paymentMethod === "SADAD") {
      setSadadShow(true);
    } else {
      createTransaction();
    }
  };

  const createTransaction = () => {
    if (query.get("type") === orderType.certificate) {
      let paymentDataPayload = {
        assessment_order_id: uuid,
        payment_method: paymentMethod,
      };
      dispatch(callCreateTransaction(navigate, paymentDataPayload, companyId));
    } else if (query.get("type") === orderType.training) {
      let paymentDataPayload = {
        training_requisition_id: uuid,
        payment_method: paymentMethod,
      };
      dispatch(
        callCreateTrainingTransaction(navigate, paymentDataPayload, companyId)
      );
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="row justify-content-center">
        <div className="col-12">
          {query.get("type") === orderType.certificate && (
            <ChevronBar
              type={"shape"}
              stepsClass={"steps-flow-container"}
              stepClass={"step-flow"}
              stepText={"step-flow-text"}
              stepTextCurrent={"step-flow-text--current"}
              stepTextUncompleted={"step-flow-text--uncompleted"}
              stepTextCompleted={"step-flow-text--completed"}
              stepCompletedClass={"step-home--completed"}
              steps={flowSteps}
              currentStep={currentCertificateFlowStep}
            />
          )}
          <BackButton onClick={() => navigate(-1)} label={local.backV2} />

          <h1 className="mb-5 fs-3">{local.paymentInfo}</h1>

          <div className="payment-options d-flex flex-column flex-md-row align-items-center justify-content-center">
            <div
              onClick={() => handleClick("MADA")}
              className={`payment-options__item  ${
                paymentMethod === "MADA" && " selected"
              }`}
            >
              <img alt="mada" src={logoMada} />
            </div>
            <div
              onClick={() => handleClick("VISA")}
              className={`payment-options__item  ${
                paymentMethod === "VISA" && " selected"
              }`}
            >
              <img alt="visa" src={logoVisa} />
            </div>
            <div
              onClick={() => handleClick("MASTER")}
              className={`payment-options__item ${
                paymentMethod === "MASTER" && " selected"
              }`}
            >
              <img alt="masterCard" src={logoMasterCard} />
            </div>

            <div
              onClick={() => handleClick("SADAD")}
              className={`payment-options__item ${
                paymentMethod === "SADAD" && " selected"
              }`}
            >
              <img
                className="payment-img disabled-image"
                alt="SADAD"
                src={logoSadad}
              />
            </div>

            {/* <div
              onClick={() => handleClick("BANK_TRANSFER")}
              className={`payment-options__item ${
                paymentMethod === "BANK_TRANSFER" && " selected"
              }`}
            >
              <img
                className="payment-img disabled-image"
                alt="BANK_TRANSFER"
                src={logoTransfer}
              />
            </div> */}
          </div>
          <Modal
            show={sadadShow}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <p>{local.proceedToSadadConfirmation}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setSadadShow(false)}>{local.close}</Button>
              <Button
                onClick={() => {
                  createTransaction();
                  setSadadShow(false);
                }}
              >
                {local.proceed}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className="row justify-content-center mt-5 text-center">
        <div className="col-12 col-md-4">
          <Button
            variant="secondary"
            className="text-white w-100 col"
            disabled={paymentMethod === ""}
            onClick={handleSubmit}
          >
            {local.processToPay}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SelectCard;

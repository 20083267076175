import React from "react";
import { useDispatch } from "react-redux";

function FilesList({
  question_id,
  files,
  deleteFn,
  state,
  setState,
  filesName,
}) {
  const dispatch = useDispatch();

  return (
    <div>
      {files &&
        files.map((file, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="bi bi-file-earmark"></i>
            <span
              style={{
                marginRight: "4px",
                direction: "ltr",
              }}
              className="size"
            >
              {file.file_size && "(" + file.file_size + ")"}
            </span>
            <span
              style={{
                marginRight: "4px",
                marginLeft: "8px",
                alignSelf: "center",
              }}
            >
              <a
                className="fileName"
                target="_blank"
                href={file.path ? file.path : file.file}
              >
                {file.file_name}
              </a>
            </span>
            <i
              className="bi bi-x"
              style={{
                cursor: "pointer",
                alignSelf: "center",
                marginTop: "4px",
              }}
              onClick={() =>
                question_id
                  ? dispatch(deleteFn(file.file_name, question_id, file.id))
                  : deleteFn(file.file_name, state, setState, filesName)
              }
            ></i>
          </div>
        ))}
    </div>
  );
}

export default FilesList;

import * as types from "./types";

const initialState = {
  workshopRequest: {},
};

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WORKSHOP_REQUEST:
      return {
        ...state,
        workshopRequest: action.payload,
      };
    default:
      return state;
  }
};

export default workshopReducer;

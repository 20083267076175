import { toast } from "react-toastify";
import arLocal from "../../localization/Arabic/index.json";
import enLocal from "../../localization/English/index.json";
import { errorMessages } from "../../lookups/status";
import * as epics from "./epics";

export const setErrors = (error) => {
  var lang = localStorage.getItem("prefered_language");
  const local = lang === "en" ? enLocal : arLocal;
  const code = error?.response?.status;

  return async (dispatch) => {
    if (code) {
      dispatch(epics.setErroStatus(code));
    }
    if (code === 503) {
      toast.error(error?.response?.statusText);
    } else if (code === 500) {
      toast.error(local.internalServerError);
    } else if (code === 404) {
      toast.error(local?.pageNotFound);
    } else if (error?.response?.data?.seconds) {
      toast.error(
        `${error?.response?.data?.message} please wait ${error?.response?.data?.seconds} seconds`
      );
    } else if (error?.response?.data) {
      if (error?.response?.data?.new_password) {
        // toast.error(error?.response?.data?.new_password[0]);
        dispatch(epics.setErrors(error?.response?.data));
      }
      if (error?.response?.data?.message) {
        const errorMessageObj = errorMessages(local).find(
          (element) => element?.id === error?.response?.data?.message
        );
        if (errorMessageObj?.value) {
          toast.error(errorMessageObj.value);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
      if (error?.response?.data?.detail) {
        const errorMessageObj = errorMessages(local).find(
          (element) => element?.id === error?.response?.data?.detail
        );
        if (errorMessageObj?.value) {
          toast.error(errorMessageObj.value);
        } else {
          toast.error(error?.response?.data?.detail);
        }
      }
      if (error?.response?.data?.errors) {
        dispatch(epics.setErrors(error?.response?.data?.errors));
      }
      if (error?.response?.data?.payload?.data) {
        dispatch(epics.setErrors(error?.response?.data?.payload?.data));
      }
    }
  };
};

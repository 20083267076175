import isEmpty from "./isEmpty";

const formatData = (criteria) => {
  if (!isEmpty(criteria)) {
    return criteria.sections.flatMap((section) =>
      section.questions.map((question) => ({
        question_id: question.id,
        value: "",
        justification: "",
        evidences: [],
      }))
    );
  } else {
    return [];
  }
};

export default formatData;

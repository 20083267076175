import React, { useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";

const FileUploadInput = ({
  multiple,
  width,
  disabledInputId,
  labelDir,
  iconClass,
  groupDir,
  placeholder,
  inputDir,
  htmlFor,
  labelClass,
  label,
  variant,
  inputName,
  required,
  type,
  isInvalid,
  inputClass,
  inputId,
  onChange,
  error,
  FileName,
  inputValue,
  style,
  accept,
  isButton,
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
    hiddenFileInput.current.value = null; // Reset the file input value
  };

  const handleInput = (event) => {
    event.target.style.width = FileName.length * 8 + "px";
  };

  return (
    <div>
      <div dir={groupDir} onClick={handleClick} className={style}>
        <div
          style={{ position: "relative" }}
          className="d-flex align-items-center mb-3"
        >
          {/* Set the parent element to "relative" */}
          {isButton ? (
            <>
              <Button type="button" variant={variant} className={inputClass}>
                <small>
                  <i className={`${iconClass}`}></i>
                  {label}
                </small>
              </Button>

              {inputValue && (
                <span className="text-success">
                  <i className="bi-check-circle-fill m-3"></i>
                </span>
              )}
            </>
          ) : (
            <>
              <input
                required={required}
                disabled
                dir={inputDir}
                placeholder={placeholder}
                type="text"
                style={{ width: width === 0 ? "auto" : width }}
                className={
                  error
                    ? inputClass + " disabledInput isInvalid form-control"
                    : inputClass + " disabledInput form-control"
                }
                id={disabledInputId}
                aria-describedby="basic-addon3"
                value={FileName}
              />
              <i className={`${iconClass}`}></i>
            </>
          )}
        </div>

        {!isButton && (
          <label dir={labelDir} htmlFor={htmlFor}>
            {label}
          </label>
        )}
        <input
          multiple={multiple}
          onInput={handleInput}
          name={inputName}
          style={{ display: "none" }}
          ref={hiddenFileInput}
          type={type}
          className={error ? inputClass + " isInvalid" : inputClass}
          id={inputId}
          aria-describedby="basic-addon3"
          onChange={onChange}
          accept={accept}
        />
      </div>
      <div id="validationServerUsernameFeedback" className="text-danger mt-2">
        {error}
      </div>
    </div>
  );
};

FileUploadInput.defaultProps = {
  iconClass: "bi bi-paperclip",
  multiple: false,
  placeholder: "",
  groupDir: "rtl",
  inputDir: "rtl",
  labelDir: "rtl",
  htmlFor: "",
  FileName: "",
  labelClass: "",
  required: true,
  type: "file",
  inputClass: "",
  isInvalid: false,
  inputId: "",
  disabledInputId: "",
  error: "",
  accept: "image/png, image/jpeg ,.pdf",
  isButton: false,
};

export default FileUploadInput;

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function ArConsent({ setConsentGiven }) {
  return (
    <div>
      <div className="mb-2">
        <h4 className="fs-4 fw-bold text-black mb-2">
          نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك
        </h4>
        <p className="fs-6 p-1">
          نحن نستخدم ملفات تعريف الارتباط لتقديم أفضل تجربة ممكنة على موقعنا.
          لمعرفة المزيد، الرجاء زيارة{" "}
          <Link
            to="/privacy-policy"
            className="text-primary link-opacity-25-hover link-underline-primary link-offset-1"
          >
            سياسة الخصوصية
          </Link>{" "}
          الخاصة بنا. من خلال الاستمرار في استخدام هذا الموقع، أو إغلاق هذه
          الرسالة، فإنك توافق على استخدامنا لملفات تعريف الارتباط.
        </p>
      </div>
      <div>
        <Button
          className="btn btn-primary text-white"
          onClick={() => {
            localStorage.setItem("cookie_consent", true);
            setConsentGiven(true);
          }}
        >
          أوافق
        </Button>
      </div>
    </div>
  );
}

export default ArConsent;

import React from "react";
import { useSelector } from "react-redux";
import ArPrivacy from "./ar-privacy";
import EnPrivacy from "./en-privacy";

function PrivacyPolicy() {
  const local = useSelector((state) => state.homeReducer.local);

  return (
    <div className="privacy-policy mt-5 mb-5 container">
      {local.language === "ar" ? <ArPrivacy /> : <EnPrivacy />}
    </div>
  );
}

export default PrivacyPolicy;

const MobileInput = ({
  placeholder,
  inputDir,
  htmlFor,
  labelClass,
  label,
  inputName,
  required,
  inputValue,
  type,
  isInvalid,
  inputClass,
  inputId,
  onChange,
  error,
  maxLength,
}) => {
  let inputClassValue = () => {
    if (isInvalid) {
      return inputClass + " isInvalid ";
    } else {
      return inputClass;
    }
  };

  return (
    <div className="mb-4">
      <label htmlFor={htmlFor} className={"form-label " + labelClass}>
        {label}
        {required && <i className="text-danger mx-1">*</i>}
      </label>

      <div style={{ direction: "rtl" }}>
        <div className="input-group">
          <input
            placeholder={placeholder}
            dir={inputDir}
            required={required}
            name={inputName}
            value={inputValue}
            type={type}
            className={inputClassValue()}
            id={inputId}
            onChange={onChange}
            maxLength={maxLength}
          />
          <span className="input-group-text isInvalid" id="areaCode">
            +966
          </span>
        </div>
      </div>
      <div id="validationServerUsernameFeedback" className="text-danger mt-2">
        {error}
      </div>
    </div>
  );
};

MobileInput.defaultProps = {
  placeholder: "",
  inputDir: "",
  htmlFor: "",
  labelClass: "",
  required: false,
  type: "text",
  inputClass: "form-control input--mobile",
  isInvalid: false,
  inputId: "",
  error: "",
  maxLength: 10,
};

export default MobileInput;

import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import Sidebar from "./components/Layout/Sidebar";
import Router from "./routers";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import CookieConsent from "./shared/CookieConsent";

const App = () => {
  const companyInfo = useSelector((state) => state.homeReducer.companyInfo);
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  return (
    <div>
      <div
        className={`main-app-container ${
          companyInfo?.id &&
          isAuthenticated &&
          companyInfo?.is_registration_completed
            ? "logged_in"
            : "logged_out"
        }`}
      >
        <header className="main-header">
          <Header />
        </header>
        {companyInfo?.id &&
          isAuthenticated &&
          companyInfo?.is_registration_completed && (
            <aside className="main-sidebar">
              <Sidebar />
            </aside>
          )}
        <main className="main-content">
          <ToastContainer />
          <Router />

          <CookieConsent />
        </main>
        <footer className="main-footer">
          <Footer />
        </footer>
      </div>
    </div>
  );
};

export default App;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import CertificateIconOrange from "../../assets/images/CertificateIconOrange.svg";
import { orderType, currenOrdersStatus } from "../../lookups/status";
import { useNavigate, useParams } from "react-router-dom";
import { setAssessmentOrder } from "../../store/Certificate/epics";
import { setTrainingOrder } from "../../store/Training/epics";
import { Button, Card } from "react-bootstrap";
import { downloadInvoice, downloadReport } from "../../store/Certificate/actions";

const OrdersTable = ({ order }) => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const local = useSelector((state) => state.homeReducer.local);

  const formattedDate = formatDate(order.created_at, "date");

  const currenOrdersStatusObj =
    order?.bank_transfer?.rejection_reason === "INSUFFICIENT_AMOUNT"
      ? currenOrdersStatus(local).find(
          (element) => element?.id === "INSUFFICIENT_AMOUNT"
        )
      : order?.bank_transfer?.rejection_reason
      ? currenOrdersStatus(local).find(
          (element) => element?.id === "BANK_TRANSFER_PAYMENT_FAILED"
        )
      : currenOrdersStatus(local).find(
          (element) => element?.id === order?.status
        );

  const handleClick = () => {
    if (order?.assessment) {
      dispatch(setAssessmentOrder(order));
      navigate(
        `/${companyId}/${currenOrdersStatusObj?.certificateDomain}/${order?.id}/${currenOrdersStatusObj?.path}type=${orderType.certificate}`
      );
    } else if (order?.training_requisition) {
      dispatch(setTrainingOrder(order));
      navigate(
        `/${companyId}/${currenOrdersStatusObj?.trainingDomain}/${order?.id}/${currenOrdersStatusObj?.path}type=${orderType.training}`
      );
    }
  };

  return (
    <>
      <Card className="grid inner_card shadow p-4 my-3 my-md-0 align-items-center">
        <p className="fs-5 mb-0 g-col-12 g-col-md-3">
          <span className="icon-wrapper d-md-inline d-none">
            {/* <img
              className="current-order-icon mx-2"
              src={
                order?.type === "training"
                  ? TrainingIconOrange
                  : CertificateIconOrange
              }
              alt="logo"
              aria-hidden="true"
            /> */}
          </span>
          <span className="d-inline-block d-md-none text-primary mx-1">
            {local.orderType} :
          </span>
          {local.language === "ar"
            ? order?.assessment?.name_ar
            : order?.assessment?.name_en}

          {local.language === "ar"
            ? order?.training_requisition?.name_ar
            : order?.training_requisition?.name_en}
        </p>

        <p className="g-col-md-3 g-col-12 fs-5 mb-0">
          <span className="d-inline-block d-md-none text-primary mx-1">
            {local.orderDate} :
          </span>
          {formattedDate}
        </p>
        <p className="g-col-md-3 g-col-12 fs-5 mb-0">
          <span className="d-inline-block d-md-none text-primary mx-1">
            {local.status} :
          </span>
          {currenOrdersStatusObj?.status}
        </p>
        <p className="g-col-md-3 g-col-12 fs-5 mb-0">
          {currenOrdersStatusObj?.buttonFlag && (
            <Button
              variant="secondary"
              onClick={handleClick}
              className="text-white w-100"
            >
              {currenOrdersStatusObj?.buttonText}
            </Button>
          )}
          {order?.invoice && (
            <Button
              variant="outline-success"
              onClick={() => dispatch(downloadInvoice(order.invoice))}
              className="w-100 mt-3"
            >
              <i className="bi bi-file-earmark-text ms-2 "></i>
              {local.downloadInvoice}
            </Button>
          )}
          {order?.has_report && (
            <Button
              variant="outline-success"
              onClick={() => dispatch(downloadReport(order.id))}
              className="w-100 mt-3"
            >
              <i className="bi bi-file-earmark-text ms-2 "></i>
              {local.reportDownload}
            </Button>
          )}
        </p>
      </Card>
    </>
  );
};

OrdersTable.defaultProps = {
  buttonFlag: false,
  img: CertificateIconOrange,
};

export default OrdersTable;

import React from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import goldMedal from "../../../../assets/images/gold-medal.png";
import pnuLogo from "../../../../assets/images/goldCertifiedLogos/pnu-logo.svg";
import SugarCompanyLogo from "../../../../assets/images/goldCertifiedLogos/sugarCompany-logo.png";
import PEPSICOLogo from "../../../../assets/images/goldCertifiedLogos/pepsico-logo.png";
import ExpertiesLogo from "../../../../assets/images/goldCertifiedLogos/experties-logo.webp";
import AlesayiLogo from "../../../../assets/images/goldCertifiedLogos/alesayi-logo.png";
import TamerLogo from "../../../../assets/images/goldCertifiedLogos/Tamer-logo.png";
import MasdarLogo from "../../../../assets/images/goldCertifiedLogos/masdar-logo-color.svg";
import JohnsHopkinsLogo from "../../../../assets/images/goldCertifiedLogos/JohnsHopkins-logo.svg";
import HumanRightsLogo from "../../../../assets/images/goldCertifiedLogos/HumanRights-logo.png";
import APDLogo from "../../../../assets/images/goldCertifiedLogos/APD-logo.jpg";
import Almarai from "../../../../assets/images/goldCertifiedLogos/almarai.svg";
import STC from "../../../../assets/images/goldCertifiedLogos/stc.svg";
import Alsaggaf from "../../../../assets/images/goldCertifiedLogos/alsaggaf_logo.png";
import Unicharm from "../../../../assets/images/goldCertifiedLogos/unicharm.svg";
import Savola from "../../../../assets/images/goldCertifiedLogos/savola.svg";
import Autohub from "../../../../assets/images/goldCertifiedLogos/autohub.jpg";
import BaeSystems from "../../../../assets/images/goldCertifiedLogos/bae-systems-logo.svg";
import ZahidTractor from "../../../../assets/images/goldCertifiedLogos/zahid-tractor-logo.svg";
import Lightech from "../../../../assets/images/goldCertifiedLogos/lightech-logo.svg";
import { useSelector } from "react-redux";
import NASMALogo from "../../../../assets/images/goldCertifiedLogos/N&P-logo.jpeg";
import Slider from "react-slick";

function GoldCertified() {
  const local = useSelector((state) => state.homeReducer.local);
  const companyList = [
    { id: 1, imgSrc: pnuLogo },
    { id: 2, imgSrc: PEPSICOLogo },
    { id: 3, imgSrc: SugarCompanyLogo },
    { id: 4, imgSrc: ExpertiesLogo },
    { id: 5, imgSrc: AlesayiLogo },
    { id: 6, imgSrc: TamerLogo },
    { id: 7, imgSrc: MasdarLogo },
    { id: 8, imgSrc: JohnsHopkinsLogo },
    { id: 9, imgSrc: HumanRightsLogo },
    { id: 10, imgSrc: APDLogo },
    { id: 11, imgSrc: NASMALogo },
    { id: 12, imgSrc: Almarai },
    { id: 13, imgSrc: STC },
    { id: 14, imgSrc: Alsaggaf },
    { id: 15, imgSrc: Unicharm },
    { id: 16, imgSrc: Savola },
    { id: 17, imgSrc: Autohub },
    { id: 18, imgSrc: BaeSystems },
    { id: 19, imgSrc: ZahidTractor },
    { id: 20, imgSrc: Lightech },
  ];

  var settings = {
    // dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    rtl: true,
    autoplaySpeed: 1000,
    centerMode: true,
    className: "",
    // draggable: true,
    focusOnSelect: false,
    initialSlide: 3,
    pauseOnHover: true,
    swipeToSlide: true,
    useTransform: true,
    waitForAnimate: true,
    arrows: false,
    cssEase: "linear",
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <section id="#companies" className="gold-certified py-5">
      <Container className="py-5">
        <Row className="justify-content-between mb-5">
          <Col xs={12} sm={6}>
            <div className="d-flex align-items-end">
              <div>
                <img className="badge-icon" src={goldMedal} />
              </div>
              <div>
                <h2 className="fs-1">
                  <span className="fs-5 d-block mb-2">
                    {local.companiesObtained}
                  </span>
                  {local.goldCertificate}
                </h2>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3} className="text-start mt-5 mt-lg-0">
            {/* <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => {}}
            >
              عرض جميع الشركات
            </Button> */}
          </Col>
        </Row>
        {/* Mobile view */}
        <Row className="g-3 justify-content-around flex-wrap d-flex d-lg-none">
          {companyList.map((item, index) => (
            <Col xs={6} key={index} className="text-center d-flex">
              <Card className="shadow my-2 p-3 d-flex align-items-center justify-content-center">
                <img src={item.imgSrc} className="img-fluid" />
              </Card>
            </Col>
          ))}
        </Row>
        <div className="d-none d-lg-block slider-wrapper">
          <Slider {...settings}>
            {companyList.map((item, index) => {
              return (
                <div key={index}>
                  <Card className="shadow my-4 px-4 d-flex align-items-center justify-content-center">
                    <img src={item.imgSrc} className="img-fluid" />
                  </Card>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </section>
  );
}

export default GoldCertified;

import React from "react";
import { useSelector } from "react-redux";

const ProgressSteps = ({
  steps,
  currentStep,
  onClickStep,
  stepsClass,
  stepClass,
  stepCompletedClass,
  type,
  stepLabelTextActive,
  stepLabelText,
  stepText,
  stepTextCurrent,
  stepTextUncompleted,
  stepTextCompleted,
}) => {
  const local = useSelector((state) => state.homeReducer.local);

  const handleClickStep = (step) => {
    if (onClickStep) {
      onClickStep(step);
    }
  };

  return (
    <React.Fragment>
      <div className={stepsClass}>
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`${stepClass} ${
              index < currentStep && stepCompletedClass
            }`}
          >
            {type === "shape" ? (
              <div
                className={`${stepText} ${
                  index === currentStep
                    ? stepTextCurrent
                    : index > currentStep
                    ? stepTextUncompleted
                    : stepTextCompleted
                }`}
                onClick={() => handleClickStep(step)}
              >
                {index + 1}
              </div>
            ) : (
              <div className="step-image" href="/">
                <img
                  className={step.class}
                  src={step.image}
                  alt="logo"
                  aria-hidden="true"
                />
              </div>
            )}

            {step?.name_ar && (
              <div
                key={step.id}
                className={`d-block ${
                  index === currentStep ? stepLabelTextActive : stepLabelText
                }`}
              >
                {local.language === "ar" ? step?.name_ar : step?.name_en}
              </div>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

ProgressSteps.defaultProps = {
  stepLabelTextActive: "step-label-text--active",
  stepLabelText: "step-label-text",
  stepsClass: "steps py-5",
  type: "shape",
  steps: "",
  currentStep: 0,
  onClickStep: "",
  stepClass: "step",
  stepCompletedClass: "step--completed",

  stepText: "step-text",
  stepTextCurrent: "step-text--current",
  stepTextUncompleted: "step-text--uncompleted",
  stepTextCompleted: "step-text--completed",
};

export default ProgressSteps;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { createTrainingOrder } from "../../../store/Training/actions";
import { Container, Button, Card } from "react-bootstrap";

const Goals = () => {
  const dispatch = useDispatch();
  const local = useSelector((state) => state.homeReducer.local);
  const loading = useSelector((state) => state.commonReducer.loading);
  const navigate = useNavigate();
  const { companyId } = useParams();

  const handleTrainingRequest = () => {
    dispatch(createTrainingOrder(navigate, companyId));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="goals">
      <h2 className="mb-5">{local.trainingOrder}</h2>
      <Card className="shadow p-4">
        <div className="mb-2">
          <span className="text-success fs-5 fw-bold">
            {local.trainingGoals}
          </span>
        </div>
        <p className="fs-6 text-dark fw-medium mb-1 me-2">
          - {local.trainingGoalsList[0]}
        </p>
        <p className="fs-6 text-dark fw-medium mb-1 me-2">
          - {local.trainingGoalsList[1]}
        </p>
        <p className="fs-6 text-dark fw-medium mb-1 me-2">
          - {local.trainingGoalsList[2]}
        </p>
        <p className="fs-6 text-dark fw-medium mb-2 me-2">
          - {local.trainingGoalsList[3]}
        </p>
      </Card>
      <div className="text-center mt-5">
        <Button
          variant="secondary"
          className="text-white"
          onClick={handleTrainingRequest}
        >
          {local.continueToTrainingOrder}
        </Button>
      </div>
    </Container>
  );
};

export default Goals;
